import { german } from './languages/german';
import { english } from './languages/english';
import { french } from './languages/french';
import { italian } from './languages/italian';

export const languages = {
    GERMAN: 'de',
    ENGLISH: 'en',
    FRENCH: 'fr',
    ITALIAN: 'it'
};

const translations = {
    [languages.ENGLISH]: english,
    [languages.GERMAN]: german,
    [languages.FRENCH]: french,
    [languages.ITALIAN]: italian
};

export function hasLanguage(language) {
    return Object.values(languages).includes(language);
}

export function getTranslatedString(language, id) {
    return translations[language][id];
}
