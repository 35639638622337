import { SUPPORTED_ADIAPAD_TILES } from '../../../../constants/constants';

export const german = {
    language: 'Sprache',

    de: 'Deutsch',
    en: 'Englisch',
    fr: 'Französisch',
    it: 'Italienisch',

    // ServiceInputPage
    next: 'Weiter',

    // LoginPage
    applicationName: 'Unblu Meet - Manager',
    serviceInputFormHeader: 'Service',
    loginFormHeader: 'Login',
    username: 'Benutzername',
    password: 'Passwort',
    serviceId: 'Service-ID',
    captcha: 'Captcha',
    signIn: 'Einloggen',

    edit: 'Bearbeiten',
    save: 'Speichern',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    ok: 'OK',
    yes: 'Ja',
    no: 'Nein',
    download: 'Herunterladen',
    notAvailable: 'Nicht verfügbar',

    excelExportHeader: 'Excel Export',
    excelExportLoadingMessage:
        'Einen Moment bitte, Ihr Download wird vorbereitet.',
    excelExportDownloadMessage:
        'Abgeschlossen. Sie können den Download nun starten.',
    excelExportErrorMessage: 'Hoppla! Ein Fehler ist aufgetreten.',

    // ADMINISTRATION
    administration: 'ADMINISTRATION',

    // Service Users
    users: 'Benutzer',
    usersSearch: 'Benutzer suchen',

    userTableId: 'Benutzer-ID',
    userTableAdditionalId: 'Zusätzliche ID',
    userTableName: 'Name',
    userTableUsername: 'Benutzername',
    userTableRoles: 'Rollen',

    userAdd: 'Benutzer hinzufügen',
    userEdit: 'Benutzer bearbeiten',
    userDeleteHeader: 'Benutzer löschen',
    userDeleteDialog: 'Möchten Sie diesen Benutzer wirklich löschen?',

    userGeneralSettings: 'IS THIS STILL USED?',
    userId: 'Benutzer-ID',
    userUsername: 'Benutzername *',
    userName: 'Name *',
    userRoles: 'Rollen *',
    userAdditionalId: 'Zusätzliche ID',
    userPassword: 'Passwort *',
    userConfirmPassword: 'Passwort bestätigen *',
    userNewPassword: 'Neues Passwort',
    userConfirmNewPassword: 'Neues Passwort bestätigen',
    userIdTooltip:
        'Optional, z.B. E-Mail Adresse oder eindeutige Mitarbeiternummer',

    // Meetings
    meetings: 'Meetings',

    meetingTableDate: 'Datum',
    meetingTableTitle: 'Titel',
    meetingTableDescription: 'Beschreibung',
    meetingTableParticipants: 'Teilnehmer',
    meetingTableState: 'Status',

    meetingAdd: 'Neues Meeting hinzufügen',
    meetingEdit: 'Meeting bearbeiten',
    meetingDeleteHeader: 'Meeting löschen?',
    meetingDeleteDialog: 'Möchten Sie dieses Meeting wirklich löschen?',

    meetingGeneral: 'Allgemeine Einstellungen',
    meetingDate: 'Datum *',
    meetingTitle: 'Titel *',
    meetingDescription: 'Beschreibung',
    meetingDuration: 'Dauer (in Minuten)',
    meetingLink: 'Link',
    meetingType: 'Typ',
    meetingState: 'Status',
    meetingStateOpen: 'offen',
    meetingStateOngoing: 'laufend',
    meetingStateCompleted: 'abgeschlossen',
    meetingStateArchived: 'archiviert',
    meetingStateDeleted: 'gelöscht',

    meetingAdditionalMetadata: 'Zusätzliche Metadaten',
    meetingAdditionalMetadataLabel: 'Eigenschaft',
    meetingAdditionalMetadataValue: 'Wert',

    meetingParticipants: 'Teilnehmer',
    meetingNewParticipant: 'Neuer Teilnehmer',
    meetingParticipantName: 'Name *',
    meetingParticipantEmail: 'E-Mail',
    meetingParticipantAdviserId: 'Adviser ID',
    meetingParticipantLink: 'Link',
    meetingParticipantPublic: 'Öffentlich',

    //Topics
    topics: 'Themen',

    topicTableId: 'ID',
    topicTableName: 'Name',
    topicTableStatsGroup: 'Statistik-Gruppe',

    topicAdd: 'Neues Thema hinzufügen',
    topicEdit: 'Thema bearbeiten',
    topicDeleteHeader: 'Thema löschen',
    topicDeleteDialog: 'Möchten Sie dieses Thema wirklich löschen?',

    topicId: 'ID *',
    topicName: 'Name *',
    topicStatsGroup: 'Statistik-Gruppe',

    userTopicsAll: 'Alle Themen',
    userTopics1: 'Themen 1',
    userTopics2: 'Themen 2',
    userTopics3: 'Themen 3',

    //Maintenance Windows
    maintenanceWindows: 'Wartungsfenster',

    maintenanceWindowTableId: 'ID',
    maintenanceWindowTableDescription: 'Beschreibung',
    maintenanceWindowTableStartDateTime: 'Start Datum/Zeit',
    maintenanceWindowTableEndDateTime: 'End Datum/Zeit',

    maintenanceWindowAdd: 'Neues Wartungsfenster hinzufügen',
    maintenanceWindowEdit: 'Wartungsfenster bearbeiten',
    maintenanceWindowDeleteHeader: 'Wartungsfenster löschen',
    maintenanceWindowDeleteDialog: 'Möchten Sie dieses Wartungsfenster wirklich löschen?',

    maintenanceWindowId: 'ID *',
    maintenanceWindowDescription: 'Beschreibung *',
    maintenanceWindowStartDateTime: 'Start Datum/Zeit *',
    maintenanceWindowEndDateTime: 'End Datum/Zeit *',

    //ApiKeys
    apiKeys: 'API-Schlüssel',

    apiKeyTableTitle: 'Titel',
    apiKeyTableRoles: 'Rollen',
    apiKeyTableIp: 'IP',
    apiKeyTableActive: 'Aktiv',

    apiKeyAdd: 'Neuen API-Schlüssel hinzufügen',
    apiKeyEdit: 'API-Schlüssel bearbeiten',
    apiKeyDeleteHeader: 'API-Schlüssel löschen',
    apiKeyDeleteDialog: 'Möchten Sie diesen API-Schlüssel wirklich löschen?',

    apiKeyTitle: 'Titel *',
    apiKeyRoles: 'Rollen *',
    apiKeyToken: 'Schlüssel',
    apiKeyIp: 'IP',
    apiKeyActive: 'Aktiv',

    // ANALYTICS
    analytics: 'ANALYTICS',
    revisionViewer: 'Showboard Log',

    // Sessions
    sessions: 'Sessions',

    sessionFilter: 'Filter',
    sessionFilterHasRecordings: 'Aufgezeichnet',

    sessionExportSelectDateMessage:
        'Wählen Sie den gewünschten Zeitraum für den Export:',
    sessionExportSelectUntil: 'bis',
    sessionExportSelectMaxDataMessage:
        "Beachten Sie, dass maximal 20'000 Datensätze auf einmal exportiert werden können.",
    sessionExportType: 'Exportart:',

    sessionDownloadRecordings: 'Aufzeichnungen herunterladen',
    sessionDownloadAudioRecordings: 'Audioaufzeichnungen herunterladen',
    sessionPrepareRecordingsMessage:
        'Die Aufzeichnungen werden für den Download vorbereitet.',
    sessionDownloadMessage: 'Die Aufzeichnungen sind zum Download bereit.',
    sessionDownloadOngoingMessage:
        'Die Aufzeichnungen werden heruntergeladen. Dies kann einige Zeit dauern.',
    sessionBackgroundDownload: 'Im Hintergrund herunterladen',
    sessionDownloadNoRecordingsFound:
        'Für diese Session sind keine Aufzeichnungen vorhanden.',
    sessionDownloadNoDownloadAvailable:
        'Für diese Aufzeichnungen steht noch kein Download zur Verfügung.',
    sessionDownloadErrorMessage:
        'Während der Vorbereitung zum Download ist ein Fehler aufgetreten.',

    sessionTableDate: 'Datum',
    sessionTableStartTime: 'Session-Start',
    sessionTableDuration: 'Dauer',
    sessionTableAdviser: 'Adviser',
    sessionTableParticipants: 'Teilnehmer',
    sessionTableRecordings: 'Aufzeichnungen',

    sessionInfo: 'Session-Info',
    sessionInfoMessage:
        'Wählen Sie eine Session aus, um zusätzliche Informationen anzuzeigen.',

    sessionMeetingInfo: 'Meeting-Info',
    sessionMeetingInfoDate: 'Datum',
    sessionMeetingInfoTitle: 'Titel',
    sessionMeetingInfoDescription: 'Beschreibung',
    sessionMeetingInfoDuration: 'Dauer des Meetings',

    sessionParticipantInfo: 'Teilnehmer-Info',
    sessionParticipantInfoName: 'Name',
    sessionParticipantInfoEmail: 'E-Mail',
    sessionParticipantInfoIsAdviser: 'Adviser',

    sessionLog: 'Session-Log',
    sessionInfoLogUnavailableMessage:
        'Für die gewählte Session ist kein Session-Log verfügbar.',

    // ADMIN
    sysAdmin: 'SYS-ADMIN',

    // Admin Users
    adminUsers: 'Admins',
    adminUser: 'Admin',

    adminUserTableId: 'ID',
    adminUserTableName: 'Name',
    adminUserTableUsername: 'Benutzername',

    adminUserAdd: 'Admin hinzufügen',
    adminUserEdit: 'Admin bearbeiten',
    adminUserDeleteHeader: 'Admin löschen?',
    adminUserDeleteDialog: 'Möchten Sie diesen Admin wirklich löschen?',

    adminUserUsername: 'Benutzername *',
    adminUserName: 'Name *',
    adminUserId: 'ID',

    // Services
    services: 'Services',
    servicesSearch: 'Service suchen',

    servicesTableId: 'ID',
    servicesTableName: 'Name',
    servicesTableLogo: 'Logo',
    servicesTableActive: 'Aktiv',

    servicesAdd: 'Service hinzufügen',
    servicesEdit: 'Service bearbeiten',
    servicesDeleteHeader: 'Service löschen?',
    servicesDeleteDialog: 'Möchten Sie diesen Service wirklich löschen?',

    servicesBasicSettings: 'Kundeneinstellungen',

    servicesGeneral: 'Allgemeine Einstellungen',
    servicesId: 'ID *',
    servicesName: 'Name *',
    servicesLogo: 'Logo',
    servicesLandingPageLogo: 'Landing-Page Logo',
    servicesAuthMethod: 'Authentifizierungsmethode',
    servicesPublicServiceFields: 'Public Service Fields',
    servicesOriginRestrictions: 'Origin Restrictions',
    servicesActive: 'Aktiv',
    servicesServiceEmailAddress: 'Kontakt E-Mail Adresse',

    servicesAdiaPadTiles: 'Control Panel Schaltflächen',
    servicesAdiaPadTilesAvailable: 'Verfügbar',
    servicesAdiaPadTilesSelected: 'Ausgewählt',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.CALL_MANAGER_INVITE_ADVISER]: 'Berater hinzufügen',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.CALL_MANAGER_TRANSFER]: 'Anruf weiterleiten',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.START_STOP_RECORDING]: 'Aufzeichnung starten/stoppen',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.MUTE_PARTICIPANT]: 'Teilnehmer stummschalten',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.ADD_PARTICIPANT]: 'Teilnehmer hinzufügen',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.REMOVE_PARTICIPANT]: 'Teilnehmer entfernen',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.SHOW_CAM_MIC_SETTINGS]: 'Kamera/Mic einstellen',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.SEPARATOR_1]: '--- Separator 1 ---',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.SHOW_SHOWBOARD]: 'Showboard öffnen',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.SHOW_AGENDA]: 'Agenda öffnen',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.SHOW_MINUTES]: 'Protokoll öffnen',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.REQUEST_SIGNATURE]: 'Unterschrift anfordern',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.START_STOP_SCREEN_SHARE]: 'Bildschirm teilen/Teilen stoppen',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.SEPARATOR_2]: '--- Separator 2 ---',

    servicesMeetingsSettings: 'Meetings Einstellungen',
    servicesSharedApplicationsUrl: 'Shared Applications Url',
    servicesMeetingsManagerUrl: 'Meetings Manager URL',
    servicesCommunicatorUrl: 'Communicator URL',
    servicesAdviserUrl: 'Adviser URL',
    servicesParticipantLimit: 'Teilnehmerlimit',
    servicesEnableRecording: 'Aufnahmen aktiviert',
    servicesEnableRecordingConfirmation: 'Aufnahme-Bestätigung aktiviert',
    servicesEnableAutoRecording: 'Automatische Aufnahme aktiviert',
    servicesDisableCommunicatorFileTransfer:
        'File Transfer im Communicator deaktiviert',
    servicesEnableCommunicatorContactForm:
        'Communicator Kontaktformular aktiviert',
    servicesEnableRaiseHand: 'Handheben aktiviert',
    servicesEnableIsExclusiveToggle: 'Exklusive Meetings aktiviert',
    servicesEnableAuthenticatedParticipants: 'Authentifizierte Teilnehmer aktiviert',
    servicesEnableSurveyInMeeting: 'Umfragen in Meetings aktiviert',
    servicesEnableVoteInMeeting: 'Abstimmungen in Meetings aktiviert',
    servicesEnableWaitingRoom: 'Warteraum aktiviert',
    servicesColoredChatNames: 'Farbige Namen im Chat',

    servicesDisableFileAttachmentsForParticipants:
        'Dateianlagen für Teilnehmer deaktiviert',
    servicesDisableFileDownloadsForParticipants:
        'Dateidownload für Teilnehmer deaktiviert',
    servicesMaxAttachmentsSize: 'Max. Dateigrösse E-Mail Anhang',
    servicesMaxFileUploadSize: 'Maximale Dateigrösse Upload',
    servicesAcceptDocumentsTypesModerators:
        'Erlaubte Dokumenttypen (Moderatoren)',
    servicesAcceptDocumentsTypesAttendees:
        'Erlaubte Dokumenttypen (Teilnehmer)',
    servicesEnableNepatec: 'Nepatec aktiviert',
    servicesShowInsign: 'Insign aktiviert',
    servicesEnableMeetingsLimit: 'Meetingslimite aktiviert',
    servicesMeetingsLimit: 'Meetingslimite',
    servicesReservedAdHocSlots: 'Reservierte Slots für Sofort-Meetings',
    servicesAddCurrentUserToNewMeeting:
        'Aktuellen Benutzer zu neuem Meeting hinzufügen',
    servicesAddAdditionalModerators: 'Zusätzliche Moderatoren hinzufügen',
    servicesKeldanoFeDomain: 'Keldano Fe Domain',
    servicesKeldanoApiDomain: 'Keldano Api Domain',
    servicesAttachAgenda: 'Agenda beifügen',
    servicesAttachProtocol: 'Protokoll beifügen',
    servicesAttendeesSendPostProcessingMailEnabled:
        'Post-Processing Mail Senden aktiviert (Teilnehmer)',
    servicesPreselectInvitationMailModerators:
        'Einladungsemail vorauswählen (Moderatoren)',
    servicesPreselectInvitationMailAttendees:
        'Einladungsemail vorauswählen (Teilnehmer)',
    servicesPreselectPostProcessingMailModerators:
        'Post-Processing-Email vorauswählen (Moderatoren)',
    servicesPreselectPostProcessingMailAttendees:
        'Post-Processing-Email vorauswählen (Teilnehmer)',
    servicesDisableArchive: 'Archivieren deaktiviert',
    servicesCreateLimitDaysFuture: 'Maximum Tage für Meetingerstellung in der Zukunft',
    servicesSummaryLink: 'Benutzerdefinierter Link auf Zusammenfassungsseite',
    servicesGenerateSummaryPdf: 'PDF Protokoll nach Meeting generieren',
    servicesRevisionPdfComments: 'Revisionsinformationen als PDF Kommentare hinzufügen',
    servicesRevisionViewer: 'Revision Viewer aktiviert',
    servicesParticipantsPdfEdit: 'PDF-Edit für Teilnehmer aktiviert',
    servicesTokenAlphabet: 'Short ID Alphabet',
    servicesTokenLength: 'Short ID Länge',
    servicesDisableUploadInMeetingManager: 'Dateiupload deaktiviert beim Erstellen von Meetings',
    servicesDisableAgenda: 'Agenda deaktiviert',
    servicesDisableMinutes: 'Protokoll deaktiviert',
    servicesCompleteMeetingDialogOnLeave: 'Meeting abschliessen Dialog beim Verlassen eines Meetings',
    servicesDisableSendSummary: 'Senden des Zusammenfassungs-Mail deaktiviert',

    servicesMailTemplateSettings: 'Mail Template Einstellungen',
    servicesMailTemplateLogo: 'Mail Logo *',
    servicesMailTemplateCompany: 'Mail Firma',
    servicesMailTemplateAddress: 'Mail Adresse',
    servicesMailTemplatePhone: 'Mail Telefon',
    servicesMailTemplateWebPage: 'Mail Webseite',
    servicesMailTemplateFaxNumber: 'Mail Faxnummer',

    servicesMailSettings: 'Mail Einstellungen',
    servicesMailCredentialsUsername: 'Benutzername',
    servicesMailCredentialsPassword: 'Passwort',
    servicesMailFromAddress: 'Absenderadresse',
    servicesMailFromName: 'Absendername',
    servicesMailReplyToAddress: 'Reply To Adresse',
    servicesMailReplyToName: 'Reply To Name',
    servicesMailReturnPathAddress: 'Return Path Adresse',
    servicesMailReturnPathName: 'Return Path Name',
    servicesMailSmtpServer: 'SMTP Server',
    servicesMailSmtpPort: 'SMTP Port',
    servicesMailMethod: 'Methode',
    servicesMailAdviserAddressAsSender: 'Berater-Email als Absender',
    servicesMailAllowSenderSelection: 'E-Mail Absender Auswahl aktiviert',
    servicesMailSendCopyToAdviser: 'BCC an Berater senden',

    servicesLocalesOverwrites: 'Locales Overwrites',

    servicesWebRtcSettings: 'WebRTC Einstellungen',
    servicesWebRtcMode: 'Modus',
    servicesWebRtcUrl: 'URL *',
    servicesWebRtcSecret: 'Secret *',
    servicesWebRtcApplicationId: 'Application ID *',
    servicesWebRtcApiKey: 'Api Key *',
    servicesWebRtcMaxAudioBitrate: 'Audio Input Max Bitrate (kbps)',
    servicesWebRtcMaxVideoBitrate: 'Video Input Max Bitrate (kbps)',
    servicesWebRtcUseSimulcast: 'Simulcast aktiviert',
    servicesWebRtcIceServerAddress: 'Turn Server',
    servicesWebRtcRelayUsername: 'Turn Username',
    servicesWebRtcRelayPassword: 'Turn Password',
    servicesWebRtcDefaultBackground: 'Standard-Videohintergrundbild',
    servicesWebRtcDisableLocalBgImage: 'Lokales Videohintergrundbild deaktiviert',

    servicesKeycloakSettings: 'Keycloak Einstellungen',
    servicesKeycloakUrl: 'URL',
    servicesKeycloakRealm: 'Realm',
    servicesKeycloakClientId: 'Client Id',
    servicesKeycloakKid: 'KID',
    servicesKeycloakPublicKey: 'Public Key',
    servicesKeycloakAutoSyncUsers: 'User Auto-Sync aktiviert',

    servicesKeldanoApiSettings: 'API Einstellungen',
    servicesKeldanoApiUrl: 'URL *',
    servicesKeldanoApiClientId: 'Client Id *',
    servicesKeldanoApiClientSecret: 'Client Secret *',
    servicesKeldanoApiUsername: 'Benutzername *',
    servicesKeldanoApiPassword: 'Passwort *',
    servicesKeldanoApiEndpoint: 'Keldano API Endpoint',

    servicesWebinarSettings: 'Webinar Einstellungen',
    servicesWebinarMaxAdvisers: 'Maximale Anzahl von Advisern',
    servicesWebinarMaxParticipants: 'Maximale Anzahl von Teilnehmern',
    servicesWebinarMaxSpeakers: 'Maximale Anzahl von Sprechern',
    servicesWebinarDisableVideoForParticipants:
        'Video für Teilnehmer deaktiviert',

    servicesPhoneConsultingSettings: 'Telefonberatung Einstellungen',

    servicesPodiumSettings: 'Podium Einstellungen',
    servicesPodiumServiceId: 'Service ID *',
    servicesPodiumStreamingServer: 'Streaming Server *',
    servicesPodiumStreamingApp: 'Streaming App *',
    servicesPodiumStreamingUrl: 'Streaming URL *',
    servicesPodiumMaxAdvisers: 'Maximale Anzahl von Advisern',
    servicesPodiumMaxParticipants: 'Maximale Anzahl von Teilnehmern',
    servicesPodiumMaxSpeakers: 'Maximale Anzahl von Sprechern',

    servicesCallManagerSettings: 'Call Manager Einstellungen',
    servicesEnableCommModeMatching: 'Communication-Mode Matching aktiviert',

    servicesOptions: 'Optionen',
    servicesExperimentalMode: 'Experimental Mode',
    servicesMeetings: 'Meetings',
    servicesOneToOne: 'One-To-One',
    servicesCallManager: 'Callmanager',
    servicesVideoIdent: 'Video Identifikation',
    servicesBranch: 'Branch',
    servicesKeldanoApi: 'Keldano API',
    servicesWebinar: 'Webinar',
    servicesHideAdviserList: 'Beraterliste ausblenden',
    servicesPhoneConsulting: 'Telefonberatung',
    servicesPodium: 'Podium',

    servicesLogging: 'Logging',
    servicesSessionLog: 'Session-Log',
    servicesChatLog: 'Chat-Log',
    servicesAdviserTracking: 'Adviser Tracking',

    //servicesUserCustomFields: 'Benutzerdefinierte Eingabefelder (Service Benutzer)',
    servicesAdviserUserCustomFields:
        'Benutzerdefinierte Eingabefelder (Benutzer)',
    servicesNewCustomField: 'Neues benutzerdefiniertes Eingabefeld',
    servicesCustomFieldsHeader: 'Benutzerdefinierte Felder',
    servicesFieldId: 'ID',
    servicesFieldType: 'Typ',
    servicesFieldLabel: 'Label',
    servicesFieldDescription: 'Beschreibung',
    servicesFieldRequired: 'Benötigt',
    servicesFieldHidden: 'Versteckt',
    servicesFieldDefault: 'Standardwert',
    servicesFieldPattern: 'Validierungsmuster',
    servicesFieldUsage: 'Verwendungszweck',
    servicesFieldOptions: 'Dropdown-Optionen',
    servicesFieldOptionsLabel: 'Dropdown-Label',
    servicesFieldOptionsValue: 'Wert',

    invalidCustomFieldId:
        'Eine leere ID ist ungültig. Folgende IDs dürfen nicht benutzt werden: name, id, username, password, passwordConfirmation.',

    calendar: {
        firstDayOfWeek: 1,
        dayNames: [
            'Sonntag',
            'Montag',
            'Dienstag',
            'Mittwoch',
            'Donnerstag',
            'Freitag',
            'Samstag'
        ],
        dayNamesShort: ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'],
        dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        monthNames: [
            'Januar',
            'Februar',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember'
        ],
        monthNamesShort: [
            'Jan.',
            'Feb.',
            'Mär.',
            'Apr.',
            'Mai',
            'Jun.',
            'Jul.',
            'Aug.',
            'Sep.',
            'Okt.',
            'Nov.',
            'Dez.'
        ]
    },
    pathDoesNotExist: 'Diese Seite existiert nicht!',

    invalidUsername: 'Nur Buchstaben, Zahlen, "-", "_" und "." erlaubt.',
    invalidPassword: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
    invalidPasswordConfirmation: 'Die Passwörter stimmen nicht überein.',

    logoutDialogHeader: 'Logout',
    logoutDialog:
        'Indem Sie auf "Ja" klicken, werden Sie von allen Applikationen abgemeldet. Sie verlieren somit die Verbindung zu allen laufenden Meetings, an denen Sie teilnehmen.',

    // messages
    connectionError: 'Verbindung zum Server ist fehlgeschlagen.',
    noServiceError:
        'Es muss mindestens ein Service existieren, um diese Aktion durchzuführen.',
    changeServiceError: 'Der Service konnte nicht gewechselt werden.',
    editCurrentUserErrorMessage:
        'Es ist nicht möglich einen Benutzer, mit dem Sie eingeloggt sind, zu bearbeiten oder löschen.',

    serverError: 'Hoppla! Ein Fehler ist aufgetreten.',

    accessDenied: 'Zugriff verweigert!',
    notAuthorized: 'Keine Berechtigung!',

    authInvalidIp: 'Ungültige IP.',
    authInvalidUser: 'Ungültiger Benutzer.',
    authInvalidCaptcha: 'Ungültiges Captcha.',
    authInvalidCredentials: 'Ungültige Logindaten.',

    userRequestError: 'Benutzer konnte(n) nicht abgerufen werden.',
    userAddSuccess: 'Benutzer wurde hinzugefügt.',
    userConflict: 'Ein Benutzer mit diesem Benutzernamen existiert bereits.',
    userKeycloakConflict:
        'Ein Benutzer mit diesem Benutzernamen und/oder dieser E-Mail existiert bereits.',
    userAddError: 'Benutzer konnte nicht hinzugefügt werden.',
    userUpdateSuccess: 'Benutzer wurde aktualisiert.',
    userNotFound: 'Dieser Benutzer existiert nicht.',
    userUpdateError: 'Benutzer konnte nicht aktualisiert werden.',
    userDeleteSuccess: 'Benutzer wurde gelöscht.',
    userDeleteError: 'Benutzer konnte nicht gelöscht werden.',
    userSyncSuccess: 'Benutzer wurden synchronisiert.',
    userSyncError: 'Ein Fehler trat während der Benutzersynchronisierung auf.',

    meetingRequestError: 'Meeting(s) konnte(n) nicht abgerufen werden.',
    meetingAddSuccess: 'Meeting wurde hinzugefügt.',
    meetingAddNotSupported:
        'Keldano-Meetings können im Manager nicht hinzugefügt werden.',
    meetingAddError: 'Meeting konnte nicht hinzugefügt werden.',
    meetingUpdateSuccess: 'Meeting wurde aktualisiert.',
    meetingNotFound: 'Dieses Meeting existiert nicht.',
    meetingUpdateError: 'Meeting konnte nicht aktualisiert werden.',
    meetingDeleteSuccess: 'Meeting wurde gelöscht.',
    meetingDeleteNotSupported:
        'Keldano-Meetings können im Manager nicht gelöscht werden.',
    meetingDeleteError: 'Meeting konnte nicht gelöscht werden.',

    apiKeyRequestError: 'API-Schlüssel konnte(n) nicht abgerufen werden.',
    apiKeyAddSuccess: 'API-Schlüssel wurde hinzugefügt.',
    apiKeyAddError: 'API-Schlüssel konnte nicht hinzugefügt werden.',
    apiKeyUpdateSuccess: 'API-Schlüssel wurde aktualisiert.',
    apiKeyNotFound: 'Dieser API-Schlüssel existiert nicht.',
    apiKeyUpdateError: 'API-Schlüssel konnte nicht aktualisiert werden.',
    apiKeyDeleteSuccess: 'API-Schlüssel wurde gelöscht.',
    apiKeyDeleteError: 'API-Schlüssel konnte nicht gelöscht werden.',

    sessionRequestError: 'Session(s) konnte(n) nicht abgerufen werden.',
    sessionDownloadNotAllowed:
        'Sie sind nicht dazu berechtigt Aufzeichnungen herunterzuladen.',

    adminUserRequestError: 'Admin(s) konnte(n) nicht abgerufen werden.',
    adminUserAddSuccess: 'Admin wurde hinzugefügt.',
    adminUserConflict: 'Ein Admin mit diesem Benutzernamen existiert bereits.',
    adminUserAddError: 'Admin konnte nicht hinzugefügt werden.',
    adminUserUpdateSuccess: 'Admin wurde aktualisiert.',
    adminUserNotFound: 'Dieser Admin existiert nicht.',
    adminUserUpdateError: 'Admin konnte nicht aktualisiert werden.',
    adminUserDeleteSuccess: 'Admin wurde gelöscht.',
    adminUserDeleteError: 'Admin konnte nicht gelöscht werden.',

    serviceRequestError: 'Service(s) konnte(n) nicht abgerufen werden.',
    serviceAddSuccess: 'Service wurde hinzugefügt.',
    serviceConflict: 'Ein Service mit dieser ID existiert bereits.',
    serviceWebRtcError: 'WebRTC Einstellungen konnten nicht gesetzt werden.',
    serviceAddError: 'Service konnte nicht hinzugefügt werden.',
    serviceUpdateSuccess: 'Service wurde aktualisiert.',
    serviceNotFound: 'Dieser Service existiert nicht.',
    serviceUpdateError: 'Service konnte nicht aktualisiert werden.',
    serviceDeleteSuccess: 'Service wurde gelöscht.',
    serviceDeleteError: 'Service konnte nicht gelöscht werden.'
};
