import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    addAdminUser,
    addAdminUserValidation,
    deleteAdminUser,
    editAdminUser,
    requestAdminUsers,
    resetAdminUserPage,
    startEditingAdminUser,
    stopEditingAdminUser,
    updateAdminUser
} from './actions';

import '../../css/features/AdminUser.scss';
import classNames from 'classnames';

import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import EditButtons from '../base/EditButtons';
import DeleteDialog from '../base/DeleteDialog';
import TranslatedString from '../base/i18n/TranslatedString';
import { getTranslatedString } from '../base/i18n/translations';
import FormButtons from '../base/FormButtons';
import { Dropdown } from 'primereact/dropdown';

export class AdminUsers extends Component {
    constructor(props) {
        super(props);

        this.rows = 25;
        this.defaultAdminUserFields = {
            name: '',
            id: '',
            username: '',
            password: '',
            passwordConfirmation: '',
            roles: 'admin',
            locale: 'en'
        };

        this.state = {
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
        this.handleEditForm = this.handleEditForm.bind(this);
        this.handleAddValidation = this.handleAddValidation.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    componentDidMount() {
        this.props.requestAdminUsers({
            range: {
                startIndex: 0,
                endIndex: this.rows - 1
            },
            sort: ['username']
        });
    }

    componentWillUnmount() {
        this.props.resetAdminUserPage();
    }

    handlePageClick(event) {
        this.props.requestAdminUsers({
            range: {
                startIndex: event.first,
                endIndex: event.first + this.rows - 1
            },
            sort: ['username']
        });
    }

    handleEditClick(adminUserId) {
        if (
            this.props.isEditing &&
            adminUserId === this.props.adminUserToEdit._id
        ) {
            this.props.stopEditingAdminUser();
        } else {
            //find adminUser object with matching adminUserId to edit
            const adminUser = this.props.adminUsers.find(item => {
                return item._id === adminUserId;
            });

            //build adminUser object using given property values or default values if necessary
            let adminUserData = { _id: adminUser._id };
            Object.keys(this.defaultAdminUserFields).forEach(prop => {
                if (adminUser.hasOwnProperty(prop)) {
                    adminUserData[prop] = adminUser[prop];
                } else {
                    adminUserData[prop] = this.defaultAdminUserFields[prop];
                }
            });

            this.props.startEditingAdminUser(adminUserData);
        }
    }

    handleDeleteClick(adminUserId, adminUserUsername) {
        this.setState({
            showDeleteDialog: true,
            itemToDeleteId: adminUserId,
            itemToDeleteName: adminUserUsername
        });
    }

    handleDeleteConfirm() {
        const adminUsers = this.props.adminUsers;
        let currentItems = { ...this.props.contentRange.currentItems };

        // if the item to be deleted is the last one on the currently selected table page, request the items for the previous table page
        if (
            currentItems.startIndex === currentItems.endIndex &&
            adminUsers[adminUsers.length - 1]._id === this.state.itemToDeleteId
        ) {
            currentItems = {
                startIndex: currentItems.startIndex - this.rows,
                endIndex: currentItems.startIndex - 1
            };
            if (currentItems.startIndex < 0) {
                currentItems = undefined;
            }
        }

        this.props.deleteAdminUser(this.state.itemToDeleteId, {
            range: currentItems,
            sort: ['username']
        });
        this.setState({
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        });
    }

    handleDeleteCancel() {
        this.setState({
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        });
    }

    handleEditForm(currentFields, update) {
        this.props.editAdminUser(currentFields, update);
    }

    handleAddValidation(validationType) {
        const validationSet = this.props.isEditing
            ? this.props.editValidationSet
            : this.props.addValidationSet;
        if (!validationSet.hasOwnProperty(validationType)) {
            this.props.addAdminUserValidation(validationType);
        }
    }

    handleSaveClick() {
        let currentItems;
        if (this.props.contentRange) {
            currentItems = { ...this.props.contentRange.currentItems };
        }

        if (this.props.isEditing) {
            this.props.updateAdminUser(this.props.adminUserToEdit._id, {
                range: currentItems,
                sort: ['username']
            });
        } else {
            if (currentItems) {
                currentItems.endIndex = currentItems.startIndex + this.rows - 1;
            }
            this.props.addAdminUser({
                range: currentItems,
                sort: ['username']
            });
        }
    }

    handleCancelClick() {
        this.props.stopEditingAdminUser();
    }

    render() {
        return (
            <React.Fragment>
                <div className="p-grid">
                    <div className="p-col-12 p-xl-8">
                        <AdminUsersTable
                            user={this.props.user}
                            isLoading={this.props.isLoading}
                            editedRowId={
                                this.props.isEditing
                                    ? this.props.adminUserToEdit._id
                                    : undefined
                            }
                            adminUsers={this.props.adminUsers}
                            language={this.props.language}
                            rows={this.rows}
                            contentRange={this.props.contentRange}
                            onPageClick={this.handlePageClick}
                            onEditClick={this.handleEditClick}
                            onDeleteClick={this.handleDeleteClick}
                        />
                    </div>

                    <div className="p-col-12 p-xl-4">
                        <AdminUserForm
                            user={this.props.user}
                            isLoading={this.props.isLoading}
                            isEditing={this.props.isEditing}
                            adminUserFormFields={
                                (this.props.isEditing
                                    ? this.props.adminUserToEdit
                                    : this.props.adminUserToAdd) ||
                                this.defaultAdminUserFields
                            }
                            validationSet={
                                this.props.isEditing
                                    ? this.props.editValidationSet
                                    : this.props.addValidationSet
                            }
                            language={this.props.language}
                            onEditForm={this.handleEditForm}
                            onAddValidation={this.handleAddValidation}
                            onSaveClick={this.handleSaveClick}
                            onCancelClick={this.handleCancelClick}
                        />
                    </div>
                </div>

                <DeleteDialog
                    visible={this.state.showDeleteDialog}
                    header={'adminUserDeleteHeader'}
                    dialog={'adminUserDeleteDialog'}
                    itemToDelete={this.state.itemToDeleteName}
                    onDeleteConfirm={this.handleDeleteConfirm}
                    onDeleteCancel={this.handleDeleteCancel}
                />
            </React.Fragment>
        );
    }
}

class AdminUsersTable extends Component {
    render() {
        const adminUserTableEntries = this.props.adminUsers.map((item, key) => {
            let adminUser = { ...item };

            adminUser.editAdminUserButtons = (
                <EditButtons
                    key={key}
                    id={adminUser._id}
                    name={adminUser.username}
                    isLoading={this.props.isLoading}
                    disableDelete={this.props.user._id === adminUser._id}
                    onEditClick={this.props.onEditClick}
                    onDeleteClick={this.props.onDeleteClick}
                />
            );

            return adminUser;
        });

        return (
            <Card
                title={getTranslatedString(this.props.language, 'adminUsers')}
            >
                <DataTable
                    className="table"
                    autoLayout={true}
                    responsive={true}
                    value={adminUserTableEntries}
                    rows={this.props.rows}
                    rowClassName={rowData => {
                        return {
                            'row-bg':
                                rowData &&
                                rowData._id === this.props.editedRowId
                        };
                    }}
                    paginator={true}
                    paginatorPosition={'top'}
                    alwaysShowPaginator={true}
                    lazy={true}
                    totalRecords={
                        this.props.contentRange
                            ? this.props.contentRange.totalItems
                            : undefined
                    }
                    first={
                        this.props.contentRange
                            ? this.props.contentRange.currentItems.startIndex
                            : 0
                    }
                    onPage={this.props.onPageClick}
                >
                    <Column
                        className="column username-col"
                        field="username"
                        header={
                            <TranslatedString id={'adminUserTableUsername'} />
                        }
                    />
                    <Column
                        className="column name-col"
                        field="name"
                        header={<TranslatedString id={'adminUserTableName'} />}
                    />
                    <Column
                        className="column id-col"
                        field="id"
                        header={<TranslatedString id={'adminUserTableId'} />}
                    />
                    <Column
                        className="column edit-buttons-col"
                        field="editAdminUserButtons"
                    />
                </DataTable>
            </Card>
        );
    }
}

class AdminUserForm extends Component {
    constructor(props) {
        super(props);

        this.validators = {
            name: name => {
                //any non-empty name is valid
                return name.trim() !== '';
            },
            username: username => {
                //a valid username may only contain letters, numbers, '-', '_' and '.'
                return /^[a-zA-Z0-9_.-]+$/.test(username);
            },
            password: (password, isEditing) => {
                //a valid password is at least 8 characters long or empty (in the case of editing the admin user)
                return isEditing
                    ? password === '' || password.length >= 8
                    : password.length >= 8;
            },
            passwordConfirmation: (password, passwordConfirmation) => {
                return password === passwordConfirmation;
            }
        };
    }

    /**
     * onChange event handler input field
     * @param fieldId - property name of input field
     * @returns {Function}
     */
    handleOnChange = fieldId => e => {
        this.props.onEditForm(this.props.adminUserFormFields, {
            field: fieldId,
            value: e.target.value
        });
    };

    /**
     * onBlur event handler for input field
     * @param fieldId - property name of input field
     * @returns {Function}
     */
    handleOnBlur = fieldId => () => {
        this.props.onAddValidation(fieldId);
    };

    /**
     * validate adminUser form fields
     * @param adminUserFormFields
     * @returns {{invalidInputFields: {password: boolean, name: boolean, username: boolean}, invalidInputs: (boolean|*)}}
     */
    validateFormFields(adminUserFormFields) {
        let invalidInputFields = {
            name: !this.validators.name(adminUserFormFields.name),
            username: !this.validators.username(adminUserFormFields.username),
            password: !this.validators.password(
                adminUserFormFields.password,
                this.props.isEditing
            )
        };
        invalidInputFields.passwordConfirmation =
            invalidInputFields.password ||
            !this.validators.passwordConfirmation(
                adminUserFormFields.password,
                adminUserFormFields.passwordConfirmation
            );

        return {
            invalidInputFields: invalidInputFields,
            invalidInputs: Object.keys(invalidInputFields).reduce(
                (acc, curr) => acc || invalidInputFields[curr],
                false
            )
        };
    }

    renderAdminUserInputFields(invalidInputFields) {
        const adminUserFormFields = this.props.adminUserFormFields;
        const validationSet = this.props.validationSet;

        return (
            <React.Fragment>
                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className={classNames('form-input', {
                                'p-error':
                                    invalidInputFields.name &&
                                    validationSet.hasOwnProperty('name')
                            })}
                            value={adminUserFormFields.name}
                            onChange={this.handleOnChange('name')}
                            onBlur={this.handleOnBlur('name')}
                        />
                        <label>
                            <TranslatedString id={'adminUserName'} />
                        </label>
                    </span>
                </div>

                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className="form-input"
                            value={adminUserFormFields.id}
                            onChange={this.handleOnChange('id')}
                        />
                        <label>
                            <TranslatedString id={'adminUserId'} />
                        </label>
                    </span>
                </div>

                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className={classNames('form-input', {
                                'p-error':
                                    invalidInputFields.username &&
                                    validationSet.hasOwnProperty('username')
                            })}
                            readOnly={
                                this.props.isEditing &&
                                this.props.user._id === adminUserFormFields._id
                            }
                            value={adminUserFormFields.username}
                            onChange={this.handleOnChange('username')}
                            onBlur={this.handleOnBlur('username')}
                        />
                        <label>
                            <TranslatedString id={'adminUserUsername'} />
                        </label>
                    </span>
                    {invalidInputFields.username &&
                        validationSet.hasOwnProperty('username') && (
                            <span className="error-message">
                                <TranslatedString id={'invalidUsername'} />
                            </span>
                        )}
                </div>

                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            autoComplete="new-password"
                            type="password"
                            className={classNames('form-input', {
                                'p-error':
                                    invalidInputFields.password &&
                                    validationSet.hasOwnProperty('password')
                            })}
                            value={adminUserFormFields.password}
                            onChange={this.handleOnChange('password')}
                            onBlur={this.handleOnBlur('password')}
                        />
                        <label>
                            <TranslatedString
                                id={
                                    this.props.isEditing
                                        ? 'userNewPassword'
                                        : 'userPassword'
                                }
                            />
                        </label>
                    </span>
                    {invalidInputFields.password &&
                        validationSet.hasOwnProperty('password') && (
                            <span className="error-message">
                                <TranslatedString id={'invalidPassword'} />
                            </span>
                        )}
                </div>

                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            type="password"
                            className={classNames('form-input', {
                                'p-error':
                                    invalidInputFields.passwordConfirmation &&
                                    validationSet.hasOwnProperty(
                                        'passwordConfirmation'
                                    )
                            })}
                            value={adminUserFormFields.passwordConfirmation}
                            onChange={this.handleOnChange(
                                'passwordConfirmation'
                            )}
                            onBlur={this.handleOnBlur('passwordConfirmation')}
                        />
                        <label>
                            <TranslatedString
                                id={
                                    this.props.isEditing
                                        ? 'userConfirmNewPassword'
                                        : 'userConfirmPassword'
                                }
                            />
                        </label>
                    </span>
                    {invalidInputFields.passwordConfirmation &&
                        validationSet.hasOwnProperty(
                            'passwordConfirmation'
                        ) && (
                            <span className="error-message">
                                <TranslatedString
                                    id={'invalidPasswordConfirmation'}
                                />
                            </span>
                        )}
                </div>

                <div className="p-col-12">
                    <Dropdown
                        className="form-input"
                        value={adminUserFormFields.roles}
                        options={[
                            { label: 'admin', value: 'admin' },
                            {
                                label: 'restricted-admin',
                                value: 'restricted-admin'
                            },
                            { label: 'stats-admin', value: 'stats-admin' }
                        ]}
                        onChange={this.handleOnChange('roles')}
                    />
                    <label className="dropdown-label-float">
                        <TranslatedString id={'userRoles'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Dropdown
                        className="form-input"
                        value={adminUserFormFields.locale}
                        options={[
                            {
                                label: getTranslatedString(
                                    this.props.language,
                                    'en'
                                ),
                                value: 'en'
                            },
                            {
                                label: getTranslatedString(
                                    this.props.language,
                                    'de'
                                ),
                                value: 'de'
                            },
                            {
                                label: getTranslatedString(
                                    this.props.language,
                                    'fr'
                                ),
                                value: 'fr'
                            },
                            {
                                label: getTranslatedString(
                                    this.props.language,
                                    'it'
                                ),
                                value: 'it'
                            }
                        ]}
                        onChange={this.handleOnChange('locale')}
                    />
                    <label className="dropdown-label-float">
                        <TranslatedString id={'language'} />
                    </label>
                </div>
            </React.Fragment>
        );
    }

    render() {
        const { invalidInputFields, invalidInputs } = this.validateFormFields(
            this.props.adminUserFormFields
        );
        const adminUserInputFields = this.renderAdminUserInputFields(
            invalidInputFields
        );

        return (
            <Card
                title={getTranslatedString(
                    this.props.language,
                    this.props.isEditing ? 'adminUserEdit' : 'adminUserAdd'
                )}
            >
                <br />
                <div className="p-grid form-group">
                    {adminUserInputFields}
                    <div className="p-col-12">
                        <FormButtons
                            invalidInputs={invalidInputs}
                            isLoading={this.props.isLoading}
                            isEditing={this.props.isEditing}
                            onSaveClick={this.props.onSaveClick}
                            onCancelClick={this.props.onCancelClick}
                        />
                    </div>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.adminUsers.isLoading,
        isEditing: state.adminUsers.isEditing,
        adminUserToAdd: state.adminUsers.adminUserToAdd,
        addValidationSet: state.adminUsers.addValidationSet,
        adminUserToEdit: state.adminUsers.adminUserToEdit,
        editValidationSet: state.adminUsers.editValidationSet,
        adminUsers: state.adminUsers.adminUsers,
        contentRange: state.adminUsers.contentRange,

        user: state.auth.user,

        language: state.i18n.language
    };
};

const mapDispatchToProps = {
    requestAdminUsers,
    addAdminUser,
    updateAdminUser,
    deleteAdminUser,
    startEditingAdminUser,
    stopEditingAdminUser,
    editAdminUser,
    addAdminUserValidation,
    resetAdminUserPage
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
