import * as types from './actionTypes';
import { handleError, log } from '../base/util/helpers';
import { forceLogout } from '../auth/actions';
import authHelper from '../base/util/authHelper';

function convertMaintenanceWindowProperties(maintenanceWindowData, dataFromServer) {
    if (dataFromServer) {
        maintenanceWindowData.forEach(maintenanceWindow => {
            // convert unix timestamp to date
            if (maintenanceWindow.startDateTime) {
                maintenanceWindow.startDateTime = new Date(maintenanceWindow.startDateTime);
            }
            if (maintenanceWindow.endDateTime) {
                maintenanceWindow.endDateTime = new Date(maintenanceWindow.endDateTime);
            }
        });
    } else {
        // convert date to unix timestamp
        if (maintenanceWindowData.startDateTime) {
            maintenanceWindowData.startDateTime = new Date(maintenanceWindowData.startDateTime).getTime();
        }
        if (maintenanceWindowData.endDateTime) {
            maintenanceWindowData.endDateTime = new Date(maintenanceWindowData.endDateTime).getTime();
        }
    }
}

/*
 * action creators
 */
function requestMaintenanceWindowsRequest() {
    return { type: types.REQUEST_MAINTENANCE_WINDOWS };
}

function requestMaintenanceWindowsSuccess(maintenanceWindows, contentRange) {
    return { type: types.REQUEST_MAINTENANCE_WINDOWS_SUCCESS, maintenanceWindows, contentRange };
}

function requestMaintenanceWindowsFailure(error) {
    return { type: types.REQUEST_MAINTENANCE_WINDOWS_FAILURE, error };
}

/*
 * thunk
 */
export function requestMaintenanceWindows({ fields, sort, range } = {}) {
    return async (dispatch, getState, api) => {
        dispatch(requestMaintenanceWindowsRequest());

        const response = await api.getMaintenanceWindows(
            fields,
            sort,
            range,
            authHelper.getToken()
        );
        if (!response.error) {
            log.debug('[requestMaintenanceWindows]', response.maintenanceWindows);

            convertMaintenanceWindowProperties(response.maintenanceWindows, true);

            dispatch(
                requestMaintenanceWindowsSuccess(response.maintenanceWindows, response.contentRange)
            );
        } else {
            handleError(response.error, {
                fatal: error => dispatch(forceLogout(error)),
                nonFatal: error => dispatch(requestMaintenanceWindowsFailure(error))
            });
        }
    };
}

/*
 * action creators
 */
function addMaintenanceWindowRequest() {
    return { type: types.ADD_MAINTENANCE_WINDOW };
}

function addMaintenanceWindowSuccess(maintenanceWindow) {
    return { type: types.ADD_MAINTENANCE_WINDOW_SUCCESS, maintenanceWindow };
}

function addMaintenanceWindowFailure(error) {
    return { type: types.ADD_MAINTENANCE_WINDOW_FAILURE, error };
}

/*
 * thunk
 */
export function addMaintenanceWindow({ fields, sort, range }) {
    return async (dispatch, getState, api) => {
        dispatch(addMaintenanceWindowRequest());

        const maintenanceWindow = getState().maintenanceWindows.maintenanceWindowToAdd;

        convertMaintenanceWindowProperties(maintenanceWindow, false);

        log.debug('[addMaintenanceWindow]', maintenanceWindow);

        const response = await api.addMaintenanceWindow(
            maintenanceWindow,
            authHelper.getToken()
        );
        if (!response.error) {
            dispatch(addMaintenanceWindowSuccess(response.maintenanceWindow));
            dispatch(requestMaintenanceWindows({ fields, sort, range }));
        } else {
            handleError(response.error, {
                fatal: error => dispatch(forceLogout(error)),
                nonFatal: error => dispatch(addMaintenanceWindowFailure(error))
            });
        }
    };
}

/*
 * action creators
 */
function updateMaintenanceWindowRequest() {
    return { type: types.UPDATE_MAINTENANCE_WINDOW };
}

function updateMaintenanceWindowSuccess(maintenanceWindow) {
    return { type: types.UPDATE_MAINTENANCE_WINDOW_SUCCESS, maintenanceWindow };
}

function updateMaintenanceWindowFailure(error) {
    return { type: types.UPDATE_MAINTENANCE_WINDOW_FAILURE, error };
}

/*
 * thunk
 */
export function updateMaintenanceWindow(maintenanceWindowId, { fields, sort, range }) {
    return async (dispatch, getState, api) => {
        dispatch(updateMaintenanceWindowRequest());

        const maintenanceWindow = { ...getState().maintenanceWindows.maintenanceWindowToEdit };
        const updatedFields = getState().maintenanceWindows.updatedFields;

        // remove fields that were not updated
        Object.keys(maintenanceWindow).forEach(field => {
            if (!updatedFields[field]) {
                delete maintenanceWindow[field];
            }
        });

        convertMaintenanceWindowProperties(maintenanceWindow, false);

        log.debug('[updateMaintenanceWindow]', maintenanceWindow);

        const response = await api.updateMaintenanceWindow(
            maintenanceWindowId,
            maintenanceWindow,
            authHelper.getToken()
        );
        if (!response.error) {
            dispatch(updateMaintenanceWindowSuccess(response.maintenanceWindow));
            dispatch(requestMaintenanceWindows({ fields, sort, range }));
        } else {
            handleError(response.error, {
                fatal: error => dispatch(forceLogout(error)),
                nonFatal: error => dispatch(updateMaintenanceWindowFailure(error))
            });
        }
    };
}

/*
 * action creators
 */
function deleteMaintenanceWindowRequest() {
    return { type: types.DELETE_MAINTENANCE_WINDOW };
}

function deleteMaintenanceWindowSuccess(maintenanceWindowId) {
    return { type: types.DELETE_MAINTENANCE_WINDOW_SUCCESS, maintenanceWindowId };
}

function deleteMaintenanceWindowFailure(error) {
    return { type: types.DELETE_MAINTENANCE_WINDOW_FAILURE, error };
}

/*
 * thunk
 */
export function deleteMaintenanceWindow(maintenanceWindowId, { fields, sort, range }) {
    return async (dispatch, getState, api) => {
        dispatch(deleteMaintenanceWindowRequest());

        const response = await api.deleteMaintenanceWindow(
            maintenanceWindowId,
            authHelper.getToken()
        );
        if (!response.error) {
            dispatch(deleteMaintenanceWindowSuccess(maintenanceWindowId));
            dispatch(requestMaintenanceWindows({ fields, sort, range }));
        } else {
            handleError(response.error, {
                fatal: error => dispatch(forceLogout(error)),
                nonFatal: error => dispatch(deleteMaintenanceWindowFailure(error))
            });
        }
    };
}

/*
 * action creators
 */
export function startEditingMaintenanceWindow(maintenanceWindowData) {
    return { type: types.START_EDITING_MAINTENANCE_WINDOW, maintenanceWindowData };
}

export function stopEditingMaintenanceWindow() {
    return { type: types.STOP_EDITING_MAINTENANCE_WINDOW };
}

export function editMaintenanceWindow(currentFields, update) {
    return { type: types.EDIT_MAINTENANCE_WINDOW, currentFields, update };
}

export function addMaintenanceWindowValidation(validationType) {
    return { type: types.ADD_MAINTENANCE_WINDOW_VALIDATION, validationType };
}

function resetPage() {
    return { type: types.RESET_MAINTENANCE_WINDOWS_PAGE };
}

/*
 * thunk
 */
export function resetMaintenanceWindowsPage(
    loadMaintenanceWindows = false,
    { fields, sort, range } = {}
) {
    return async dispatch => {
        dispatch(resetPage());
        if (loadMaintenanceWindows) {
            dispatch(requestMaintenanceWindows({ fields, sort, range }));
        }
    };
}
