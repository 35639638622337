import * as types from './actionTypes';
import { forceLogout } from '../auth/actions';

import authHelper from '../base/util/authHelper';
import { handleError } from '../base/util/helpers';

function getLiveStatsRequest() {
    return { type: types.GET_LIVE_STATS_REQUEST };
}

function getLiveStatsSuccess(stats) {
    return { type: types.GET_LIVE_STATS_SUCCESS, stats };
}

function getLiveStatsFailure(errorMessage) {
    return { type: types.GET_LIVE_STATS_FAILURE, errorMessage };
}

export function requestLiveStats() {
    return async (dispatch, getState, api) => {
        dispatch(getLiveStatsRequest());
        const response = await api.getLiveStats(authHelper.getToken());
        if (!response.error) {
            dispatch(getLiveStatsSuccess(response.stats));
        } else {
            handleError(response.error, {
                fatal: errorMessage => dispatch(forceLogout(errorMessage)),
                nonFatal: errorMessage =>
                    dispatch(getLiveStatsFailure(errorMessage))
            });
        }
    };
}
