export const REQUEST_API_KEYS = 'REQUEST_API_KEYS';
export const REQUEST_API_KEYS_SUCCESS = 'REQUEST_API_KEYS_SUCCESS';
export const REQUEST_API_KEYS_FAILURE = 'REQUEST_API_KEYS_FAILURE';

export const ADD_API_KEY = 'ADD_API_KEY';
export const ADD_API_KEY_SUCCESS = 'ADD_API_KEY_SUCCESS';
export const ADD_API_KEY_FAILURE = 'ADD_API_KEY_FAILURE';

export const UPDATE_API_KEY = 'UPDATE_API_KEY';
export const UPDATE_API_KEY_SUCCESS = 'UPDATE_API_KEY_SUCCESS';
export const UPDATE_API_KEY_FAILURE = 'UPDATE_API_KEY_FAILURE';

export const DELETE_API_KEY = 'DELETE_API_KEY';
export const DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS';
export const DELETE_API_KEY_FAILURE = 'DELETE_API_KEY_FAILURE';

export const START_EDITING_API_KEY = 'START_EDITING_API_KEY';
export const STOP_EDITING_API_KEY = 'STOP_EDITING_API_KEY';
export const EDIT_API_KEY = 'EDIT_API_KEY';
export const ADD_API_KEY_VALIDATION = 'ADD_API_KEY_VALIDATION';

export const RESET_API_KEYS_PAGE = 'RESET_API_KEYS_PAGE';
