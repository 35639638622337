import * as types from './actionTypes';
import { handleError, log } from '../base/util/helpers';
import { forceLogout, noServiceFound } from '../auth/actions';
import authHelper from '../base/util/authHelper';

function convertUserSettings(userData, dataFromServer) {
    if (dataFromServer) {
    } else {
        delete userData.passwordConfirmation;
        if (userData.password === '') {
            delete userData.password;
        }
    }
}

export function updateRequestParameters(requestParameters) {
    return {
        type: types.UPDATE_USER_REQUEST_PARAMETERS,
        requestParameters
    };
}

/*
 * action creators
 */
function requestUsersRequest() {
    return { type: types.REQUEST_USERS };
}

function requestUsersSuccess(users, contentRange) {
    return { type: types.REQUEST_USERS_SUCCESS, users, contentRange };
}

function requestUsersFailure(error) {
    return { type: types.REQUEST_USERS_FAILURE, error };
}

/*
 * thunk
 */
export function requestUsers() {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(requestUsersRequest());

            const {
                fields,
                sort,
                range,
                search
            } = getState().users.requestParameters;
            const response = await api.getUsers(
                service._id,
                fields,
                sort,
                range,
                search,
                authHelper.getToken()
            );
            if (!response.error) {
                log.debug('[requestUsers]', response.users);
                dispatch(
                    requestUsersSuccess(response.users, response.contentRange)
                );
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(requestUsersFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

/*
 * action creators
 */
function addUserRequest() {
    return { type: types.ADD_USER };
}

function addUserSuccess(user) {
    return { type: types.ADD_USER_SUCCESS, user };
}

function addUserFailure(error) {
    return { type: types.ADD_USER_FAILURE, error };
}

/*
 * thunk
 */
export function addUser() {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(addUserRequest());

            let user = { ...getState().users.userToAdd };
            convertUserSettings(user, false);
            log.debug('[addUser]', user);

            const response = await api.addUser(
                service._id,
                user,
                authHelper.getToken()
            );
            if (!response.error) {
                dispatch(addUserSuccess(response.user));
                dispatch(requestUsers());
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(addUserFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

/*
 * action creators
 */
function updateUserRequest() {
    return { type: types.UPDATE_USER };
}

function updateUserSuccess(user) {
    return { type: types.UPDATE_USER_SUCCESS, user };
}

function updateUserFailure(error) {
    return { type: types.UPDATE_USER_FAILURE, error };
}

/*
 * thunk
 */
export function updateUser(userId) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(updateUserRequest());

            let user = { ...getState().users.userToEdit };
            const updatedFields = getState().users.updatedFields;

            // remove fields that were not updated
            Object.keys(user).forEach(field => {
                if (!updatedFields[field]) {
                    delete user[field];
                }
            });

            convertUserSettings(user, false);
            log.debug('[updateUser]', user);

            const response = await api.updateUser(
                service._id,
                userId,
                user,
                authHelper.getToken()
            );
            if (!response.error) {
                dispatch(updateUserSuccess(response.user));
                dispatch(requestUsers());
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(updateUserFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

/*
 * action creators
 */
function deleteUserRequest() {
    return { type: types.DELETE_USER };
}

function deleteUserSuccess(userId) {
    return { type: types.DELETE_USER_SUCCESS, userId };
}

function deleteUserFailure(error) {
    return { type: types.DELETE_USER_FAILURE, error };
}

/*
 * thunk
 */
export function deleteUser(userId) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(deleteUserRequest());
            const response = await api.deleteUser(
                service._id,
                userId,
                authHelper.getToken()
            );
            if (!response.error) {
                dispatch(deleteUserSuccess(userId));
                dispatch(requestUsers());
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(deleteUserFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

/*
 * action creators
 */
function requestExportDataRequest() {
    return { type: types.REQUEST_EXPORT_DATA };
}

function requestExportDataSuccess(exportData) {
    return { type: types.REQUEST_EXPORT_DATA_SUCCESS, exportData };
}

function requestExportDataFailure(error) {
    return { type: types.REQUEST_EXPORT_DATA_FAILURE, error };
}

/*
 * thunk
 */
export function requestExportData({ fields, sort, range }) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(requestExportDataRequest());
            const response = await api.getUsers(
                service._id,
                fields,
                sort,
                range,
                undefined,
                authHelper.getToken()
            );
            if (!response.error) {
                log.debug('[requestExportData]', response.users);
                dispatch(requestExportDataSuccess(response.users));
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(requestExportDataFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

export function resetExportData() {
    return { type: types.RESET_EXPORT_DATA };
}

/*
 * action creators
 */
function syncUsersRequest() {
    return { type: types.SYNC_USERS };
}

function syncUsersProgress(progress) {
    return { type: types.SYNC_USERS_PROGRESS, progress };
}

function syncUsersSuccess() {
    return { type: types.SYNC_USERS_SUCCESS };
}

function syncUsersFailure(error) {
    return { type: types.SYNC_USERS_FAILURE, error };
}

/*
 * thunk
 */
export function syncUsers({ fields, sort, range }) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(syncUsersRequest());
            dispatch(syncUsersProgress({ state: 'running', progress: 0 }));

            const response = await api.syncUsers(
                service._id,
                authHelper.getToken()
            );

            if (!response.error) {
                dispatch(getSyncUsersProgress({ fields, sort, range }, 5000));
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(syncUsersFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

function getSyncUsersProgress({ fields, sort, range }, retryTimeout) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            setTimeout(async () => {
                dispatch(syncUsersRequest());

                const response = await api.getSyncUsersProgress(
                    service._id,
                    authHelper.getToken()
                );
                if (response.error) {
                    return handleError(response.error, {
                        fatal: error => dispatch(forceLogout(error)),
                        nonFatal: error => dispatch(syncUsersFailure(error))
                    });
                }

                if (response.state === 'error') {
                    dispatch(
                        syncUsersFailure(
                            'error while synchronizing users with keycloak'
                        )
                    ); //TODO?
                } else if (response.state === 'completed') {
                    dispatch(syncUsersSuccess());
                    dispatch(requestUsers());
                } else {
                    dispatch(
                        syncUsersProgress({
                            state: response.state,
                            progress: response.progress
                        })
                    );
                    dispatch(
                        getSyncUsersProgress(
                            { fields, sort, range },
                            ((100 - response.progress) / 100) * 5000
                        )
                    );
                }
            }, retryTimeout);
        } else {
            dispatch(noServiceFound());
        }
    };
}

/*
 * action creators
 */
export function startEditingUser(userData) {
    return { type: types.START_EDITING_USER, userData };
}

export function stopEditingUser() {
    return { type: types.STOP_EDITING_USER };
}

export function editUser(currentFields, update) {
    return { type: types.EDIT_USER, currentFields, update };
}

export function addUserValidation(validationType) {
    return { type: types.ADD_USER_VALIDATION, validationType };
}

function resetPage() {
    return { type: types.RESET_USER_PAGE };
}

/*
 * thunk
 */
export function resetUsersPage(loadUsers = false) {
    return async dispatch => {
        dispatch(resetPage());
        if (loadUsers) {
            dispatch(requestUsers());
        }
    };
}
