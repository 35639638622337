import 'react-app-polyfill/ie11';

import 'core-js/features/array/includes';
import 'core-js/features/array/find';
import 'core-js/features/array/fill';
import 'core-js/features/object/entries';
import 'core-js/features/object/values';
import 'core-js/features/string/starts-with';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import rootReducer from './rootReducer';

import { createAuthMiddleware } from './middleware/authMiddleware';
import { createMessageMiddleware } from './middleware/messageMiddleware';

import './css/index.css';
import './css/serenityTheme/theme-adiacom.scss';
import './css/serenityTheme/layout-adiacom.scss';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';

import Manager from './Manager';
import { getPreloadLanguage } from './features/base/util/helpers';
import al5ApiService from './services/al5ApiService';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const history = createBrowserHistory();
const language = getPreloadLanguage();
const authMiddleware = createAuthMiddleware();
const messageMiddleware = createMessageMiddleware();

const store = createStore(
    rootReducer(history),
    {
        i18n: { language: language }
    },
    composeEnhancers(
        applyMiddleware(
            routerMiddleware(history),
            thunk.withExtraArgument(al5ApiService),
            authMiddleware,
            messageMiddleware
        )
    )
);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Manager />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);
