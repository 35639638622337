import * as types from './actionTypes';

export const initialState = {
    isLoading: false,
    isEditing: false,
    apiKeyToAdd: undefined,
    addValidationSet: {},
    apiKeyToEdit: undefined,
    updatedFields: undefined,
    editValidationSet: {},
    apiKeys: [],
    contentRange: undefined,
    error: undefined
};

/**
 *
 * @param state
 * @param action
 * @returns new state
 */
export default function apiKeys(state = initialState, action) {
    switch (action.type) {
        case types.REQUEST_API_KEYS:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.REQUEST_API_KEYS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                apiKeys: action.apiKeys || [],
                contentRange: action.contentRange
            };
        case types.REQUEST_API_KEYS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case types.ADD_API_KEY:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.ADD_API_KEY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                apiKeyToAdd: undefined,
                addValidationSet: {}
            };
        case types.ADD_API_KEY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case types.UPDATE_API_KEY:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.UPDATE_API_KEY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isEditing: false,
                apiKeyToEdit: undefined,
                updatedFields: undefined,
                editValidationSet: {}
            };
        case types.UPDATE_API_KEY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case types.DELETE_API_KEY:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.DELETE_API_KEY_SUCCESS:
            let newState = {
                ...state,
                isLoading: false
            };
            if (state.isEditing && state.apiKeyToEdit._id === action.apiKeyId) {
                newState.isEditing = false;
                newState.apiKeyToEdit = undefined;
                newState.editValidationSet = {};
            }
            return newState;
        case types.DELETE_API_KEY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case types.START_EDITING_API_KEY:
            return {
                ...state,
                isEditing: true,
                apiKeyToEdit: action.apiKeyData,
                updatedFields: {}
            };
        case types.STOP_EDITING_API_KEY:
            return {
                ...state,
                isEditing: false,
                apiKeyToEdit: undefined,
                updatedFields: undefined,
                editValidationSet: {}
            };
        case types.EDIT_API_KEY:
            if (state.isEditing) {
                return {
                    ...state,
                    apiKeyToEdit: {
                        ...action.currentFields,
                        [action.update.field]: action.update.value
                    },
                    updatedFields: {
                        ...state.updatedFields,
                        [action.update.field]: true
                    }
                };
            } else {
                return {
                    ...state,
                    apiKeyToAdd: {
                        ...action.currentFields,
                        [action.update.field]: action.update.value
                    }
                };
            }
        case types.ADD_API_KEY_VALIDATION:
            if (state.isEditing) {
                return {
                    ...state,
                    editValidationSet: {
                        ...state.editValidationSet,
                        [action.validationType]: true
                    }
                };
            } else {
                return {
                    ...state,
                    addValidationSet: {
                        ...state.addValidationSet,
                        [action.validationType]: true
                    }
                };
            }
        case types.RESET_API_KEYS_PAGE:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
