import * as types from './actionTypes';

export const initialState = {
    isLoading: false,
    isEditing: false,

    serviceToAdd: undefined,
    addValidationSet: {},

    serviceToEdit: undefined,
    updatedFields: undefined,
    editValidationSet: {},

    services: [],
    requestParameters: {
        fields: undefined,
        sort: undefined,
        range: undefined,
        search: []
    },
    contentRange: undefined,

    error: undefined
};

/**
 *
 * @param state
 * @param action
 * @returns new state
 */
export default function services(state = initialState, action) {
    switch (action.type) {
        case types.UPDATE_SERVICE_REQUEST_PARAMETERS:
            const update = { ...state.requestParameters };
            for (let requestParameter in action.requestParameters) {
                update[requestParameter] =
                    action.requestParameters[requestParameter];
            }

            return {
                ...state,
                requestParameters: update
            };

        case types.REQUEST_SERVICES:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.REQUEST_SERVICES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                services: action.services || [],
                contentRange: action.contentRange
            };
        case types.REQUEST_SERVICES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case types.ADD_SERVICE:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.ADD_SERVICE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                serviceToAdd: undefined,
                addValidationSet: {}
            };
        case types.ADD_SERVICE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case types.UPDATE_SERVICE:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.UPDATE_SERVICE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isEditing: false,
                serviceToEdit: undefined,
                updatedFields: undefined,
                editValidationSet: {}
            };
        case types.UPDATE_SERVICE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case types.DELETE_SERVICE:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.DELETE_SERVICE_SUCCESS:
            let newState = {
                ...state,
                isLoading: false
            };
            if (
                state.isEditing &&
                state.serviceToEdit._id === action.serviceId
            ) {
                newState.isEditing = false;
                newState.serviceToEdit = undefined;
                newState.editValidationSet = {};
            }
            return newState;
        case types.DELETE_SERVICE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case types.START_EDITING_SERVICE:
            return {
                ...state,
                isEditing: true,
                serviceToEdit: action.serviceData,
                updatedFields: {}
            };
        case types.STOP_EDITING_SERVICE:
            return {
                ...state,
                isEditing: false,
                serviceToEdit: undefined,
                updatedFields: undefined,
                editValidationSet: {}
            };
        case types.EDIT_SERVICE:
            if (state.isEditing) {
                return {
                    ...state,
                    serviceToEdit: {
                        ...action.currentFields,
                        [action.update.field]: action.update.value
                    },
                    updatedFields: {
                        ...state.updatedFields,
                        [action.update.field]: true
                    }
                };
            } else {
                return {
                    ...state,
                    serviceToAdd: {
                        ...action.currentFields,
                        [action.update.field]: action.update.value
                    }
                };
            }
        case types.ADD_SERVICE_VALIDATION:
            if (state.isEditing) {
                return {
                    ...state,
                    editValidationSet: {
                        ...state.editValidationSet,
                        [action.validationType]: true
                    }
                };
            } else {
                return {
                    ...state,
                    addValidationSet: {
                        ...state.addValidationSet,
                        [action.validationType]: true
                    }
                };
            }
        case types.RESET_SERVICES_PAGE:
            const { services, ...partialInitialState } = initialState;
            return {
                ...state,
                ...partialInitialState
            };
        default:
            return state;
    }
}
