import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import routes from '../../constants/routes';

export class AppBreadcrumb extends Component {
    render() {
        return (
            <div className="layout-breadcrumb">
                <ul>
                    <Link to={routes.HOME}>
                        <li>
                            <button className="p-link">
                                <i className="material-icons">home</i>
                            </button>
                        </li>
                    </Link>
                    <li>{this.props.pathname}</li>
                </ul>
            </div>
        );
    }
}
