export const REQUEST_SESSIONS = 'REQUEST_SESSIONS';
export const REQUEST_SESSIONS_SUCCESS = 'REQUEST_SESSIONS_SUCCESS';
export const REQUEST_EXPORT_DATA_SUCCESS = 'REQUEST_EXPORT_DATA_SUCCESS';
export const REQUEST_SESSIONS_FAILURE = 'REQUEST_SESSIONS_FAILURE';

export const RESET_EXPORT_DATA = 'RESET_EXPORT_DATA';
export const START_EDITING_SESSION = 'START_EDITING_SESSION';
export const STOP_EDITING_SESSION = 'STOP_EDITING_SESSION';

export const PREPARE_DOWNLOAD = 'PREPARE_DOWNLOAD';
export const PREPARE_DOWNLOAD_PROGRESS = 'PREPARE_DOWNLOAD_PROGRESS';
export const PREPARE_DOWNLOAD_SUCCESS = 'PREPARE_DOWNLOAD_SUCCESS';
export const PREPARE_DOWNLOAD_FAILURE = 'PREPARE_DOWNLOAD_FAILURE';
export const PREPARE_DOWNLOAD_DENIED = 'PREPARE_DOWNLOAD_DENIED';
export const RESET_RECORDINGS_DOWNLOAD = 'RESET_RECORDINGS_DOWNLOAD';

export const RESET_SESSION_PAGE = 'RESET_SESSION_PAGE';
