import { log, serviceUrlToAPIUrl } from '../features/base/util/helpers';
import { Config } from '../config/Config';
import { ERRORS } from '../constants/constants';

const getApiUrl = (serviceUrl) => serviceUrlToAPIUrl(serviceUrl);

const parseContentRange = apiResponse => {
    if (apiResponse) {
        try {
            const contentRangeHeader = apiResponse.headers.get('Content-Range');
            if (!contentRangeHeader) {
                return {
                    totalItems: 0,
                    currentItems: { startIndex: 0, endIndex: 0 }
                };
            } else {
                const contentRange = contentRangeHeader
                    .split('items ')[1]
                    .split('/');
                const currentItems = contentRange[0].split('-');

                let parsedContentRange = {};
                parsedContentRange.totalItems = parseInt(contentRange[1]);
                parsedContentRange.currentItems = {
                    startIndex: parseInt(currentItems[0]),
                    endIndex: parseInt(currentItems[1])
                };
                return parsedContentRange;
            }
        } catch (e) {
            log.error(e);
            return {
                totalItems: 0,
                currentItems: { startIndex: 0, endIndex: 0 }
            };
        }
    } else {
        return { totalItems: 0, currentItems: { startIndex: 0, endIndex: 0 } };
    }
};

const al5ApiService = {
    /** LOGIN: user authorisation **/
    serviceLogin: async loginData => {
        const url = getApiUrl(Config.serviceUrl) + '/login/service/' + loginData.serviceId;

        let response = {
            user: undefined,
            token: undefined,
            service: undefined,
            error: undefined,
            captcha: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(loginData.credentials)
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.token = responseJson.token;
                    response.user = responseJson.user;
                } else {
                    response.error = responseJson;
                    response.captcha = responseJson.captcha;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    adminLogin: async loginData => {
        const url = getApiUrl(Config.serviceUrl) + '/login/admin';

        let response = {
            user: undefined,
            service: undefined,
            token: undefined,
            error: undefined,
            captcha: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(loginData.credentials)
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.token = responseJson.token;
                    response.user = responseJson.user;
                } else {
                    response.error = responseJson;
                    response.captcha = responseJson.captcha;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    /** PUBLIC: public routes (no authentication needed) **/
    getPublicServiceInfo: async serviceId => {
        const url = getApiUrl(Config.serviceUrl) + '/public/services/' + serviceId;

        let response = {
            publicServiceInfo: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'GET'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.publicServiceInfo = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    /** TOKEN: check and update tokens **/
    updateAdminToken: async token => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/token/admin/update';

        let response = {
            token: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'GET'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.token = responseJson.token;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    updateServiceToken: async (serviceId, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/token/' + serviceId + '/update';

        let response = {
            token: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'GET'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.token = responseJson.token;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    /** SERVICES: access and administrate services **/
    getServices: async (fields, sort, range, search, token) => {
        let fieldsParam = '';
        let sortParam = '';
        let searchParam = '';

        if (fields && fields.length) {
            fieldsParam += '?fields=';
            fieldsParam += fields.join('%2C');
        }

        if (sort && sort.length) {
            sortParam += (fieldsParam ? '&' : '?') + 'sort=';
            sortParam += sort.join('%2C');
        }

        if (search && search.length) {
            searchParam += (fieldsParam || sortParam ? '&' : '?') + 'search=';
            searchParam += search.join('|');
        }

        const url =
            getApiUrl(Config.serviceUrl) + '/api/v1/services' + fieldsParam + sortParam + searchParam;

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': token
        };

        if (
            range &&
            range.hasOwnProperty('startIndex') &&
            range.hasOwnProperty('endIndex')
        ) {
            headers['Range'] =
                'items=' + range.startIndex + '-' + range.endIndex;
        }

        let response = {
            services: undefined,
            contentRange: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: headers,
                method: 'GET'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200 || apiResponse.status === 206) {
                    response.services = responseJson;
                    response.contentRange = parseContentRange(apiResponse);
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    getService: async (serviceId, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/services/' + serviceId;

        let response = {
            service: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'GET'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.service = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    addService: async (serviceData, token) => {
        const url = Config.multitenantServices
            ? getApiUrl(Config.serviceUrl) + '/api/v1/multitenant-services'
            : getApiUrl(Config.serviceUrl) + '/api/v1/services';

        let response = {
            service: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'POST',
                body: JSON.stringify(serviceData)
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.service = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    updateService: async (serviceId, serviceData, token) => {
        const url = Config.multitenantServices
            ? getApiUrl(Config.serviceUrl) + '/api/v1/multitenant-services/' + serviceId
            : getApiUrl(Config.serviceUrl) + '/api/v1/services/' + serviceId;

        let response = {
            service: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'PUT',
                body: JSON.stringify(serviceData)
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.service = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    deleteService: async (serviceId, token) => {
        const url = Config.multitenantServices
            ? getApiUrl(Config.serviceUrl) + '/api/v1/multitenant-services/' + serviceId
            : getApiUrl(Config.serviceUrl) + '/api/v1/services/' + serviceId;

        let response = {
            service: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'DELETE'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.service = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    /** USERS: user administration **/
    getUsers: async (serviceId, fields, sort, range, search, token) => {
        let fieldsParam = '';
        let sortParam = '';
        let searchParam = '';

        if (fields && fields.length) {
            fieldsParam += '?fields=';
            fieldsParam += fields.join('%2C');
        }

        if (sort && sort.length) {
            sortParam += (fieldsParam ? '&' : '?') + 'sort=';
            sortParam += sort.join('%2C');
        }

        if (search && search.length) {
            searchParam += (fieldsParam || sortParam ? '&' : '?') + 'search=';
            searchParam += search.join('|');
        }

        const url =
            getApiUrl(Config.serviceUrl) +
            '/api/v1/users/' +
            serviceId +
            fieldsParam +
            sortParam +
            searchParam;
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': token
        };

        if (
            range &&
            range.hasOwnProperty('startIndex') &&
            range.hasOwnProperty('endIndex')
        ) {
            headers['Range'] =
                'items=' + range.startIndex + '-' + range.endIndex;
        }

        let response = {
            users: undefined,
            contentRange: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: headers,
                method: 'GET'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200 || apiResponse.status === 206) {
                    response.users = responseJson;
                    response.contentRange = parseContentRange(apiResponse);
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    addUser: async (serviceId, userData, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/users/' + serviceId;

        let response = {
            user: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'POST',
                body: JSON.stringify(userData)
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.user = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    updateUser: async (serviceId, userId, userData, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/users/' + serviceId + '/' + userId;

        let response = {
            user: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'PUT',
                body: JSON.stringify(userData)
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.user = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    deleteUser: async (serviceId, userId, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/users/' + serviceId + '/' + userId;

        let response = {
            user: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'DELETE'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.user = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    syncUsers: async (serviceId, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/users/' + serviceId + '/sync';
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': token
        };

        let response = {
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, { headers, method: 'POST' });
            try {
                if (apiResponse.status !== 200) {
                    response.error = await apiResponse.json();
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    getSyncUsersProgress: async (serviceId, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/users/' + serviceId + '/sync/progress';
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': token
        };

        let response = {
            error: undefined,
            progress: undefined,
            state: undefined
        };

        try {
            const apiResponse = await fetch(url, { headers, method: 'GET' });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.progress = responseJson.progress;
                    response.state = responseJson.state;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    /** MEETINGS: meeting administration **/
    getMeetings: async (serviceId, fields, sort, range, token) => {
        let fieldsParam = '';
        let sortParam = '';

        if (fields && fields.length) {
            fieldsParam += '?fields=';
            fieldsParam += fields.join('%2C');
        }

        if (sort && sort.length) {
            sortParam += (fieldsParam ? '&' : '?') + 'sort=';
            sortParam += sort.join('%2C');
        }

        const url =
            getApiUrl(Config.serviceUrl) + '/api/v1/meetings/' + serviceId + fieldsParam + sortParam;
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': token
        };

        if (
            range &&
            range.hasOwnProperty('startIndex') &&
            range.hasOwnProperty('endIndex')
        ) {
            headers['Range'] =
                'items=' + range.startIndex + '-' + range.endIndex;
        }

        let response = {
            meetings: undefined,
            contentRange: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: headers,
                method: 'GET'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200 || apiResponse.status === 206) {
                    response.meetings = responseJson;
                    response.contentRange = parseContentRange(apiResponse);
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    addMeeting: async (serviceId, meetingData, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/meetings/' + serviceId;
        let response = {
            meeting: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'POST',
                body: JSON.stringify(meetingData)
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.meeting = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    updateMeeting: async (serviceId, meetingId, meetingData, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/meetings/' + serviceId + '/' + meetingId;

        let response = {
            meeting: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'PUT',
                body: JSON.stringify(meetingData)
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.meeting = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    deleteMeeting: async (serviceId, meetingId, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/meetings/' + serviceId + '/' + meetingId;

        let response = {
            meeting: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'DELETE'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.meeting = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    /** ADMIN USERS:  admin user administration **/
    getAdminUsers: async (fields, sort, range, token) => {
        let fieldsParam = '';
        let sortParam = '';

        if (fields && fields.length) {
            fieldsParam += '?fields=';
            fieldsParam += fields.join('%2C');
        }

        if (sort && sort.length) {
            sortParam += (fieldsParam ? '&' : '?') + 'sort=';
            sortParam += sort.join('%2C');
        }

        const url = getApiUrl(Config.serviceUrl) + '/api/v1/admin/users' + fieldsParam + sortParam;
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': token
        };

        if (
            range &&
            range.hasOwnProperty('startIndex') &&
            range.hasOwnProperty('endIndex')
        ) {
            headers['Range'] =
                'items=' + range.startIndex + '-' + range.endIndex;
        }

        let response = {
            adminUsers: undefined,
            contentRange: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: headers,
                method: 'GET'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200 || apiResponse.status === 206) {
                    response.adminUsers = responseJson;
                    response.contentRange = parseContentRange(apiResponse);
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    addAdminUser: async (adminData, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/admin/users';

        let response = {
            adminUser: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'POST',
                body: JSON.stringify(adminData)
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.adminUser = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    updateAdminUser: async (adminUserId, adminData, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/admin/users/' + adminUserId;

        let response = {
            adminUser: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'PUT',
                body: JSON.stringify(adminData)
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.adminUser = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    deleteAdminUser: async (adminId, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/admin/users/' + adminId;

        let response = {
            adminUser: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'DELETE'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.adminUser = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    /**APIKEYS: api key administration (only for users with admin role)**/
    getApiKeys: async (serviceId, fields, sort, range, token) => {
        let fieldsParam = '';
        let sortParam = '';

        if (fields && fields.length) {
            fieldsParam += '?fields=';
            fieldsParam += fields.join('%2C');
        }

        if (sort && sort.length) {
            sortParam += (fieldsParam ? '&' : '?') + 'sort=';
            sortParam += sort.join('%2C');
        }

        const url =
            getApiUrl(Config.serviceUrl) +
            '/api/v1/admin/apikeys/' +
            serviceId +
            fieldsParam +
            sortParam;
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': token
        };

        if (
            range &&
            range.hasOwnProperty('startIndex') &&
            range.hasOwnProperty('endIndex')
        ) {
            headers['Range'] =
                'items=' + range.startIndex + '-' + range.endIndex;
        }

        let response = {
            apiKeys: undefined,
            contentRange: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: headers,
                method: 'GET'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200 || apiResponse.status === 206) {
                    response.apiKeys = responseJson;
                    response.contentRange = parseContentRange(apiResponse);
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    addApiKey: async (serviceId, apiKeyData, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/admin/apikeys/' + serviceId;
        let response = {
            apiKey: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'POST',
                body: JSON.stringify(apiKeyData)
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.apiKey = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    updateApiKey: async (serviceId, apiKeyId, apiKeyData, token) => {
        const url =
            getApiUrl(Config.serviceUrl) + '/api/v1/admin/apikeys/' + serviceId + '/' + apiKeyId;

        let response = {
            apiKey: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'PUT',
                body: JSON.stringify(apiKeyData)
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.apiKey = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    deleteApiKey: async (serviceId, apiKeyId, token) => {
        const url =
            getApiUrl(Config.serviceUrl) + '/api/v1/admin/apikeys/' + serviceId + '/' + apiKeyId;

        let response = {
            apiKey: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'DELETE'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.apiKey = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    /**SESSIONS: access sessions**/
    getSessions: async (serviceId, fields, sort, range, filters, token) => {
        let fieldsParam = '';
        let sortParam = '';
        let filtersParam = '';

        if (fields && fields.length) {
            fieldsParam += '?fields=';
            fieldsParam += fields.join('%2C');
        }

        if (sort && sort.length) {
            sortParam += (fieldsParam ? '&' : '?') + 'sort=';
            sortParam += sort.join('%2C');
        }

        if (filters && filters.length) {
            filtersParam += fieldsParam || sortParam ? '&' : '?';
            filtersParam += filters.join('&');
        }

        const url =
            getApiUrl(Config.serviceUrl) +
            '/api/v1/sessions/' +
            serviceId +
            fieldsParam +
            sortParam +
            filtersParam;
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': token
        };

        if (
            range &&
            range.hasOwnProperty('startIndex') &&
            range.hasOwnProperty('endIndex')
        ) {
            headers['Range'] =
                'items=' + range.startIndex + '-' + range.endIndex;
        }

        let response = {
            sessions: undefined,
            contentRange: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: headers,
                method: 'GET'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200 || apiResponse.status === 206) {
                    response.sessions = responseJson;
                    response.contentRange = parseContentRange(apiResponse);
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    /**RECORDINGS: download session recordings**/
    prepareRecordings: async (serviceId, sessionId, audioOnly, token) => {
        let url = `${getApiUrl(Config.serviceUrl)}/api/v1/recordings/${serviceId}/${sessionId}/prepare`;
        if (audioOnly) {
            url += '/audio';
        }

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': token
        };

        let response = {
            progress: undefined,
            download: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, { headers, method: 'POST' });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.progress = responseJson.progress;
                    response.download = responseJson.downloadLink;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    /**SERVICE_INFO: access additional info about services and meetings**/
    adviserInMeeting: async (serviceId, token) => {
        const url =
            Config.serviceUrl +
            '/serviceinfo/' +
            serviceId +
            '/adviserInMeeting';

        let response = {
            adviserInMeeting: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'GET'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.adviserInMeeting = responseJson.adviserInMeeting;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    getLiveStats: async token => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/admin/live-stats/json';

        let response = {
            stats: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'GET'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.stats = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    /**TOPICS: topics administration**/
    getTopics: async (serviceId, fields, sort, range, token) => {
        let fieldsParam = '';
        let sortParam = '';

        if (fields && fields.length) {
            fieldsParam += '?fields=';
            fieldsParam += fields.join('%2C');
        }

        if (sort && sort.length) {
            sortParam += (fieldsParam ? '&' : '?') + 'sort=';
            sortParam += sort.join('%2C');
        }

        const url =
            getApiUrl(Config.serviceUrl) + '/api/v1/topics/' + serviceId + fieldsParam + sortParam;
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': token
        };

        if (
            range &&
            range.hasOwnProperty('startIndex') &&
            range.hasOwnProperty('endIndex')
        ) {
            headers['Range'] =
                'items=' + range.startIndex + '-' + range.endIndex;
        }

        let response = {
            topics: undefined,
            contentRange: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: headers,
                method: 'GET'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200 || apiResponse.status === 206) {
                    response.topics = responseJson;
                    response.contentRange = parseContentRange(apiResponse);
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    addTopic: async (serviceId, topicData, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/topics/' + serviceId;
        let response = {
            topic: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'POST',
                body: JSON.stringify(topicData)
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.topic = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    updateTopic: async (serviceId, topicId, topicData, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/topics/' + serviceId + '/' + topicId;

        let response = {
            topic: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'PUT',
                body: JSON.stringify(topicData)
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.topic = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    deleteTopic: async (serviceId, topicId, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/topics/' + serviceId + '/' + topicId;

        let response = {
            topic: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'DELETE'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.topic = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    /**MAINTENANCE WINDOWS: maintenance-windows administration**/
    getMaintenanceWindows: async (fields, sort, range, token) => {
        let fieldsParam = '';
        let sortParam = '';

        if (fields && fields.length) {
            fieldsParam += '?fields=';
            fieldsParam += fields.join('%2C');
        }

        if (sort && sort.length) {
            sortParam += (fieldsParam ? '&' : '?') + 'sort=';
            sortParam += sort.join('%2C');
        }

        const url =
            getApiUrl(Config.serviceUrl) + '/api/v1/admin/maintenance-windows/'+ fieldsParam + sortParam;
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': token
        };

        if (
            range &&
            range.hasOwnProperty('startIndex') &&
            range.hasOwnProperty('endIndex')
        ) {
            headers['Range'] =
                'items=' + range.startIndex + '-' + range.endIndex;
        }

        let response = {
            maintenanceWindows: undefined,
            contentRange: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: headers,
                method: 'GET'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200 || apiResponse.status === 206) {
                    response.maintenanceWindows = responseJson;
                    response.contentRange = parseContentRange(apiResponse);
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    addMaintenanceWindow: async (maintenanceWindowData, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/admin/maintenance-windows/';

        let response = {
            maintenanceWindow: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'POST',
                body: JSON.stringify(maintenanceWindowData)
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.maintenanceWindow = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    updateMaintenanceWindow: async (maintenanceWindowId, maintenanceWindowData, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/admin/maintenance-windows/' + maintenanceWindowId;

        let response = {
            maintenanceWindow: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'PUT',
                body: JSON.stringify(maintenanceWindowData)
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.maintenanceWindow = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    deleteMaintenanceWindow: async (maintenanceWindowId, token) => {
        const url = getApiUrl(Config.serviceUrl) + '/api/v1/admin/maintenance-windows/' + maintenanceWindowId;

        let response = {
            maintenanceWindow: undefined,
            error: undefined
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                method: 'DELETE'
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.maintenanceWindow = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    }
};

export default al5ApiService;
