import React, {Component} from 'react';
import {connect} from 'react-redux';

import {
    changeSelectedService,
    logoutWithCheck,
    logout
} from '../auth/actions';
import {changeLanguage} from '../base/i18n/actions';

import '../../css/AppTopbar.scss';
import classNames from 'classnames';

import TranslatedString from '../base/i18n/TranslatedString';
import {getTranslatedString, languages} from '../base/i18n/translations';

import {Dropdown} from 'primereact/dropdown';
import {isAdminUser} from "../base/util/accessControl";

export class AppTopbar extends Component {
    constructor(props) {
        super(props);

        this.handleLanguageSelectChange = this.handleLanguageSelectChange.bind(
            this
        );
        this.handleServiceSelectChange = this.handleServiceSelectChange.bind(
            this
        );
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleServiceSelectChange(e) {
        if (e.target.value.serviceId !== this.props.service) {
            this.props.changeService(e.target.value);
        }
    }

    handleLanguageSelectChange(e) {
        this.props.changeLanguage(e.target.value);
    }

    handleLogout() {
        if (
            this.props.user.roles &&
            this.props.user.roles.includes('adviser') &&
            this.props.service.authMethod === 'keycloak'
        ) {
            // logout with additional check if adviser is currently in a meeting (relevant for keycloak only)
            this.props.logoutWithCheck(this.props.service._id, this.props.user);
        } else {
            this.props.logout(this.props.user);
        }
    }

    onTopbarItemClick(event, item) {
        if (this.props.onTopbarItemClick) {
            this.props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    renderLanguageButtons() {
        const languageButtons = [];
        Object.keys(languages).forEach(language => {
            languageButtons.push(
                <button
                    key={languages[language]}
                    className="p-link"
                    onClick={() =>
                        this.props.changeLanguage(languages[language])
                    }
                >
                    {getTranslatedString(
                        languages[language],
                        languages[language]
                    )}
                    {' - '}
                    {getTranslatedString(
                        this.props.language,
                        languages[language]
                    )}
                </button>
            );
        });

        return languageButtons;
    }

    render() {
        let servicesDropdownEntries = [];
        if (isAdminUser(this.props.user)) {
            servicesDropdownEntries = (
                (this.props.service && this.props.services) ||
                []
            ).map(item => {
                return {
                    label: item.name + ' (' + item._id + ')',
                    value: item._id
                };
            });
        }

        let topbarClass = classNames('topbar-menu fadeInDown', {
            'topbar-menu-active': this.props.topbarMenuActive
        });

        return (
            <div className="layout-topbar">
                {/*<img
                    alt="logo"
                    src={logo}
                    className="mobile-logo"
                />*/}

                <button
                    className="p-link menu-btn"
                    onClick={this.props.onMenuButtonClick}
                >
                    <i className="material-icons">&#xE5D2;</i>
                </button>

                <button
                    className="p-link topbar-menu-btn"
                    onClick={this.props.onTopbarMobileMenuButtonClick}
                >
                    <i className="material-icons">&#xE853;</i>
                </button>

                <div className="layout-topbar-menu-wrapper">
                    <ul className={topbarClass}>
                        <li
                            className={classNames({
                                'active-topmenuitem':
                                    this.props.activeTopbarItem === 'profile'
                            })}
                        >
                            <button
                                className="p-link"
                                onClick={e =>
                                    this.onTopbarItemClick(e, 'profile')
                                }
                            >
                                <i className="topbar-icon material-icons">
                                    account_circle
                                </i>
                                <span className="topbar-item-name profile-name">
                                    {this.props.user &&
                                    this.props.user.username}
                                </span>
                            </button>
                            <ul className="fadeInDown">
                                <li role="menuitem">
                                    <button
                                        className="p-link"
                                        onClick={this.handleLogout}
                                    >
                                        <i className="material-icons">
                                            exit_to_app
                                        </i>
                                        <span>Logout</span>
                                    </button>
                                </li>
                            </ul>
                        </li>
                        <li
                            className={classNames({
                                'active-topmenuitem':
                                    this.props.activeTopbarItem === 'lan'
                            })}
                        >
                            <button
                                className="p-link"
                                onClick={e => this.onTopbarItemClick(e, 'lan')}
                            >
                                <span className="topbar-desktop-dropdown">
                                    <span className="topbar-item-name profile-name">
                                        {this.props.language}
                                    </span>
                                    <i className="topbar-icon material-icons topbar-desktop-dropdown-arrow">
                                        arrow_drop_down
                                    </i>
                                </span>
                                <i className="topbar-icon mobile-only material-icons">
                                    language
                                </i>
                                <span className="topbar-item-name">
                                    <TranslatedString id="language"/>
                                </span>
                            </button>
                            <ul className="fadeInDown">
                                <li role="menuitem">
                                    {this.renderLanguageButtons()}
                                </li>
                            </ul>
                        </li>
                        {servicesDropdownEntries.length > 0 && (
                            <li
                                className={classNames(
                                    'service-dropdown',
                                    {
                                        'active-topmenuitem':
                                            this.props.activeTopbarItem ===
                                            'service'
                                    },
                                    'search-item'
                                )}
                                onClick={e =>
                                    this.onTopbarItemClick(e, 'service')
                                }
                            >
                                <Dropdown
                                    //scrollHeight={'100px'}
                                    placeholder={this.props.service._id}
                                    value={this.props.service._id}
                                    options={servicesDropdownEntries}
                                    filter={true}
                                    filterPlaceholder="Select Service"
                                    filterBy="serviceId"
                                    onChange={this.handleServiceSelectChange}
                                />
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        language: state.i18n.language,
        user: state.auth.user,
        service: state.auth.service,
        services: state.auth.services
    };
};

const mapDispatchToProps = {
    logoutWithCheck,
    logout,
    changeService: changeSelectedService,
    changeLanguage
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppTopbar);
