export const REQUEST_MAINTENANCE_WINDOWS = 'REQUEST_MAINTENANCE_WINDOWS';
export const REQUEST_MAINTENANCE_WINDOWS_SUCCESS = 'REQUEST_MAINTENANCE_WINDOWS_SUCCESS';
export const REQUEST_MAINTENANCE_WINDOWS_FAILURE = 'REQUEST_MAINTENANCE_WINDOWS_FAILURE';

export const ADD_MAINTENANCE_WINDOW = 'ADD_MAINTENANCE_WINDOW';
export const ADD_MAINTENANCE_WINDOW_SUCCESS = 'ADD_MAINTENANCE_WINDOW_SUCCESS';
export const ADD_MAINTENANCE_WINDOW_FAILURE = 'ADD_MAINTENANCE_WINDOW_FAILURE';

export const UPDATE_MAINTENANCE_WINDOW = 'UPDATE_MAINTENANCE_WINDOW';
export const UPDATE_MAINTENANCE_WINDOW_SUCCESS = 'UPDATE_MAINTENANCE_WINDOW_SUCCESS';
export const UPDATE_MAINTENANCE_WINDOW_FAILURE = 'UPDATE_MAINTENANCE_WINDOW_FAILURE';

export const DELETE_MAINTENANCE_WINDOW = 'DELETE_MAINTENANCE_WINDOW';
export const DELETE_MAINTENANCE_WINDOW_SUCCESS = 'DELETE_MAINTENANCE_WINDOW_SUCCESS';
export const DELETE_MAINTENANCE_WINDOW_FAILURE = 'DELETE_MAINTENANCE_WINDOW_FAILURE';

export const START_EDITING_MAINTENANCE_WINDOW = 'START_EDITING_MAINTENANCE_WINDOW';
export const STOP_EDITING_MAINTENANCE_WINDOW = 'STOP_EDITING_MAINTENANCE_WINDOW';
export const EDIT_MAINTENANCE_WINDOW = 'EDIT_MAINTENANCE_WINDOW';
export const ADD_MAINTENANCE_WINDOW_VALIDATION = 'ADD_MAINTENANCE_WINDOW_VALIDATION';

export const RESET_MAINTENANCE_WINDOWS_PAGE = 'RESET_MAINTENANCE_WINDOWS_PAGE';
