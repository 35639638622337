export const PUBLIC_SERVICE_INFO_REQUEST = 'PUBLIC_SERVICE_INFO_REQUEST';
export const PUBLIC_SERVICE_INFO_SUCCESS = 'PUBLIC_SERVICE_INFO_SUCCESS';
export const PUBLIC_SERVICE_INFO_FAILURE = 'PUBLIC_SERVICE_INFO_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const RE_LOGIN_CHECK = 'RE_LOGIN_CHECK';
export const RE_LOGIN_CHECKED = 'RE_LOGIN_CHECKED';

export const RE_LOGIN_REQUEST = 'RE_LOGIN_REQUEST';
export const RE_LOGIN_SUCCESS = 'RE_LOGIN_SUCCESS';
export const RE_LOGIN_FAILURE = 'RE_LOGIN_FAILURE';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_CONFIRMATION_NEEDED = 'LOGOUT_CONFIRMATION_NEEDED';
export const CANCEL_LOGOUT = 'CANCEL_LOGOUT';
export const FORCE_LOGOUT = 'FORCE_LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const NO_SERVICE = 'NO_SERVICE';
export const SELECT_SERVICE = 'SELECT_SERVICE';

export const CHANGE_SELECTED_SERVICE = 'CHANGE_SELECTED_SERVICE';
export const CHANGE_SELECTED_SERVICE_SUCCESS =
    'CHANGE_SELECTED_SERVICE_SUCCESS';
export const CHANGE_SELECTED_SERVICE_FAILURE =
    'CHANGE_SELECTED_SERVICE_FAILURE';

export const UPDATE_SELECTED_SERVICE = 'UPDATE_SELECTED_SERVICE';

export const GET_SERVICES_LIST = 'GET_SERVICES_LIST';
export const GET_SERVICES_LIST_SUCCESS = 'GET_SERVICES_LIST_SUCCESS';
export const GET_SERVICES_LIST_FAILURE = 'GET_SERVICES_LIST_FAILURE';

export const GET_SERVICE_INFO = 'GET_SERVICE_INFO';
export const GET_SERVICE_INFO_SUCCESS = 'GET_SERVICE_INFO_SUCCESS';
export const GET_SERVICE_INFO_FAILURE = 'GET_SERVICE_INFO_FAILURE';
