import * as types from './actionTypes';

export const initialState = {
    stats: [],
    statsLoading: false,
    errorMessage: null
};

export default function dashboard(state = initialState, action) {
    switch (action.type) {
        case types.GET_LIVE_STATS_REQUEST:
            return {
                ...state,
                statsLoading: true,
                errorMessage: null
            };
        case types.GET_LIVE_STATS_SUCCESS:
            return {
                ...state,
                statsLoading: false,
                stats: action.stats
            };
        case types.GET_LIVE_STATS_FAILURE:
            return {
                ...state,
                statsLoading: false,
                stats: [],
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
}
