import * as types from './actionTypes';

export const initialState = {
    isLoading: false,
    isEditing: false,

    sessionToEdit: undefined,

    sessions: [],
    contentRange: undefined,

    exportData: undefined,

    downloadCancelled: undefined,
    prepareDownloadState: undefined,
    downloadLink: undefined,
    downloadError: undefined,

    error: undefined
};

/**
 *
 * @param state
 * @param action
 * @returns new state
 */
export default function sessions(state = initialState, action) {
    switch (action.type) {
        case types.REQUEST_SESSIONS:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.REQUEST_SESSIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                sessions: action.sessions || [],
                contentRange: action.contentRange
            };
        case types.REQUEST_SESSIONS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case types.REQUEST_EXPORT_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                exportData: action.exportData || undefined
            };
        case types.RESET_EXPORT_DATA:
            return {
                ...state,
                exportData: undefined
            };

        case types.PREPARE_DOWNLOAD:
            return {
                ...state,
                isLoading: true,
                downloadCancelled: false,
                ...(!state.prepareDownloadState && {
                    prepareDownloadState: { progress: 0, tries: 0 }
                }),
                downloadLink: undefined,
                downloadError: undefined
            };
        case types.PREPARE_DOWNLOAD_PROGRESS:
            if (state.downloadCancelled) {
                return {
                    ...state,
                    isLoading: false,
                    downloadCancelled: undefined
                };
            } else {
                return {
                    ...state,
                    prepareDownloadState: {
                        progress: action.progress,
                        tries: state.prepareDownloadState.tries + 1
                    }
                };
            }
        case types.PREPARE_DOWNLOAD_SUCCESS:
            if (state.downloadCancelled) {
                return {
                    ...state,
                    isLoading: false,
                    downloadCancelled: undefined
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    prepareDownloadState: {
                        progress: 100,
                        tries: state.prepareDownloadState.tries + 1
                    },
                    downloadLink: action.downloadLink
                };
            }
        case types.PREPARE_DOWNLOAD_FAILURE:
            if (state.downloadCancelled) {
                return {
                    ...state,
                    isLoading: false,
                    downloadCancelled: undefined
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    downloadError: action.error
                };
            }
        case types.RESET_RECORDINGS_DOWNLOAD:
            return {
                ...state,
                downloadCancelled:
                    state.downloadLink || state.downloadError
                        ? undefined
                        : true,
                prepareDownloadState: undefined,
                downloadLink: undefined,
                downloadRecordingsProgress: undefined,
                downloadError: undefined
            };

        case types.START_EDITING_SESSION:
            return {
                ...state,
                isEditing: true,
                sessionToEdit: action.sessionData
            };
        case types.STOP_EDITING_SESSION:
            return {
                ...state,
                isEditing: false,
                sessionToEdit: undefined
            };
        case types.RESET_SESSION_PAGE:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
