import * as types from './actionTypes';
import { handleError, log } from '../base/util/helpers';
import { forceLogout, noServiceFound } from '../auth/actions';
import authHelper from '../base/util/authHelper';

/*
 * action creators
 */
function requestApiKeysRequest() {
    return { type: types.REQUEST_API_KEYS };
}

function requestApiKeysSuccess(apiKeys, contentRange) {
    return { type: types.REQUEST_API_KEYS_SUCCESS, apiKeys, contentRange };
}

function requestApiKeysFailure(error) {
    return { type: types.REQUEST_API_KEYS_FAILURE, error };
}

/*
 * thunk
 */
export function requestApiKeys({ fields, sort, range }) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(requestApiKeysRequest());

            const response = await api.getApiKeys(
                service._id,
                fields,
                sort,
                range,
                authHelper.getToken()
            );
            if (!response.error) {
                log.debug('[requestApiKeys]', response.apiKeys);

                dispatch(
                    requestApiKeysSuccess(
                        response.apiKeys,
                        response.contentRange
                    )
                );
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(requestApiKeysFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

/*
 * action creators
 */
function addApiKeyRequest() {
    return { type: types.ADD_API_KEY };
}

function addApiKeySuccess(apiKey) {
    return { type: types.ADD_API_KEY_SUCCESS, apiKey };
}

function addApiKeyFailure(error) {
    return { type: types.ADD_API_KEY_FAILURE, error };
}

/*
 * thunk
 */
export function addApiKey({ fields, sort, range }) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(addApiKeyRequest());

            const apiKey = getState().apiKeys.apiKeyToAdd;
            log.debug('[addApiKey]', apiKey);

            const response = await api.addApiKey(
                service._id,
                apiKey,
                authHelper.getToken()
            );
            if (!response.error) {
                dispatch(addApiKeySuccess(response.apiKey));
                dispatch(requestApiKeys({ fields, sort, range }));
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(addApiKeyFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

/*
 * action creators
 */
function updateApiKeyRequest() {
    return { type: types.UPDATE_API_KEY };
}

function updateApiKeySuccess(apiKey) {
    return { type: types.UPDATE_API_KEY_SUCCESS, apiKey };
}

function updateApiKeyFailure(error) {
    return { type: types.UPDATE_API_KEY_FAILURE, error };
}

/*
 * thunk
 */
export function updateApiKey(apiKeyId, { fields, sort, range }) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(updateApiKeyRequest());

            const apiKey = { ...getState().apiKeys.apiKeyToEdit };
            const updatedFields = getState().apiKeys.updatedFields;

            // remove fields that were not updated
            Object.keys(apiKey).forEach(field => {
                if (!updatedFields[field]) {
                    delete apiKey[field];
                }
            });
            log.debug('[updateApiKey]', apiKey);

            const response = await api.updateApiKey(
                service._id,
                apiKeyId,
                apiKey,
                authHelper.getToken()
            );
            if (!response.error) {
                dispatch(updateApiKeySuccess(response.apiKey));
                dispatch(requestApiKeys({ fields, sort, range }));
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(updateApiKeyFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

/*
 * action creators
 */
function deleteApiKeyRequest() {
    return { type: types.DELETE_API_KEY };
}

function deleteApiKeySuccess(apiKeyId) {
    return { type: types.DELETE_API_KEY_SUCCESS, apiKeyId };
}

function deleteApiKeyFailure(error) {
    return { type: types.DELETE_API_KEY_FAILURE, error };
}

/*
 * thunk
 */
export function deleteApiKey(apiKeyId, { fields, sort, range }) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(deleteApiKeyRequest());

            const response = await api.deleteApiKey(
                service._id,
                apiKeyId,
                authHelper.getToken()
            );
            if (!response.error) {
                dispatch(deleteApiKeySuccess(apiKeyId));
                dispatch(requestApiKeys({ fields, sort, range }));
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(deleteApiKeyFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

/*
 * action creators
 */
export function startEditingApiKey(apiKeyData) {
    return { type: types.START_EDITING_API_KEY, apiKeyData };
}

export function stopEditingApiKey() {
    return { type: types.STOP_EDITING_API_KEY };
}

export function editApiKey(currentFields, update) {
    return { type: types.EDIT_API_KEY, currentFields, update };
}

export function addApiKeyValidation(validationType) {
    return { type: types.ADD_API_KEY_VALIDATION, validationType };
}

function resetPage() {
    return { type: types.RESET_API_KEYS_PAGE };
}

/*
 * thunk
 */
export function resetApiKeysPage(
    loadApiKeys = false,
    { fields, sort, range } = {}
) {
    return async dispatch => {
        dispatch(resetPage());
        if (loadApiKeys) {
            dispatch(requestApiKeys({ fields, sort, range }));
        }
    };
}
