import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../../css/features/RevisionViewer.scss';

export class RevisionViewer extends Component {

    render() {
        const iFrameUrl = this.props.applicationsUrl + '/revisionViewer?nokeycloak=true&lang=' + this.props.language;

        return (
            <iframe
                className="revision-viewer-iframe"
                title="Revision Viewer"
                src={iFrameUrl}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        applicationsUrl: state.auth.service.meetingsSettings ? state.auth.service.meetingsSettings.applicationsUrl : state.auth.service['meetings.applicationsUrl'],
        language: state.i18n.language
    };
};



export default connect(mapStateToProps, undefined)(RevisionViewer);
