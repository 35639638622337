import * as types from './actionTypes';
import Routes from '../../constants/routes';
import { push } from 'connected-react-router';
import {
    adiaLiveSettings,
    handleError,
    log,
    readFromSessionStorage,
    removeFromSessionStorage,
    routeWithServiceId,
    storeToLocalStorage,
    storeToSessionStorage
} from '../base/util/helpers';
import authHelper from '../base/util/authHelper';
import { convertServiceSettings } from '../services/actions';

/*
 * publicServiceInfo action creators
 */
function getPublicServiceInfoRequest() {
    return { type: types.PUBLIC_SERVICE_INFO_REQUEST };
}

function getPublicServiceInfoSuccess(publicServiceInfo) {
    return { type: types.PUBLIC_SERVICE_INFO_SUCCESS, publicServiceInfo };
}

function getPublicServiceInfoFailure(error) {
    return { type: types.PUBLIC_SERVICE_INFO_FAILURE, error };
}

/*
 * publicServiceInfo thunk
 */
export function requestPublicServiceInfo(serviceId, keycloakLogin) {
    return async (dispatch, getState, api) => {
        dispatch(getPublicServiceInfoRequest());

        const response = await api.getPublicServiceInfo(serviceId);
        if (!response.error) {
            // convert service flags to properties
            adiaLiveSettings.flagsToSettings(response.publicServiceInfo);

            dispatch(getPublicServiceInfoSuccess(response.publicServiceInfo));

            // login immediately with keycloak if authMethod is 'keycloak' and a 'keycloakLogin' method is defined
            if (
                keycloakLogin &&
                response.publicServiceInfo &&
                response.publicServiceInfo.authMethod === 'keycloak' &&
                response.publicServiceInfo.keycloakSettings
            ) {
                dispatch(keycloakLogin);
            }
        } else {
            handleError(response.error, err =>
                dispatch(getPublicServiceInfoFailure(err))
            );

            //redirect to HOME route if there is some error; this will trigger the serviceId input page again
            dispatch(push(Routes.HOME));
        }
    };
}

/*
 * login action creators
 */
export function login(loginData) {
    return { type: types.LOGIN_REQUEST, loginData };
}

export function loginSuccess(user, service) {
    return { type: types.LOGIN_SUCCESS, user, service };
}

export function loginFailure(error, captcha) {
    return { type: types.LOGIN_FAILURE, error, captcha };
}

/*
 * serviceLogin thunk
 */
export function serviceLogin(loginData) {
    return async (dispatch, getState, api) => {
        const success = service => {
            dispatch(loginSuccess(response.user, service));

            //redirect to selected path or default path
            const path = getState().router.location.pathname;
            if (
                !path.startsWith(`/${serviceId}`) ||
                path === routeWithServiceId(Routes.SERVICE_LOGIN, serviceId)
            ) {
                dispatch(
                    push(routeWithServiceId(Routes.SERVICE_SESSIONS, serviceId))
                );
            }
        };
        const failure = (error, captcha) => {
            handleError(error, err => dispatch(loginFailure(err, captcha)));
        };

        const serviceId = loginData.serviceId;
        const response = await api.serviceLogin(loginData);

        if (response.user) {
            storeToSessionStorage('token', response.token);
            storeToSessionStorage('user', JSON.stringify(response.user));
            storeToLocalStorage('serviceId', serviceId);

            /*
            // -> not needed anymore because authMethod and adviserUserCustomFields are in publicService
            // get service data if the user has the 'service' role;
            // otherwise just proceed with successful login (other roles don't need all the fields of a service)
            if (
                response.user.roles &&
                response.user.roles.includes('service')
            ) {
                dispatch(
                    getServiceInfo(serviceId, (error, service) => {
                        if (!error) {
                            return success(service);
                        }
                        failure(error);
                    })
                );
            } else {
                success(getState().auth.publicServiceInfo);
            }
            */

            success(getState().auth.publicServiceInfo);
        } else {
            failure(response.error, response.captcha);
        }
    };
}

/*
 * adminLogin thunk
 */
export function adminLogin(loginData) {
    return async (dispatch, getState, api) => {
        const success = service => {
            dispatch(loginSuccess(response.user, service));

            //redirect to selected path or default path
            const path = getState().router.location.pathname;
            if (path === Routes.ADMIN_LOGIN) {
                dispatch(push(Routes.SERVICES));
            }
        };
        const failure = (error, captcha) => {
            handleError(error, err => dispatch(loginFailure(err, captcha)));
        };

        const response = await api.adminLogin(loginData);

        if (response.user) {
            storeToSessionStorage('token', response.token);
            storeToSessionStorage('user', JSON.stringify(response.user));

            // request all available services to make service switching possible
            await dispatch(getServicesList());

            // if no services are created yet, set auth.service to undefined; otherwise get the information for the first service
            const services = getState().auth.services;

            if (services) {
                if (services.length > 0) {
                    dispatch(
                        getServiceInfo(services[0]._id, (error, service) => {
                            if (!error) {
                                return success(service);
                            }
                            failure(error);
                        })
                    );
                } else {
                    success();
                }
            } else {
                // login failed because servicesList could not be fetched
                failure(getState().auth.error);
            }
        } else {
            failure(response.error, response.captcha);
        }
    };
}

/*
 * keycloakLogin thunk
 */
export function keycloakLogin(user, serviceId) {
    return async (dispatch, getState) => {
        const success = service => {
            dispatch(loginSuccess(user, service));

            //redirect to selected path or default path
            const path = getState().router.location.pathname;
            if (
                !path.startsWith(`/${serviceId}`) ||
                path === routeWithServiceId(Routes.SERVICE_LOGIN, serviceId)
            ) {
                dispatch(
                    push(routeWithServiceId(Routes.SERVICE_SESSIONS, serviceId))
                );
            }
        };

        storeToSessionStorage('token', 'keycloak');
        storeToSessionStorage('user', JSON.stringify(user));
        storeToLocalStorage('serviceId', serviceId);

        /* -> not needed anymore because authMethod and adviserUserCustomFields are in publicService
        const failure = (error, captcha) => {
            handleError(error, err => dispatch(loginFailure(err, captcha)));
        };
        // get service data if the user has the 'service' role;
        // otherwise just proceed with successful login (other roles don't need all the fields of a service)
        if (false && user && user.roles && user.roles.includes('service')) {
            dispatch(
                getServiceInfo(serviceId, (error, service) => {
                    if (!error) {
                        return success(service);
                    }
                    failure(error);
                })
            );
        } else {
            success(getState().auth.publicServiceInfo);
        }*/

        success(getState().auth.publicServiceInfo);
    };
}

/*
 * updateToken thunk
 */
export function updateToken(loginType, currentToken, serviceId) {
    return async (dispatch, getState, api) => {
        log.info('[updateToken]');

        if (loginType === 'admin') {
            const response = await api.updateAdminToken(currentToken);
            if (!response.error) {
                storeToSessionStorage('token', response.token);
            } else {
                handleError(response.error, err => dispatch(forceLogout(err)));
            }
            return response;
        } else {
            const response = await api.updateServiceToken(
                serviceId,
                currentToken
            );
            if (!response.error) {
                storeToSessionStorage('token', response.token);
            } else {
                handleError(response.error, err => dispatch(forceLogout(err)));
            }
            return response;
        }
    };
}

/*
 * reLogin action creators
 */
export function checkReLogin() {
    return { type: types.RE_LOGIN_CHECK };
}

export function checkReLoginDone() {
    return { type: types.RE_LOGIN_CHECKED };
}

export function reLogin(user, serviceId, keycloak) {
    return { type: types.RE_LOGIN_REQUEST, user, serviceId, keycloak };
}

function reLoginSuccess(user, service) {
    return { type: types.RE_LOGIN_SUCCESS, user, service };
}

function reLoginFailure(error) {
    return { type: types.RE_LOGIN_FAILURE, error };
}

/*
 * reLoginService thunk
 */
export function reLoginService(user, serviceId) {
    return async (dispatch, getState) => {
        const success = service => {
            dispatch(reLoginSuccess(user, service));

            //redirect to selected path or default path
            const path = getState().router.location.pathname;
            if (
                !path.startsWith(`/${serviceId}`) ||
                path === routeWithServiceId(Routes.SERVICE_LOGIN, serviceId)
            ) {
                dispatch(
                    push(routeWithServiceId(Routes.SERVICE_SESSIONS, serviceId))
                );
            }
        };
        const failure = error => {
            handleError(error, err => dispatch(reLoginFailure(err)));
        };

        // update token and at the same time check if token is still valid and server is reachable
        const response = await dispatch(
            updateToken('service', authHelper.getToken(true), serviceId)
        );

        if (!response.error) {
            // request public service info (such that store.auth is the same as after a normal login)
            await dispatch(requestPublicServiceInfo(serviceId));

            if (getState().auth.publicServiceInfo) {
                /* -> not needed anymore because authMethod and adviserUserCustomFields are in publicService
                // get service data if the user has the 'service' role;
                // otherwise just proceed with successful login (other roles don't need all the fields of a service)
                if (
                    false &&
                    user &&
                    user.roles &&
                    user.roles.includes('service')
                ) {
                    dispatch(
                        getServiceInfo(serviceId, (error, service) => {
                            if (!error) {
                                return success(service);
                            }
                            failure(error);
                        })
                    );
                } else {
                    success(getState().auth.publicServiceInfo);
                }*/

                success(getState().auth.publicServiceInfo);
            } else {
                // reLogin failed because publicServiceInfo could not be fetched
                failure(getState().auth.publicServiceInfoError);
            }
        } else {
            // reLogin failed because token could not be updated
            failure(response.error);
        }
    };
}

/*
 * keycloakReLogin thunk
 */
export function keycloakReLogin() {
    return async dispatch => {
        removeFromSessionStorage('token');

        dispatch(checkReLoginDone());
    };
}

/*
 * reLoginAdmin thunk
 */
export function reLoginAdmin(user) {
    return async (dispatch, getState) => {
        const success = service => {
            dispatch(reLoginSuccess(user, service));
        };
        const failure = error => {
            handleError(error, err => dispatch(reLoginFailure(err)));
        };

        // update token and at the same time check token validity and server availability
        const response = await dispatch(
            updateToken('admin', authHelper.getToken(true))
        );

        if (!response.error) {
            // request all available services to make service switching possible
            await dispatch(getServicesList());

            // if no services are created yet, set auth.service to undefined; otherwise get the information for the first service
            const services = getState().auth.services;
            if (services) {
                if (services.length > 0) {
                    // load same service that was selected before page reload (if there is one)
                    const selectedService = readFromSessionStorage(
                        'selectedService'
                    );
                    const serviceToLoad =
                        selectedService &&
                        services.find(
                            service => service._id === selectedService
                        )
                            ? selectedService
                            : services[0]._id;

                    dispatch(
                        getServiceInfo(serviceToLoad, (error, service) => {
                            if (!error) {
                                return success(service);
                            }
                            failure(error);
                        })
                    );
                } else {
                    success();
                }
            } else {
                // reLogin failed because servicesList could not be fetched
                failure(getState().auth.error);
            }
        } else {
            // reLogin failed because token could not be updated
            failure(response.error);
        }
    };
}

/*
 * logout action creators
 */
export function logoutWithCheck(serviceId, user) {
    return async (dispatch, getState, api) => {
        const response = await api.adviserInMeeting(
            serviceId,
            authHelper.getToken()
        );

        if (response.adviserInMeeting === false) {
            dispatch(logout(user));
        } else {
            dispatch(logoutConfirmationNeeded(true));
        }
    };
}

export function logout(user) {
    return { type: types.LOGOUT, user };
}

function logoutConfirmationNeeded(confirmationNeeded) {
    return { type: types.LOGOUT_CONFIRMATION_NEEDED, confirmationNeeded };
}

export function cancelLogout() {
    return { type: types.CANCEL_LOGOUT };
}

export function forceLogout(error) {
    return { type: types.FORCE_LOGOUT, error };
}

export function logoutSuccess() {
    return { type: types.LOGOUT_SUCCESS };
}

/*
 * action creators
 */
function changeSelectedServiceRequest() {
    return { type: types.CHANGE_SELECTED_SERVICE };
}

function changeSelectedServiceSuccess(service) {
    return { type: types.CHANGE_SELECTED_SERVICE_SUCCESS, service };
}

function changeSelectedServiceFailure(error) {
    return { type: types.CHANGE_SELECTED_SERVICE_FAILURE, error };
}

/*
 * thunk
 */
export function changeSelectedService(serviceId, service) {
    return async dispatch => {
        const success = service => {
            if (service) {
                storeToSessionStorage('selectedService', service._id);
            } else {
                removeFromSessionStorage('selectedService');
            }
            dispatch(changeSelectedServiceSuccess(service));
        };
        const failure = error => {
            handleError(error, err =>
                dispatch(changeSelectedServiceFailure(err))
            );
        };

        dispatch(changeSelectedServiceRequest());

        if (service) {
            success(service);
        } else if (serviceId) {
            // request service to be switched to
            dispatch(
                getServiceInfo(serviceId, (error, service) => {
                    if (!error) {
                        return success(service);
                    }
                    failure(error);
                })
            );
        } else {
            success();
        }
    };
}

export function updateSelectedService(service) {
    return { type: types.UPDATE_SELECTED_SERVICE, service };
}

/*
 * action creators
 */
function getServicesListRequest() {
    return { type: types.GET_SERVICES_LIST };
}

function getServicesListSuccess(services) {
    return { type: types.GET_SERVICES_LIST_SUCCESS, services };
}

function getServicesListFailure(error) {
    return { type: types.GET_SERVICES_LIST_FAILURE, error };
}

/*
 * thunk
 */
export function getServicesList() {
    return async (dispatch, getState, api) => {
        dispatch(getServicesListRequest());

        const maxResultSize = 200;
        let result = [];
        let error;
        let counter = 1;

        while (true) {
            const response = await api.getServices(
                ['_id', 'name'],
                undefined,
                {
                    startIndex: (counter - 1) * maxResultSize,
                    endIndex: counter * maxResultSize - 1
                },
                undefined,
                authHelper.getToken()
            );
            if (!response.error) {
                log.debug('[getPartialServicesList]', response.services);
                result = result.concat(response.services);
                if (
                    response.contentRange.currentItems.endIndex >=
                    response.contentRange.totalItems - 1
                ) {
                    break;
                }
            } else {
                error = response.error;
                break;
            }

            counter++;
        }

        if (error) {
            handleError(error, {
                fatal: error => dispatch(forceLogout(error)),
                nonFatal: error => dispatch(getServicesListFailure(error))
            });
        } else {
            log.debug('[getServicesList]', result);
            dispatch(getServicesListSuccess(result));
        }
    };
}

/*
 * action creators
 */
function getServiceInfoRequest() {
    return { type: types.GET_SERVICE_INFO };
}

function getServiceInfoSuccess() {
    return { type: types.GET_SERVICE_INFO_SUCCESS };
}

function getServiceInfoFailure(error) {
    return { type: types.GET_SERVICE_INFO_FAILURE, error };
}

/*
 * thunk
 */
function getServiceInfo(serviceId, callback) {
    return async (dispatch, getState, api) => {
        dispatch(getServiceInfoRequest());

        const response = await api.getService(serviceId, authHelper.getToken());

        if (!response.error) {
            convertServiceSettings([response.service], true);
            log.debug('[getServiceInfo]', response.service);

            dispatch(getServiceInfoSuccess());

            if (callback) {
                callback(null, response.service);
            }
        } else {
            if (callback) {
                handleError(response.error, {
                    fatal: error => {
                        dispatch(getServiceInfoFailure(error));
                        callback(error);
                    },
                    nonFatal: error => {
                        dispatch(getServiceInfoFailure(error));
                        callback(error);
                    }
                });
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(getServiceInfoFailure(error))
                });
            }
        }
    };
}

export function selectService(serviceId) {
    return { type: types.SELECT_SERVICE, serviceId };
}

export function noServiceFound() {
    return { type: types.NO_SERVICE };
}
