import * as types from './actionTypes';

export const initialState = {
    reLoginChecked: false,

    isLoadingPublicServiceInfo: false,
    publicServiceInfo: undefined,
    publicServiceInfoError: undefined,

    logoutConfirmationNeeded: undefined,

    isLoggingIn: false,
    authenticated: false,
    user: undefined,
    service: undefined,
    services: undefined,
    captcha: undefined,
    error: undefined
};

/**
 *
 * @param state
 * @param action
 * @returns new state
 */
export default function auth(state = initialState, action) {
    switch (action.type) {
        case types.SELECT_SERVICE:
            return {
                ...state,
                publicServiceInfoError: undefined,
                error: undefined
            };
        case types.PUBLIC_SERVICE_INFO_REQUEST:
            return {
                ...state,
                isLoadingPublicServiceInfo: true,
                publicServiceInfoError: undefined
            };
        case types.PUBLIC_SERVICE_INFO_SUCCESS:
            return {
                ...state,
                isLoadingPublicServiceInfo: false,
                publicServiceInfo: action.publicServiceInfo || {}
            };
        case types.PUBLIC_SERVICE_INFO_FAILURE:
            return {
                ...state,
                isLoadingPublicServiceInfo: false,
                publicServiceInfoError: action.error
            };
        case types.LOGIN_REQUEST:
            return {
                ...state,
                isLoggingIn: true,
                error: undefined
            };
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                authenticated: true,
                isLoggingIn: false,
                captcha: undefined,
                user: { ...action.user },
                service: { ...action.service }
            };
        case types.LOGIN_FAILURE:
            return {
                ...state,
                authenticated: false,
                isLoggingIn: false,
                error: action.error,
                captcha: action.captcha
            };
        case types.RE_LOGIN_CHECKED:
            return {
                ...state,
                reLoginChecked: true
            };
        case types.RE_LOGIN_REQUEST:
            return {
                ...state,
                isLoggingIn: true,
                error: undefined
            };
        case types.RE_LOGIN_SUCCESS:
            return {
                ...state,
                authenticated: true,
                reLoginChecked: true,
                isLoggingIn: false,
                captcha: undefined,
                user: { ...action.user },
                service: { ...action.service }
            };
        case types.RE_LOGIN_FAILURE:
            return {
                ...state,
                authenticated: false,
                reLoginChecked: true,
                isLoggingIn: false,
                error: action.error,
                captcha: undefined
            };
        case types.LOGOUT_CONFIRMATION_NEEDED:
            return {
                ...state,
                logoutConfirmationNeeded: action.confirmationNeeded
            };
        case types.CANCEL_LOGOUT:
            return {
                ...state,
                logoutConfirmationNeeded: undefined
            };
        case types.FORCE_LOGOUT:
            return {
                ...state,
                error: action.error
            };
        case types.LOGOUT_SUCCESS:
            return {
                ...state,
                logoutConfirmationNeeded: undefined,
                authenticated: false,
                user: undefined,
                service: undefined,
                services: undefined
            };
        case types.CHANGE_SELECTED_SERVICE_SUCCESS:
            return {
                ...state,
                service: action.service
            };
        case types.CHANGE_SELECTED_SERVICE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case types.UPDATE_SELECTED_SERVICE:
            return {
                ...state,
                service: action.service
            };
        case types.GET_SERVICES_LIST:
            return {
                ...state,
                error: undefined
            };
        case types.GET_SERVICES_LIST_SUCCESS:
            return {
                ...state,
                services: action.services || []
            };
        case types.GET_SERVICES_LIST_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case types.GET_SERVICE_INFO:
            return {
                ...state,
                error: undefined
            };
        case types.GET_SERVICE_INFO_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}
