import * as types from './actionTypes';

export const initialState = {
    isLoading: false,
    isEditing: false,
    maintenanceWindowToAdd: undefined,
    addValidationSet: {},
    maintenanceWindowToEdit: undefined,
    updatedFields: undefined,
    editValidationSet: {},
    maintenanceWindows: [],
    contentRange: undefined,
    error: undefined
};

/**
 *
 * @param state
 * @param action
 * @returns new state
 */
export default function topics(state = initialState, action) {
    switch (action.type) {
        case types.REQUEST_MAINTENANCE_WINDOWS:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.REQUEST_MAINTENANCE_WINDOWS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                maintenanceWindows: action.maintenanceWindows || [],
                contentRange: action.contentRange
            };
        case types.REQUEST_MAINTENANCE_WINDOWS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case types.ADD_MAINTENANCE_WINDOW:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.ADD_MAINTENANCE_WINDOW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                maintenanceWindowToAdd: undefined,
                addValidationSet: {}
            };
        case types.ADD_MAINTENANCE_WINDOW_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case types.UPDATE_MAINTENANCE_WINDOW:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.UPDATE_MAINTENANCE_WINDOW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isEditing: false,
                maintenanceWindowToEdit: undefined,
                updatedFields: undefined,
                editValidationSet: {}
            };
        case types.UPDATE_MAINTENANCE_WINDOW_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case types.DELETE_MAINTENANCE_WINDOW:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.DELETE_MAINTENANCE_WINDOW_SUCCESS:
            let newState = {
                ...state,
                isLoading: false
            };
            if (state.isEditing && state.maintenanceWindowToEdit._id === action.maintenanceWindowId) {
                newState.isEditing = false;
                newState.topicToEdit = undefined;
                newState.editValidationSet = {};
            }
            return newState;
        case types.DELETE_MAINTENANCE_WINDOW_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case types.START_EDITING_MAINTENANCE_WINDOW:
            return {
                ...state,
                isEditing: true,
                maintenanceWindowToEdit: action.maintenanceWindowData,
                updatedFields: {}
            };
        case types.STOP_EDITING_MAINTENANCE_WINDOW:
            return {
                ...state,
                isEditing: false,
                maintenanceWindowToEdit: undefined,
                updatedFields: undefined,
                editValidationSet: {}
            };
        case types.EDIT_MAINTENANCE_WINDOW:
            if (state.isEditing) {
                return {
                    ...state,
                    maintenanceWindowToEdit: {
                        ...action.currentFields,
                        [action.update.field]: action.update.value
                    },
                    updatedFields: {
                        ...state.updatedFields,
                        [action.update.field]: true
                    }
                };
            } else {
                return {
                    ...state,
                    maintenanceWindowToAdd: {
                        ...action.currentFields,
                        [action.update.field]: action.update.value
                    }
                };
            }
        case types.ADD_MAINTENANCE_WINDOW_VALIDATION:
            if (state.isEditing) {
                return {
                    ...state,
                    editValidationSet: {
                        ...state.editValidationSet,
                        [action.validationType]: true
                    }
                };
            } else {
                return {
                    ...state,
                    addValidationSet: {
                        ...state.addValidationSet,
                        [action.validationType]: true
                    }
                };
            }
        case types.RESET_MAINTENANCE_WINDOWS_PAGE:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
