import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import '../../css/base/EditButtons.scss';
import { Button } from 'primereact/button';

class EditButtons extends PureComponent {
    /**
     * @param type - type of the action triggered by the button (edit/delete)
     * @returns {Function}
     */
    handleOnClick = type => e => {
        e.preventDefault();
        if (type === 'edit') {
            this.props.handleEditClick(this.props.id);
        } else {
            this.props.handleDeleteClick(this.props.id, this.props.name);
        }
    };

    render() {
        return (
            <div>
                <Button
                    className="edit-button edit"
                    icon="pi-md-create"
                    onClick={this.handleOnClick('edit')}
                    disabled={this.props.isLoading || this.props.disableEdit}
                    //label={getTranslatedString(this.props.language, 'edit')}
                />
                <Button
                    className="edit-button delete"
                    icon="pi-md-delete"
                    onClick={this.handleOnClick('delete')}
                    disabled={this.props.isLoading || this.props.disableDelete}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        language: state.i18n.language,
        id: ownProps.id,
        name: ownProps.name,
        isLoading: ownProps.isLoading,
        disableEdit: ownProps.disableEdit,
        disableDelete: ownProps.disableDelete,
        handleEditClick: ownProps.onEditClick,
        handleDeleteClick: ownProps.onDeleteClick
    };
};

export default connect(
    mapStateToProps,
    undefined
)(EditButtons);
