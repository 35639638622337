/*
import Config_CONFIGNAME.js
if no CONFIGNAME is defined, use Config_local.js
  */
let config = null;

if (process.env.REACT_APP_CONFIG === 'al5-app04') {
    config = require('./Config_al5-app04').Config;
} else if (process.env.REACT_APP_CONFIG === 'al5-app07') {
    config = require('./Config_al5-app07').Config;
} else if (process.env.REACT_APP_CONFIG === 'al5-app08') {
    config = require('./Config_al5-app08').Config;
} else if (process.env.REACT_APP_CONFIG === 'al5-app10') {
    config = require('./Config_al5-app10').Config;
} else if (process.env.REACT_APP_CONFIG === 'al5-app11') {
    config = require('./Config_al5-app11').Config;
} else if (process.env.REACT_APP_CONFIG === 'al5-app14') {
    config = require('./Config_al5-app14').Config;
} else if (process.env.REACT_APP_CONFIG === 'VZ') {
    config = require('./Config_VZ').Config;
} else if (process.env.REACT_APP_CONFIG === 'CIC-PROD') {
    config = require('./Config_CIC-PROD').Config;
} else if (process.env.REACT_APP_CONFIG === 'CIC-DEV') {
    config = require('./Config_CIC-DEV').Config;
} else if (process.env.REACT_APP_CONFIG === 'al5-dev01') {
    config = require('./Config_al5-dev01').Config;
} else if (process.env.REACT_APP_CONFIG === 'al5-dev02') {
    config = require('./Config_al5-dev02').Config;
} else if (process.env.NODE_ENV === 'development') {
    try {
        config = require('./Config_local').Config;
    } catch (e) {
        throw new Error("Can't find config file Config_local.js!");
    }
}

if (!config) {
    throw new Error('No Config loaded!');
}

export const Config = config;