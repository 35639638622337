import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    addTopic,
    addTopicValidation,
    deleteTopic,
    editTopic,
    requestTopics,
    resetTopicsPage,
    startEditingTopic,
    stopEditingTopic,
    updateTopic
} from './actions';

import classNames from 'classnames';
import '../../css/features/Topics.scss';

import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import EditButtons from '../base/EditButtons';
import DeleteDialog from '../base/DeleteDialog';
import FormButtons from '../base/FormButtons';
import TranslatedString from '../base/i18n/TranslatedString';
import { getTranslatedString } from '../base/i18n/translations';

export class Topics extends Component {
    constructor(props) {
        super(props);

        this.rows = 25;
        this.defaultTopicFields = {
            _id: '',
            name: '',
            statsGroup: ''
        };

        this.state = {
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
        this.handleEditForm = this.handleEditForm.bind(this);
        this.handleAddValidation = this.handleAddValidation.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    componentDidMount() {
        this.props.requestTopics({
            range: { startIndex: 0, endIndex: this.rows - 1 }
        });
    }

    componentDidUpdate(prevProps) {
        // reset topics page if the service was changed
        if (prevProps.service !== this.props.service && this.props.service) {
            this.props.resetTopicsPage(true, {
                range: {
                    startIndex: 0,
                    endIndex: this.rows - 1
                }
            });

            this.setState({
                showDeleteDialog: false,
                itemToDeleteId: undefined,
                itemToDeleteName: undefined
            });
        }
    }

    componentWillUnmount() {
        this.props.resetTopicsPage();
    }

    handlePageClick(event) {
        this.props.requestTopics({
            range: {
                startIndex: event.first,
                endIndex: event.first + this.rows - 1
            }
        });
    }

    handleEditClick(topicId) {
        if (this.props.isEditing && topicId === this.props.topicToEdit._id) {
            this.props.stopEditingTopic();
        } else {
            //find topic object with matching topicId to edit
            const topic = this.props.topics.find(item => {
                return item._id === topicId;
            });

            //build topic object with using given property values or default values if necessary
            let topicData = { _id: topic._id };
            Object.keys(this.defaultTopicFields).forEach(prop => {
                if (topic.hasOwnProperty(prop)) {
                    topicData[prop] = topic[prop];
                } else {
                    topicData[prop] = this.defaultTopicFields[prop];
                }
            });

            this.props.startEditingTopic(topicData);
        }
    }

    handleDeleteClick(topicId, topicName) {
        this.setState({
            showDeleteDialog: true,
            itemToDeleteId: topicId,
            itemToDeleteName: topicName
        });
    }

    handleDeleteConfirm() {
        const topics = this.props.topics;
        let currentItems = { ...this.props.contentRange.currentItems };

        // if the item to be deleted is the last one on the currently selected table page, request the items for the previous table page
        if (
            currentItems.startIndex === currentItems.endIndex &&
            topics[topics.length - 1]._id === this.state.itemToDeleteId
        ) {
            currentItems = {
                startIndex: currentItems.startIndex - this.rows,
                endIndex: currentItems.startIndex - 1
            };
            if (currentItems.startIndex < 0) {
                currentItems = undefined;
            }
        }

        this.props.deleteTopic(this.state.itemToDeleteId, {
            range: currentItems
        });
        this.setState({
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        });
    }

    handleDeleteCancel() {
        this.setState({
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        });
    }

    handleEditForm(currentFields, update) {
        this.props.editTopic(currentFields, update);
    }

    handleAddValidation(validationType) {
        const validationSet = this.props.isEditing
            ? this.props.editValidationSet
            : this.props.addValidationSet;
        if (!validationSet.hasOwnProperty(validationType)) {
            this.props.addTopicValidation(validationType);
        }
    }

    handleSaveClick() {
        let currentItems;
        if (this.props.contentRange) {
            currentItems = { ...this.props.contentRange.currentItems };
        }

        if (this.props.isEditing) {
            this.props.updateTopic(this.props.topicToEdit._id, {
                range: currentItems
            });
        } else {
            if (currentItems) {
                currentItems.endIndex = currentItems.startIndex + this.rows - 1;
            }
            this.props.addTopic({ range: currentItems });
        }
    }

    handleCancelClick() {
        this.props.stopEditingTopic();
    }

    render() {
        return (
            <React.Fragment>
                <div className="p-grid">
                    <div className="p-col-12 p-xl-8">
                        <TopicsTable
                            isLoading={this.props.isLoading}
                            editedRowId={
                                this.props.isEditing
                                    ? this.props.topicToEdit._id
                                    : undefined
                            }
                            topics={this.props.topics}
                            language={this.props.language}
                            rows={this.rows}
                            contentRange={this.props.contentRange}
                            onPageClick={this.handlePageClick}
                            onEditClick={this.handleEditClick}
                            onDeleteClick={this.handleDeleteClick}
                        />
                    </div>

                    <div className="p-col-12 p-xl-4">
                        <TopicForm
                            isLoading={this.props.isLoading}
                            isEditing={this.props.isEditing}
                            topicFormFields={
                                (this.props.isEditing
                                    ? this.props.topicToEdit
                                    : this.props.topicToAdd) ||
                                this.defaultTopicFields
                            }
                            validationSet={
                                this.props.isEditing
                                    ? this.props.editValidationSet
                                    : this.props.addValidationSet
                            }
                            language={this.props.language}
                            onEditForm={this.handleEditForm}
                            onAddValidation={this.handleAddValidation}
                            onSaveClick={this.handleSaveClick}
                            onCancelClick={this.handleCancelClick}
                        />
                    </div>
                </div>

                <DeleteDialog
                    visible={this.state.showDeleteDialog}
                    header={'topicDeleteHeader'}
                    dialog={'topicDeleteDialog'}
                    itemToDelete={this.state.itemToDeleteName}
                    onDeleteConfirm={this.handleDeleteConfirm}
                    onDeleteCancel={this.handleDeleteCancel}
                />
            </React.Fragment>
        );
    }
}

class TopicsTable extends Component {
    render() {
        const topicTableEntries = this.props.topics.map((item, key) => {
            const topic = { ...item };

            topic.editTopicButtons = (
                <EditButtons
                    key={key}
                    id={topic._id}
                    name={topic.name}
                    isLoading={this.props.isLoading}
                    onEditClick={this.props.onEditClick}
                    onDeleteClick={this.props.onDeleteClick}
                />
            );

            return topic;
        });

        return (
            <Card title={getTranslatedString(this.props.language, 'topics')}>
                <DataTable
                    className="table"
                    autoLayout={true}
                    responsive={true}
                    value={topicTableEntries}
                    rows={this.props.rows}
                    rowClassName={rowData => {
                        return {
                            'row-bg':
                                rowData &&
                                rowData._id === this.props.editedRowId
                        };
                    }}
                    paginator={true}
                    paginatorPosition={'top'}
                    alwaysShowPaginator={true}
                    lazy={true}
                    totalRecords={
                        this.props.contentRange
                            ? this.props.contentRange.totalItems
                            : undefined
                    }
                    first={
                        this.props.contentRange
                            ? this.props.contentRange.currentItems.startIndex
                            : 0
                    }
                    onPage={this.props.onPageClick}
                >
                    <Column
                        className="column id-col"
                        field="_id"
                        header={<TranslatedString id={'topicTableId'} />}
                    />
                    <Column
                        className="column name-col"
                        field="name"
                        header={<TranslatedString id={'topicTableName'} />}
                    />
                    <Column
                        className="column stats-group-col"
                        field="statsGroup"
                        header={
                            <TranslatedString id={'topicTableStatsGroup'} />
                        }
                    />
                    <Column
                        className="column edit-buttons-col"
                        field="editTopicButtons"
                    />
                </DataTable>
            </Card>
        );
    }
}

class TopicForm extends Component {
    constructor(props) {
        super(props);

        this.validators = {
            nonEmpty: input => {
                // any non-empty input is valid
                return input ? input.trim() !== '' : false;
            }
        };
    }

    /**
     * onChange event handler input field
     * @param fieldId - property name of input field
     * @returns {Function}
     */
    handleOnChange = fieldId => e => {
        this.props.onEditForm(this.props.topicFormFields, {
            field: fieldId,
            value: e.target.value
        });
    };

    /**
     * onBlur event handler for input field
     * @param fieldId - property name of input field
     * @returns {Function}
     */
    handleOnBlur = fieldId => () => {
        this.props.onAddValidation(fieldId);
    };

    /**
     * validate topic form fields
     * @param topicFormFields
     * @returns {{invalidInputFields: {date: boolean, title: boolean}, invalidInputs: (boolean|*)}}
     */
    validateFormFields(topicFormFields) {
        const invalidInputFields = {
            id: !this.validators.nonEmpty(topicFormFields._id),
            name: !this.validators.nonEmpty(topicFormFields.name)
        };
        return {
            invalidInputFields: invalidInputFields,
            invalidInputs: Object.keys(invalidInputFields).reduce(
                (acc, curr) => acc || invalidInputFields[curr],
                false
            )
        };
    }

    renderTopicInputFields(invalidInputFields) {
        const topicFormFields = this.props.topicFormFields;
        const validationSet = this.props.validationSet;

        return (
            <React.Fragment>
                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className={classNames('form-input', {
                                'p-error':
                                    invalidInputFields.id &&
                                    validationSet.hasOwnProperty('id')
                            })}
                            readOnly={this.props.isEditing}
                            value={topicFormFields._id}
                            onChange={this.handleOnChange('_id')}
                            onBlur={this.handleOnBlur('id')}
                        />
                        <label>
                            <TranslatedString id={'topicId'} />
                        </label>
                    </span>
                </div>

                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className={classNames('form-input', {
                                'p-error':
                                    invalidInputFields.name &&
                                    validationSet.hasOwnProperty('name')
                            })}
                            value={topicFormFields.name}
                            onChange={this.handleOnChange('name')}
                            onBlur={this.handleOnBlur('name')}
                        />
                        <label>
                            <TranslatedString id={'topicName'} />
                        </label>
                    </span>
                </div>

                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className="form-input"
                            value={topicFormFields.statsGroup}
                            onChange={this.handleOnChange('statsGroup')}
                        />
                        <label>
                            <TranslatedString id={'topicStatsGroup'} />
                        </label>
                    </span>
                </div>
            </React.Fragment>
        );
    }

    render() {
        const { invalidInputFields, invalidInputs } = this.validateFormFields(
            this.props.topicFormFields
        );
        const topicInputFields = this.renderTopicInputFields(
            invalidInputFields
        );

        return (
            <Card
                title={getTranslatedString(
                    this.props.language,
                    this.props.isEditing ? 'topicEdit' : 'topicAdd'
                )}
            >
                <br />
                <div className="p-grid form-group">
                    {topicInputFields}
                    <div className="p-col-12">
                        <FormButtons
                            invalidInputs={invalidInputs}
                            isLoading={this.props.isLoading}
                            isEditing={this.props.isEditing}
                            onSaveClick={this.props.onSaveClick}
                            onCancelClick={this.props.onCancelClick}
                        />
                    </div>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.topics.isLoading,
        isEditing: state.topics.isEditing,
        topicToAdd: state.topics.topicToAdd,
        addValidationSet: state.topics.addValidationSet,
        topicToEdit: state.topics.topicToEdit,
        editValidationSet: state.topics.editValidationSet,
        topics: state.topics.topics,
        contentRange: state.topics.contentRange,

        service: state.auth.service,
        language: state.i18n.language
    };
};

const mapDispatchToProps = {
    requestTopics,
    addTopic,
    updateTopic,
    deleteTopic,
    startEditingTopic,
    stopEditingTopic,
    editTopic,
    addTopicValidation,
    resetTopicsPage
};

export default connect(mapStateToProps, mapDispatchToProps)(Topics);
