export const UPDATE_SERVICE_REQUEST_PARAMETERS =
    'UPDATE_SERVICE_REQUEST_PARAMETERS';

export const REQUEST_SERVICES = 'REQUEST_SERVICES';
export const REQUEST_SERVICES_SUCCESS = 'REQUEST_SERVICES_SUCCESS';
export const REQUEST_SERVICES_FAILURE = 'REQUEST_SERVICES_FAILURE';

export const ADD_SERVICE = 'ADD_SERVICE';
export const ADD_SERVICE_SUCCESS = 'ADD_SERVICE_SUCCESS';
export const ADD_SERVICE_FAILURE = 'ADD_SERVICE_FAILURE';

export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_FAILURE = 'UPDATE_SERVICE_FAILURE';

export const DELETE_SERVICE = 'DELETE_SERVICE';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAILURE = 'DELETE_SERVICE_FAILURE';

export const START_EDITING_SERVICE = 'START_EDITING_SERVICE';
export const STOP_EDITING_SERVICE = 'STOP_EDITING_SERVICE';
export const EDIT_SERVICE = 'EDIT_SERVICE';
export const ADD_SERVICE_VALIDATION = 'ADD_SERVICE_VALIDATION';

export const RESET_SERVICES_PAGE = 'RESET_SERVICES_PAGE';
