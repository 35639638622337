import React, { Component } from 'react';
import { connect } from 'react-redux';

import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import { getTranslatedString } from './i18n/translations';
import { Calendar } from 'primereact/calendar';

class UserCustomField extends Component {
    /**
     * onChange event handler for custom input field
     * @param fieldId - property name of custom field
     * @returns {Function}
     */
    handleOnChange = fieldId => e => {
        this.props.onEditForm(this.props.formFields, {
            field: fieldId,
            value: e.target.value
        });
    };

    /**
     * onBlur event handler for custom input field
     * @param fieldId - property name of custom field
     * @returns {Function}
     */
    handleOnBlur = fieldId => () => {
        this.props.onAddValidation(fieldId);
    };

    renderTextInput() {
        const fieldProps = this.props.fieldProperties;
        const value = this.props.formFields[fieldProps._id] || '';

        return (
            <React.Fragment>
                <span className="md-inputfield">
                    <InputText
                        className={
                            'form-input ' + (this.props.error ? 'p-error' : '')
                        }
                        value={value}
                        onChange={this.handleOnChange(fieldProps._id)}
                        onBlur={
                            fieldProps.required || fieldProps.pattern
                                ? this.handleOnBlur(fieldProps._id)
                                : undefined
                        }
                    />
                    <label>
                        {fieldProps.label + (fieldProps.required ? ' *' : '')}
                    </label>
                </span>
                {fieldProps.description && (
                    <span className="form-tooltip">
                        {fieldProps.description}
                    </span>
                )}
            </React.Fragment>
        );
    }

    renderEmailOrNumberInput() {
        const fieldProps = this.props.fieldProperties;
        const value = this.props.formFields[fieldProps._id] || '';

        return (
            <React.Fragment>
                <span className="md-inputfield">
                    <InputText
                        className={
                            'form-input ' + (this.props.error ? 'p-error' : '')
                        }
                        value={value}
                        onChange={this.handleOnChange(fieldProps._id)}
                        onBlur={this.handleOnBlur(fieldProps._id)}
                    />
                    <label>
                        {fieldProps.label + (fieldProps.required ? ' *' : '')}
                    </label>
                </span>
                {fieldProps.description && (
                    <span className="form-tooltip">
                        {fieldProps.description}
                    </span>
                )}
            </React.Fragment>
        );
    }

    renderDateInput() {
        const fieldProps = this.props.fieldProperties;
        const value = this.props.formFields[fieldProps._id];

        return (
            <React.Fragment>
                <span className="md-inputfield">
                    <Calendar
                        className={
                            'form-input ' + (this.props.error ? 'p-error' : '')
                        }
                        value={value}
                        dateFormat="dd.mm.yy, "
                        showTime={true}
                        readOnlyInput
                        locale={getTranslatedString(
                            this.props.language,
                            'calendar'
                        )}
                        onChange={this.handleOnChange(fieldProps._id)}
                        onBlur={
                            fieldProps.required || fieldProps.pattern
                                ? this.handleOnBlur(fieldProps._id)
                                : undefined
                        }
                    />
                    <label>
                        {fieldProps.label + (fieldProps.required ? ' *' : '')}
                    </label>
                </span>

                {fieldProps.description && (
                    <span className="form-tooltip">
                        {fieldProps.description}
                    </span>
                )}
            </React.Fragment>
        );
    }

    renderDropdownInput() {
        const fieldProps = this.props.fieldProperties;
        const value = this.props.formFields[fieldProps._id];

        return (
            <React.Fragment>
                <React.Fragment>
                    <Dropdown
                        className={
                            'form-input ' + (this.props.error ? 'p-error' : '')
                        }
                        value={value}
                        placeholder={fieldProps.defaultValue || ''}
                        options={fieldProps.options || []}
                        onChange={this.handleOnChange(fieldProps._id)}
                        onMouseDown={
                            fieldProps.required || fieldProps.pattern
                                ? this.handleOnBlur(fieldProps._id)
                                : undefined
                        }
                    />
                    <label className="dropdown-label-float">
                        {fieldProps.label + (fieldProps.required ? ' *' : '')}
                    </label>
                </React.Fragment>

                {fieldProps.description && (
                    <span className="form-tooltip">
                        {fieldProps.description}
                    </span>
                )}
            </React.Fragment>
        );
    }

    render() {
        return (
            <div className="p-col-12">
                {(() => {
                    switch (this.props.fieldProperties.type) {
                        case 'text':
                            return this.renderTextInput();
                        case 'email':
                        case 'number':
                            return this.renderEmailOrNumberInput();
                        case 'date':
                            return this.renderDateInput();
                        case 'dropdown':
                            return this.renderDropdownInput();
                        default:
                            return null;
                    }
                })()}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        language: state.i18n.language,

        formFields: ownProps.formFields,
        fieldProperties: ownProps.fieldProperties,
        error: ownProps.error,
        onEditForm: ownProps.onEditForm,
        onAddValidation: ownProps.onAddValidation
    };
};

export default connect(
    mapStateToProps,
    undefined
)(UserCustomField);
