import * as types from './actionTypes';
import { handleError, log } from '../base/util/helpers';
import { forceLogout, noServiceFound } from '../auth/actions';
import authHelper from '../base/util/authHelper';

/*
 * action creators
 */
function requestTopicsRequest() {
    return { type: types.REQUEST_TOPICS };
}

function requestTopicsSuccess(topics, contentRange) {
    return { type: types.REQUEST_TOPICS_SUCCESS, topics, contentRange };
}

function requestTopicsFailure(error) {
    return { type: types.REQUEST_TOPICS_FAILURE, error };
}

/*
 * thunk
 */
export function requestTopics({ fields, sort, range } = {}) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(requestTopicsRequest());

            const response = await api.getTopics(
                service._id,
                fields,
                sort,
                range,
                authHelper.getToken()
            );
            if (!response.error) {
                log.debug('[requestTopics]', response.topics);

                dispatch(
                    requestTopicsSuccess(response.topics, response.contentRange)
                );
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(requestTopicsFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

/*
 * action creators
 */
function addTopicRequest() {
    return { type: types.ADD_TOPIC };
}

function addTopicSuccess(topic) {
    return { type: types.ADD_TOPIC_SUCCESS, topic };
}

function addTopicFailure(error) {
    return { type: types.ADD_TOPIC_FAILURE, error };
}

/*
 * thunk
 */
export function addTopic({ fields, sort, range }) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(addTopicRequest());

            const topic = getState().topics.topicToAdd;
            log.debug('[addTopic]', topic);

            const response = await api.addTopic(
                service._id,
                topic,
                authHelper.getToken()
            );
            if (!response.error) {
                dispatch(addTopicSuccess(response.topic));
                dispatch(requestTopics({ fields, sort, range }));
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(addTopicFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

/*
 * action creators
 */
function updateTopicRequest() {
    return { type: types.UPDATE_TOPIC };
}

function updateTopicSuccess(topic) {
    return { type: types.UPDATE_TOPIC_SUCCESS, topic };
}

function updateTopicFailure(error) {
    return { type: types.UPDATE_TOPIC_FAILURE, error };
}

/*
 * thunk
 */
export function updateTopic(topicId, { fields, sort, range }) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(updateTopicRequest());

            const topic = { ...getState().topics.topicToEdit };
            const updatedFields = getState().topics.updatedFields;

            // remove fields that were not updated
            Object.keys(topic).forEach(field => {
                if (!updatedFields[field]) {
                    delete topic[field];
                }
            });
            log.debug('[updateTopic]', topic);

            const response = await api.updateTopic(
                service._id,
                topicId,
                topic,
                authHelper.getToken()
            );
            if (!response.error) {
                dispatch(updateTopicSuccess(response.topic));
                dispatch(requestTopics({ fields, sort, range }));
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(updateTopicFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

/*
 * action creators
 */
function deleteTopicRequest() {
    return { type: types.DELETE_TOPIC };
}

function deleteTopicSuccess(topicId) {
    return { type: types.DELETE_TOPIC_SUCCESS, topicId };
}

function deleteTopicFailure(error) {
    return { type: types.DELETE_TOPIC_FAILURE, error };
}

/*
 * thunk
 */
export function deleteTopic(topicId, { fields, sort, range }) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(deleteTopicRequest());

            const response = await api.deleteTopic(
                service._id,
                topicId,
                authHelper.getToken()
            );
            if (!response.error) {
                dispatch(deleteTopicSuccess(topicId));
                dispatch(requestTopics({ fields, sort, range }));
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(deleteTopicFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

/*
 * action creators
 */
export function startEditingTopic(topicData) {
    return { type: types.START_EDITING_TOPIC, topicData };
}

export function stopEditingTopic() {
    return { type: types.STOP_EDITING_TOPIC };
}

export function editTopic(currentFields, update) {
    return { type: types.EDIT_TOPIC, currentFields, update };
}

export function addTopicValidation(validationType) {
    return { type: types.ADD_TOPIC_VALIDATION, validationType };
}

function resetPage() {
    return { type: types.RESET_TOPICS_PAGE };
}

/*
 * thunk
 */
export function resetTopicsPage(
    loadTopics = false,
    { fields, sort, range } = {}
) {
    return async dispatch => {
        dispatch(resetPage());
        if (loadTopics) {
            dispatch(requestTopics({ fields, sort, range }));
        }
    };
}
