import React, { Component } from 'react';
import classNames from 'classnames';
import AppTopbar from './AppTopbar';
import { AppMenu } from './AppMenu';
import DomHandler from 'primereact/components/utils/DomHandler';
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';
import { withRouter } from 'react-router';
import logo from '../../assets/images/unblu_meet.svg';
import { AppBreadcrumb } from './AppBreadcrumb';
import { AppFooter } from './AppFooter';

class SerenityLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTopbarItem: null,
            layoutMode: 'static',
            mobileMenuActive: null,
            topbarMenuActive: null,
            menuActive: false
        };

        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMobileMenuButtonClick = this.onTopbarMobileMenuButtonClick.bind(
            this
        );
        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onSidebarMouseEnter = this.onSidebarMouseEnter.bind(this);
        this.onSidebarMouseLeave = this.onSidebarMouseLeave.bind(this);
        this.onToggleMenuClick = this.onToggleMenuClick.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
    }

    onWrapperClick(event) {
        if (
            !this.menuClick &&
            !this.menuButtonClick &&
            this.state.mobileMenuActive
        ) {
            this.setState({ mobileMenuActive: false });
        }

        if (!this.topbarMenuClick && !this.topbarMenuButtonClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isOverlay()) {
                this.setState({
                    menuActive: false
                });
            }
        }

        this.menuClick = false;
        this.menuButtonClick = false;
        this.topbarMenuClick = false;
        this.topbarMenuButtonClick = false;
    }

    onTopbarItemClick(event) {
        this.topbarMenuClick = true;
        if (this.state.activeTopbarItem === event.item)
            this.setState({ activeTopbarItem: null });
        else this.setState({ activeTopbarItem: event.item });

        event.originalEvent.preventDefault();
    }

    onMenuButtonClick(event) {
        this.menuButtonClick = true;

        if (this.isMobile()) {
            this.setState({ mobileMenuActive: !this.state.mobileMenuActive });
        }

        event.preventDefault();
    }

    onTopbarMobileMenuButtonClick(event) {
        this.topbarMenuButtonClick = true;
        this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
        event.preventDefault();
    }

    onToggleMenuClick(event) {
        this.setState({
            layoutMode:
                this.state.layoutMode !== 'static' ? 'static' : 'overlay'
        });
    }

    onSidebarClick(event) {
        this.menuClick = true;
    }

    onSidebarMouseEnter(event) {
        if (this.sidebarTimeout) {
            clearTimeout(this.sidebarTimeout);
        }
        DomHandler.addClass(this.sidebar, 'layout-sidebar-active');
    }

    onSidebarMouseLeave(event) {
        this.sidebarTimeout = setTimeout(() => {
            DomHandler.removeClass(this.sidebar, 'layout-sidebar-active');
        }, 250);
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });
    }

    onMenuItemClick(event) {
        if (!event.item.items && this.isHorizontal()) {
            this.setState({
                menuActive: false
            });
        }
    }

    isMobile() {
        return window.innerWidth <= 1024;
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    render() {
        let wrapperClass = classNames('layout-wrapper', {
            'layout-wrapper-static': this.state.layoutMode === 'static',
            'layout-wrapper-active': this.state.mobileMenuActive,
            'layout-menu-horizontal': this.state.layoutMode === 'horizontal'
        });
        let sidebarClassName = classNames('layout-sidebar', {
            'layout-sidebar-dark': this.state.darkMenu
        });

        return (
            <div className={wrapperClass} onClick={this.onWrapperClick}>
                <div
                    ref={el => (this.sidebar = el)}
                    className={sidebarClassName}
                    onClick={this.onSidebarClick}
                    onMouseEnter={this.onSidebarMouseEnter}
                    onMouseLeave={this.onSidebarMouseLeave}
                >
                    <div className="sidebar-logo">
                        <img
                            alt="logo"
                            src={logo}
                            className="sidebar-logo-img"
                        />
                        <button
                            className="p-link sidebar-anchor"
                            title="Toggle Menu"
                            onClick={this.onToggleMenuClick}
                        />
                    </div>

                    <ScrollPanel
                        ref={el => (this.layoutMenuScroller = el)}
                        style={{ height: '100%' }}
                    >
                        <div className="layout-menu-container">
                            <AppMenu
                                model={this.props.menuModel}
                                onRootMenuItemClick={this.onRootMenuItemClick}
                                layoutMode={this.state.layoutMode}
                                active={this.state.menuActive}
                                onMenuItemClick={this.onMenuItemClick}
                                pathname={this.props.location.pathname}
                            />
                        </div>
                    </ScrollPanel>
                </div>
                <div className="layout-main">
                    {this.props.showTopbar && (
                        <React.Fragment>
                            <AppTopbar
                                layoutMode={this.state.layoutMode}
                                activeTopbarItem={this.state.activeTopbarItem}
                                onTopbarItemClick={this.onTopbarItemClick}
                                onMenuButtonClick={this.onMenuButtonClick}
                                onTopbarMobileMenuButtonClick={
                                    this.onTopbarMobileMenuButtonClick
                                }
                                topbarMenuActive={this.state.topbarMenuActive}
                            />
                            <div className="topbar-padding" />
                        </React.Fragment>
                    )}
                    {this.props.showBreadcrumb && (
                        <AppBreadcrumb
                            pathname={this.props.location.pathname}
                        />
                    )}
                    <div className="layout-content">
                        {this.props.contentPages}
                    </div>
                    {this.props.showFooter && <AppFooter />}

                    {this.state.mobileMenuActive && (
                        <div className="layout-main-mask" />
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(SerenityLayout);
