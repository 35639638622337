import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactExport from 'react-export-excel';

import {
    addUser,
    addUserValidation,
    deleteUser,
    editUser,
    requestExportData,
    requestUsers,
    resetExportData,
    resetUsersPage,
    startEditingUser,
    stopEditingUser,
    syncUsers,
    updateRequestParameters,
    updateUser
} from './actions';
import { requestTopics } from '../topics/actions';

import '../../css/features/Users.scss';
import classNames from 'classnames';

import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

import TranslatedString from '../base/i18n/TranslatedString';
import { getTranslatedString } from '../base/i18n/translations';
import EditButtons from '../base/EditButtons';
import DeleteDialog from '../base/DeleteDialog';
import UserCustomField from '../base/UserCustomField';
import FormButtons from '../base/FormButtons';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';

export class Users extends Component {
    constructor(props) {
        super(props);

        this.rows = 25;
        this.defaultUserFields = {
            name: '',
            id: '',
            username: '',
            roles: ['adviser'],
            password: '',
            passwordConfirmation: '',
            topics1: [],
            topics2: [],
            topics3: [],
            locale: 'en'
        };
        this.userFields = {};

        this.updateUserFields();

        this.state = {
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined,
            showExportDialog: false,
            exportError: false,
            exportUsers: false
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
        this.handleEditForm = this.handleEditForm.bind(this);
        this.handleAddValidation = this.handleAddValidation.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleSyncClick = this.handleSyncClick.bind(this);
        this.handleExportClick = this.handleExportClick.bind(this);
        this.handleExportConfirm = this.handleExportConfirm.bind(this);
        this.handleExportCancel = this.handleExportCancel.bind(this);
    }

    componentDidMount() {
        this.props.updateRequestParameters({
            range: { startIndex: 0, endIndex: this.rows - 1 }
        });
        this.props.requestUsers();

        // get topics if callManager is enabled
        if (this.props.service.callManager) {
            this.props.requestTopics();
        }
    }

    componentDidUpdate(prevProps) {
        // reset users page if the service was changed
        if (prevProps.service !== this.props.service && this.props.service) {
            this.props.resetUsersPage(true);
            this.updateUserFields();

            // get topics if callManager is enabled
            if (this.props.service.callManager) {
                this.props.requestTopics();
            }

            this.setState({
                showDeleteDialog: false,
                itemToDeleteId: undefined,
                itemToDeleteName: undefined,
                showExportDialog: false,
                exportError: false,
                exportUsers: false
            });
        }
    }

    componentWillUnmount() {
        this.props.resetUsersPage();
    }

    updateUserFields() {
        // set user fields with respect to the current service object
        this.userFields = { ...this.defaultUserFields };
        if (this.props.service && this.props.service.adviserUserCustomFields) {
            this.props.service.adviserUserCustomFields.forEach(
                customField =>
                    (this.userFields[customField._id] =
                        customField.defaultValue)
            );
        }
    }

    handleSearchChange(searchKey) {
        this.props.updateRequestParameters({
            search: searchKey
                ? [
                      `_id=${searchKey}`,
                      `username=${searchKey}`,
                      `name=${searchKey}`,
                      ...(this.props.service &&
                      Array.isArray(
                          this.props.service.adviserUserCustomFields
                      ) &&
                      this.props.service.adviserUserCustomFields.some(
                          customField => customField._id === 'firstName'
                      )
                          ? [`firstName=${searchKey}`]
                          : []),
                      `id=${searchKey}`
                  ]
                : [],
            range: { startIndex: 0, endIndex: this.rows - 1 }
        });
        this.props.requestUsers();
    }

    handlePageClick(event) {
        this.props.updateRequestParameters({
            range: {
                startIndex: event.first,
                endIndex: event.first + this.rows - 1
            }
        });
        this.props.requestUsers();
    }

    handleEditClick(userId) {
        if (this.props.isEditing && userId === this.props.userToEdit._id) {
            this.props.stopEditingUser();
        } else {
            //find user object with matching userId to edit
            const user = this.props.users.find(item => {
                return item._id === userId;
            });

            //build user object using given property values or default values if necessary
            let userData = { _id: user._id };

            Object.keys(this.userFields).forEach(prop => {
                if (user.hasOwnProperty(prop)) {
                    userData[prop] = user[prop];
                } else {
                    userData[prop] = this.userFields[prop];
                }
            });

            this.props.startEditingUser(userData);
        }
    }

    handleDeleteClick(userId, username) {
        this.setState({
            showDeleteDialog: true,
            itemToDeleteId: userId,
            itemToDeleteName: username
        });
    }

    handleDeleteConfirm() {
        const { users, contentRange } = this.props;
        let currentItems = { ...contentRange.currentItems };

        // if the item to be deleted is the last one on the currently selected table page, request the items for the previous table page
        if (
            currentItems.startIndex === currentItems.endIndex &&
            users[users.length - 1]._id === this.state.itemToDeleteId
        ) {
            currentItems = {
                startIndex: currentItems.startIndex - this.rows,
                endIndex: currentItems.startIndex - 1
            };
            if (currentItems.startIndex < 0) {
                currentItems = undefined;
            }
        }

        this.props.updateRequestParameters({
            range: currentItems
        });
        this.props.deleteUser(this.state.itemToDeleteId);

        this.setState({
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        });
    }

    handleDeleteCancel() {
        this.setState({
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        });
    }

    handleEditForm(currentFields, update) {
        this.props.editUser(currentFields, update);
    }

    handleAddValidation(validationType) {
        const validationSet = this.props.isEditing
            ? this.props.editValidationSet
            : this.props.addValidationSet;
        if (!validationSet.hasOwnProperty(validationType)) {
            this.props.addUserValidation(validationType);
        }
    }

    handleSaveClick() {
        if (this.props.isEditing) {
            this.props.updateUser(this.props.userToEdit._id);
        } else {
            this.props.addUser();
        }
    }

    handleCancelClick() {
        this.props.stopEditingUser();
    }

    handleSyncClick() {
        let currentItems;
        if (this.props.contentRange) {
            currentItems = { ...this.props.contentRange.currentItems };
        }
        if (currentItems) {
            currentItems.endIndex = currentItems.startIndex + this.rows - 1;
        }

        this.props.syncUsers({
            range: currentItems,
            sort: ['username']
        });
    }

    handleExportClick() {
        // make sure to clear previously requested export data (if there is any)
        if (this.props.exportData) {
            this.props.resetExportData();
        }

        // setup fields and filter parameters for the user request call to get the export data
        const fields = ['-keycloakId', '-deleted', '-crdate', '-modified'];
        const sort = ['username'];
        const range = {
            startIndex: 0,
            endIndex: this.props.contentRange
                ? this.props.contentRange.totalItems
                : undefined
        };

        this.props.requestExportData({
            fields: fields,
            sort: sort,
            range: range
        });

        this.setState({
            showExportDialog: true,
            exportError: false,
            exportUsers: false
        });
    }

    handleExportConfirm() {
        // check that the export data received from the server is defined and has the correct format; otherwise, show an error message in the dialog
        if (this.props.exportData && Array.isArray(this.props.exportData)) {
            this.setState({ showExportDialog: false, exportUsers: true });
        } else {
            this.setState({ exportError: true });
        }
    }

    handleExportCancel() {
        // cleanup: clear the requested export data when cancelling/finishing the excel export
        if (this.props.exportData) {
            this.props.resetExportData();
        }
        this.setState({
            showExportDialog: false,
            exportError: false,
            exportUsers: false
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="p-grid">
                    <div className="p-col-12 p-xl-8">
                        <UsersTable
                            user={this.props.user}
                            service={this.props.service}
                            isLoading={this.props.isLoading}
                            editedRowId={
                                this.props.isEditing
                                    ? this.props.userToEdit._id
                                    : undefined
                            }
                            users={this.props.users}
                            language={this.props.language}
                            rows={this.rows}
                            contentRange={this.props.contentRange}
                            syncProgress={this.props.syncProgress}
                            onSearchChange={this.handleSearchChange}
                            onSyncClick={this.handleSyncClick}
                            onExportClick={this.handleExportClick}
                            onPageClick={this.handlePageClick}
                            onEditClick={this.handleEditClick}
                            onDeleteClick={this.handleDeleteClick}
                        />
                    </div>

                    <div className="p-col-12 p-xl-4">
                        <UserForm
                            user={this.props.user}
                            service={this.props.service}
                            topics={this.props.topics}
                            isLoading={this.props.isLoading}
                            isEditing={this.props.isEditing}
                            userFormFields={
                                (this.props.isEditing
                                    ? this.props.userToEdit
                                    : this.props.userToAdd) || this.userFields
                            }
                            customFields={
                                (this.props.service &&
                                    this.props.service
                                        .adviserUserCustomFields) ||
                                []
                            }
                            validationSet={
                                this.props.isEditing
                                    ? this.props.editValidationSet
                                    : this.props.addValidationSet
                            }
                            language={this.props.language}
                            onEditForm={this.handleEditForm}
                            onAddValidation={this.handleAddValidation}
                            onSaveClick={this.handleSaveClick}
                            onCancelClick={this.handleCancelClick}
                        />
                    </div>
                </div>

                {this.state.showExportDialog && (
                    <ExcelExportDialog
                        visible={this.state.showExportDialog}
                        error={this.state.exportError}
                        isLoading={this.props.isLoading}
                        exportData={this.props.exportData}
                        language={this.props.language}
                        header={'excelExportHeader'}
                        onExportConfirm={this.handleExportConfirm}
                        onExportCancel={this.handleExportCancel}
                    />
                )}

                {this.state.exportUsers && (
                    <ExcelExport
                        exportData={this.props.exportData}
                        service={this.props.service}
                        onExportStarted={this.handleExportCancel}
                    />
                )}

                <DeleteDialog
                    visible={this.state.showDeleteDialog}
                    header={'userDeleteHeader'}
                    dialog={'userDeleteDialog'}
                    itemToDelete={this.state.itemToDeleteName}
                    onDeleteConfirm={this.handleDeleteConfirm}
                    onDeleteCancel={this.handleDeleteCancel}
                />
            </React.Fragment>
        );
    }
}

class UsersTable extends Component {
    constructor(props) {
        super(props);

        this.fetchSearchResultsTimer = null;

        this.state = {
            searchKey: ''
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleOnSyncButtonClick = this.handleOnSyncButtonClick.bind(this);
        this.handleOnExportButtonClick = this.handleOnExportButtonClick.bind(
            this
        );
    }

    componentDidUpdate(prevProps) {
        // reset search field if the service was changed
        if (prevProps.service !== this.props.service && this.props.service) {
            this.setState({
                searchKey: ''
            });
        }
    }

    handleSearchChange(e) {
        const searchKey = e.target.value;
        this.setState({ searchKey: searchKey });

        if (this.fetchSearchResultsTimer !== null) {
            clearTimeout(this.fetchSearchResultsTimer);
            this.fetchSearchResultsTimer = null;
        }

        this.fetchSearchResultsTimer = setTimeout(() => {
            this.props.onSearchChange(searchKey);

            clearTimeout(this.fetchSearchResultsTimer);
            this.fetchSearchResultsTimer = null;
        }, 1000);
    }

    handleOnSyncButtonClick(e) {
        e.preventDefault();
        this.props.onSyncClick();
    }

    handleOnExportButtonClick(e) {
        e.preventDefault();
        this.props.onExportClick();
    }

    renderSearchField() {
        const { language } = this.props;
        return (
            <div className="search-field">
                <InputText
                    value={this.state.searchKey}
                    placeholder={getTranslatedString(language, 'usersSearch')}
                    onChange={this.handleSearchChange}
                />
            </div>
        );
    }

    renderTableHeader() {
        const { service, users, syncProgress, isLoading } = this.props;
        return (
            <div>
                {service && service.authMethod === 'keycloak' && (
                    <React.Fragment>
                        {syncProgress && (
                            <span className="sync-progress">{`${syncProgress.progress}%`}</span>
                        )}
                        <Button
                            icon="pi pi-md-sync"
                            className="sync-button"
                            disabled={isLoading}
                            onClick={this.handleOnSyncButtonClick}
                        />
                    </React.Fragment>
                )}

                <Button
                    disabled={users.length < 1}
                    icon="pi pi-md-file-download"
                    className="export-data-button"
                    onClick={this.handleOnExportButtonClick}
                />
            </div>
        );
    }

    renderDataTable() {
        const {
            service,
            users,
            rows,
            editedRowId,
            contentRange,
            isLoading
        } = this.props;

        const userTableEntries = users.map((item, key) => {
            let user = { ...item };

            if (user.roles) {
                user.roles = user.roles.join(', ');
            }

            user.editUserButtons = (
                <EditButtons
                    key={key}
                    id={user._id}
                    name={user.username}
                    isLoading={isLoading}
                    disableDelete={this.props.user._id === user._id}
                    onEditClick={this.props.onEditClick}
                    onDeleteClick={this.props.onDeleteClick}
                />
            );
            return user;
        });

        const customFieldsColumns =
            service && Array.isArray(service.adviserUserCustomFields)
                ? service.adviserUserCustomFields.map((customField, index) => {
                      return (
                          <Column
                              className="column name-col"
                              field={customField._id}
                              key={index + 'cf'}
                              header={customField.label}
                          />
                      );
                  })[0] // todo: fix view for all custom fields not only first
                : null;

        const header = this.renderTableHeader();

        return (
            <DataTable
                className="table"
                header={header}
                autoLayout={true}
                responsive={true}
                value={userTableEntries}
                rows={rows}
                rowClassName={rowData => {
                    return {
                        'row-bg': rowData && rowData._id === editedRowId
                    };
                }}
                paginator={true}
                paginatorPosition={'top'}
                alwaysShowPaginator={true}
                lazy={true}
                totalRecords={
                    contentRange ? contentRange.totalItems : undefined
                }
                first={contentRange ? contentRange.currentItems.startIndex : 0}
                onPage={this.props.onPageClick}
            >
                <Column
                    className="column username-col"
                    field="username"
                    header={<TranslatedString id={'userTableUsername'} />}
                />
                <Column
                    className="column id-col"
                    field="_id"
                    header={<TranslatedString id={'userTableId'} />}
                />
                <Column
                    className="column name-col"
                    field="name"
                    header={<TranslatedString id={'userTableName'} />}
                />
                {customFieldsColumns}
                <Column
                    className="column roles-col"
                    field="roles"
                    header={<TranslatedString id={'userTableRoles'} />}
                />
                <Column
                    className="column edit-buttons-col"
                    field="editUserButtons"
                />
            </DataTable>
        );
    }

    render() {
        return (
            <Card title={getTranslatedString(this.props.language, 'users')}>
                <React.Fragment>
                    {this.renderSearchField()}
                    {this.renderDataTable()}
                </React.Fragment>
            </Card>
        );
    }
}

class UserForm extends Component {
    constructor(props) {
        super(props);
        this.validators = {
            name: name => {
                //any non-empty name is valid
                return name.trim() !== '';
            },
            username: username => {
                //a valid username may only contain letters, numbers, '-', '_' and '.'
                return /^[a-zA-Z0-9_.-]+$/.test(username);
            },
            password: (password, isEditing) => {
                //a valid password is at least 8 characters long or empty (in the case of editing the user)
                return isEditing
                    ? password === '' || password.length >= 8
                    : password.length >= 8;
            },
            passwordConfirmation: (password, passwordConfirmation) => {
                return password === passwordConfirmation;
            },
            roles: roles => {
                //at least one role has to be specified
                return roles.length > 0;
            },
            customField: (input, type, required, pattern) => {
                let valid = true;
                if (required) {
                    if (type !== 'date') {
                        valid = input.trim() !== '';
                    } else {
                        valid = input !== '';
                    }
                }
                if (pattern) {
                    valid = pattern.test(input);
                }
                if (type === 'email') {
                    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (required) {
                        valid = emailRegex.test(input);
                    } else {
                        valid = input === '' || emailRegex.test(input);
                    }
                } else if (type === 'number') {
                    valid = /^[-]?\d+$/.test(input);
                }
                return valid;
            }
        };
    }

    /**
     * onChange event handler input field
     * @param fieldId - property name of input field
     * @returns {Function}
     */
    handleOnChange = fieldId => e => {
        this.props.onEditForm(this.props.userFormFields, {
            field: fieldId,
            value: e.target.value
        });
    };

    /**
     * onBlur event handler for input field
     * @param fieldId - property name of input field
     * @returns {Function}
     */
    handleOnBlur = fieldId => () => {
        this.props.onAddValidation(fieldId);
    };

    /**
     * validate user form fields
     * @param userFormFields
     * @param invalidCustomFields
     * @returns {{invalidInputFields: {password: boolean, customFields: *, name: boolean, username: boolean}, invalidInputs: (boolean|*)}}
     */
    validateFormFields(userFormFields, invalidCustomFields) {
        let invalidInputFields = {
            name: !this.validators.name(userFormFields.name),
            username: !this.validators.username(userFormFields.username),
            password: !this.validators.password(
                userFormFields.password,
                this.props.isEditing
            ),
            roles: !this.validators.roles(userFormFields.roles),
            customFields: invalidCustomFields
        };
        invalidInputFields.passwordConfirmation =
            invalidInputFields.password ||
            !this.validators.passwordConfirmation(
                userFormFields.password,
                userFormFields.passwordConfirmation
            );

        return {
            invalidInputFields: invalidInputFields,
            invalidInputs: Object.keys(invalidInputFields).reduce(
                (acc, curr) => acc || invalidInputFields[curr],
                false
            )
        };
    }

    renderUserInputFields(invalidInputFields, customFields) {
        const userFormFields = this.props.userFormFields;
        const validationSet = this.props.validationSet;
        let availableTopics;
        if (this.props.service.callManager) {
            const defaultTopics = [
                {
                    _id: 'all',
                    name: getTranslatedString(
                        this.props.language,
                        'userTopicsAll'
                    )
                }
            ];
            availableTopics = defaultTopics
                .concat(this.props.topics || [])
                .map(topic => ({ label: topic.name, value: topic._id }));
        }

        return (
            <React.Fragment>
                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className={classNames('form-input', {
                                'p-error':
                                    invalidInputFields.name &&
                                    validationSet.hasOwnProperty('name')
                            })}
                            value={userFormFields.name}
                            onChange={this.handleOnChange('name')}
                            onBlur={this.handleOnBlur('name')}
                        />
                        <label>
                            <TranslatedString id={'userName'} />
                        </label>
                    </span>
                </div>

                {customFields}

                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className="form-input"
                            value={userFormFields.id}
                            onChange={this.handleOnChange('id')}
                        />
                        <label>
                            <TranslatedString id={'userAdditionalId'} />
                        </label>
                    </span>
                    <span className="form-tooltip">
                        <TranslatedString id={'userIdTooltip'} />
                    </span>
                </div>

                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className={classNames('form-input', {
                                'p-error':
                                    invalidInputFields.username &&
                                    validationSet.hasOwnProperty('username')
                            })}
                            readOnly={
                                this.props.isEditing &&
                                (this.props.user._id === userFormFields._id ||
                                    this.props.service.authMethod ===
                                        'keycloak')
                            }
                            value={userFormFields.username}
                            onChange={this.handleOnChange('username')}
                            onBlur={this.handleOnBlur('username')}
                        />
                        <label>
                            <TranslatedString id={'userUsername'} />
                        </label>
                    </span>
                    {invalidInputFields.username &&
                        validationSet.hasOwnProperty('username') && (
                            <span className="error-message">
                                <TranslatedString id={'invalidUsername'} />
                            </span>
                        )}
                </div>

                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            autoComplete="new-password"
                            className={classNames('form-input', {
                                'p-error':
                                    invalidInputFields.password &&
                                    validationSet.hasOwnProperty('password')
                            })}
                            type="password"
                            value={userFormFields.password}
                            onChange={this.handleOnChange('password')}
                            onBlur={this.handleOnBlur('password')}
                        />
                        <label>
                            <TranslatedString
                                id={
                                    this.props.isEditing
                                        ? 'userNewPassword'
                                        : 'userPassword'
                                }
                            />
                        </label>
                    </span>
                    {invalidInputFields.password &&
                        validationSet.hasOwnProperty('password') && (
                            <span className="error-message">
                                <TranslatedString id={'invalidPassword'} />
                            </span>
                        )}
                </div>

                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className={classNames('form-input', {
                                'p-error':
                                    invalidInputFields.passwordConfirmation &&
                                    validationSet.hasOwnProperty(
                                        'passwordConfirmation'
                                    )
                            })}
                            type="password"
                            value={userFormFields.passwordConfirmation}
                            onChange={this.handleOnChange(
                                'passwordConfirmation'
                            )}
                            onBlur={this.handleOnBlur('passwordConfirmation')}
                        />
                        <label>
                            <TranslatedString
                                id={
                                    this.props.isEditing
                                        ? 'userConfirmNewPassword'
                                        : 'userConfirmPassword'
                                }
                            />
                        </label>
                    </span>
                    {invalidInputFields.passwordConfirmation &&
                        validationSet.hasOwnProperty(
                            'passwordConfirmation'
                        ) && (
                            <span className="error-message">
                                <TranslatedString
                                    id={'invalidPasswordConfirmation'}
                                />
                            </span>
                        )}
                </div>

                {userFormFields._id && (
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={userFormFields._id}
                                readOnly={true}
                            />
                            <label>
                                <TranslatedString id={'userId'} />
                            </label>
                        </span>
                    </div>
                )}

                <div className="p-col-12">
                    <MultiSelect
                        className={classNames('form-input', {
                            'multiselect-error':
                                invalidInputFields.roles &&
                                validationSet.hasOwnProperty('roles')
                        })}
                        value={userFormFields.roles}
                        options={[
                            { label: 'adviser', value: 'adviser' },
                            { label: 'service', value: 'service' },
                            { label: 'manager', value: 'manager' },
                            {
                                label: 'analytics',
                                value: 'analytics'
                            },
                            {
                                label: 'meeting',
                                value: 'meeting'
                            },
                            // TODO: only display if feature activated?
                            { label: 'autorecord', value: 'autorecord' }
                        ]}
                        onChange={this.handleOnChange('roles')}
                        onBlur={this.handleOnBlur('roles')}
                    />
                    <label className="dropdown-label-float">
                        <TranslatedString id={'userRoles'} />
                    </label>
                </div>

                {availableTopics && (
                    <React.Fragment>
                        <div className="p-col-12">
                            <MultiSelect
                                className="form-input"
                                value={userFormFields.topics1}
                                options={availableTopics}
                                onChange={this.handleOnChange('topics1')}
                            />
                            <label className="dropdown-label-float">
                                <TranslatedString id={'userTopics1'} />
                            </label>
                        </div>

                        <div className="p-col-12">
                            <MultiSelect
                                className="form-input"
                                value={userFormFields.topics2}
                                options={availableTopics}
                                onChange={this.handleOnChange('topics2')}
                            />
                            <label className="dropdown-label-float">
                                <TranslatedString id={'userTopics2'} />
                            </label>
                        </div>

                        <div className="p-col-12">
                            <MultiSelect
                                className="form-input"
                                value={userFormFields.topics3}
                                options={availableTopics}
                                onChange={this.handleOnChange('topics3')}
                            />
                            <label className="dropdown-label-float">
                                <TranslatedString id={'userTopics3'} />
                            </label>
                        </div>
                    </React.Fragment>
                )}

                <div className="p-col-12">
                    <Dropdown
                        className="form-input"
                        value={userFormFields.locale}
                        options={[
                            {
                                label: getTranslatedString(
                                    this.props.language,
                                    'en'
                                ),
                                value: 'en'
                            },
                            {
                                label: getTranslatedString(
                                    this.props.language,
                                    'de'
                                ),
                                value: 'de'
                            },
                            {
                                label: getTranslatedString(
                                    this.props.language,
                                    'fr'
                                ),
                                value: 'fr'
                            },
                            {
                                label: getTranslatedString(
                                    this.props.language,
                                    'it'
                                ),
                                value: 'it'
                            }
                        ]}
                        onChange={this.handleOnChange('locale')}
                        onBlur={this.handleOnBlur('locale')}
                    />
                    <label className="dropdown-label-float">
                        <TranslatedString id={'language'} />
                    </label>
                </div>
            </React.Fragment>
        );
    }

    renderCustomFields() {
        let invalidCustomFields = false;
        const customFields = this.props.customFields.map(
            (customField, index) => {
                const invalidCustomField = !this.validators.customField(
                    this.props.userFormFields[customField._id],
                    customField.type,
                    customField.required,
                    customField.pattern
                );
                invalidCustomFields = invalidCustomFields || invalidCustomField;

                return (
                    <UserCustomField
                        key={index}
                        fieldProperties={customField}
                        formFields={this.props.userFormFields}
                        error={
                            invalidCustomField &&
                            this.props.validationSet.hasOwnProperty(
                                customField._id
                            )
                        }
                        onEditForm={this.props.onEditForm}
                        onAddValidation={this.props.onAddValidation}
                    />
                );
            }
        );

        return {
            invalidCustomFields: invalidCustomFields,
            customFields: customFields
        };
    }

    render() {
        const { invalidCustomFields, customFields } = this.renderCustomFields();
        const { invalidInputFields, invalidInputs } = this.validateFormFields(
            this.props.userFormFields,
            invalidCustomFields
        );
        const userInputFields = this.renderUserInputFields(
            invalidInputFields,
            customFields
        );

        return (
            <Card
                title={getTranslatedString(
                    this.props.language,
                    this.props.isEditing ? 'userEdit' : 'userAdd'
                )}
            >
                <br />
                <div className="p-grid form-group">
                    {userInputFields}
                    <div className="p-col-12">
                        <FormButtons
                            invalidInputs={invalidInputs}
                            isLoading={this.props.isLoading}
                            isEditing={this.props.isEditing}
                            onSaveClick={this.props.onSaveClick}
                            onCancelClick={this.props.onCancelClick}
                        />
                    </div>
                </div>
            </Card>
        );
    }
}

class ExcelExportDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 'download'
        };
    }

    renderLoadingExportData() {
        const exportDialog = (
            <TranslatedString id={'excelExportLoadingMessage'} />
        );
        const exportDialogFooter = (
            <div>
                <Button
                    className="button cancel"
                    label={getTranslatedString(this.props.language, 'cancel')}
                    icon="pi pi-times"
                    onClick={this.props.onExportCancel}
                />
            </div>
        );
        return { exportDialog, exportDialogFooter };
    }

    renderExportDownload() {
        const exportDialog = (
            <TranslatedString id={'excelExportDownloadMessage'} />
        );
        const exportDialogFooter = (
            <div>
                <Button
                    className="button confirm"
                    label={getTranslatedString(this.props.language, 'download')}
                    icon="pi pi-md-file-download"
                    onClick={this.props.onExportConfirm}
                />
                <Button
                    className="button cancel"
                    label={getTranslatedString(this.props.language, 'cancel')}
                    icon="pi pi-times"
                    onClick={this.props.onExportCancel}
                />
            </div>
        );
        return { exportDialog, exportDialogFooter };
    }

    renderExportError() {
        const exportDialog = (
            <TranslatedString id={'excelExportErrorMessage'} />
        );
        const exportDialogFooter = (
            <div>
                <Button
                    className="button cancel"
                    label={getTranslatedString(this.props.language, 'cancel')}
                    icon="pi pi-times"
                    onClick={this.props.onExportCancel}
                />
            </div>
        );
        return { exportDialog, exportDialogFooter };
    }

    render() {
        const exportDialogHeader = <TranslatedString id={this.props.header} />;
        let exportDialog, exportDialogFooter;

        if (!this.props.error) {
            if (this.props.exportData) {
                ({
                    exportDialog,
                    exportDialogFooter
                } = this.renderExportDownload());
            } else if (this.props.isLoading) {
                ({
                    exportDialog,
                    exportDialogFooter
                } = this.renderLoadingExportData());
            } else {
                ({
                    exportDialog,
                    exportDialogFooter
                } = this.renderExportError());
            }
        } else {
            ({ exportDialog, exportDialogFooter } = this.renderExportError());
        }

        return (
            <Dialog
                className="dialog user-export-dialog"
                visible={this.props.visible}
                closeOnEscape={true}
                closable={false}
                header={exportDialogHeader}
                footer={exportDialogFooter}
                onHide={this.props.onExportCancel}
            >
                {exportDialog}
            </Dialog>
        );
    }
}

class ExcelExport extends Component {
    componentDidMount() {
        this.props.onExportStarted();
    }

    formatExportData() {
        const data = (this.props.exportData || []).map(user => {
            const roles =
                Array.isArray(user.roles) && user.roles.length > 0
                    ? user.roles.join(', ')
                    : '';
            return [
                { value: user.username },
                { value: user._id },
                { value: user.name },
                { value: user.firstName || '' },
                { value: user.email || '' },
                { value: user.id },
                { value: roles },
                { value: user.locale }
            ];
        });

        return [
            {
                columns: [
                    'Username',
                    'User ID',
                    'Name',
                    'First Name',
                    'E-Mail',
                    'Additional ID',
                    'Roles',
                    'Language'
                ],
                data: data
            }
        ];
    }

    render() {
        const data = this.formatExportData();
        return (
            <ReactExport.ExcelFile
                hideElement={true}
                filename={`UnbluMeet-${this.props.service._id}-Users`}
            >
                <ReactExport.ExcelFile.ExcelSheet dataSet={data} name="Users" />
            </ReactExport.ExcelFile>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.users.isLoading,
        isEditing: state.users.isEditing,
        userToAdd: state.users.userToAdd,
        addValidationSet: state.users.addValidationSet,
        userToEdit: state.users.userToEdit,
        editValidationSet: state.users.editValidationSet,
        users: state.users.users,
        contentRange: state.users.contentRange,
        syncProgress: state.users.syncProgress,

        exportData: state.users.exportData,

        topics: state.topics.topics,

        user: state.auth.user,
        service: state.auth.service,

        language: state.i18n.language
    };
};

const mapDispatchToProps = {
    requestTopics,
    requestUsers,
    addUser,
    updateUser,
    deleteUser,
    updateRequestParameters,
    requestExportData,
    syncUsers,
    startEditingUser,
    stopEditingUser,
    editUser,
    addUserValidation,
    resetExportData,
    resetUsersPage
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
