export const AUTH_MIN_TOKEN_VALIDITY = 150; //in seconds
export const AUTH_MAX_INACTIVITY_TIME = 900; //in seconds
export const AUTH_REFRESH_TOKEN_INTERVAL = 60; //in seconds

export const ERRORS = {
    SERVER_UNREACHABLE_ERROR: {
        internalError: true,
        errorNo: 0,
        message: 'could not reach server'
    },
    PARSING_ERROR: {
        internalError: true,
        errorNo: 1,
        message: 'could not parse response'
    }
};

export const MEETING_TYPES = {
    MEETING: 1,
    WEBINAR: 2,
    PHONE_CONSULTING: 4,
    PODIUM: 8,
    OUTLOOK_MEETING: 16,
    CALL_MANAGER: 32
};

export const MEETING_TYPE_NAMES = {
    1: 'meeting',
    2: 'webinar',
    4: 'phoneConsulting',
    8: 'podium',
    16: 'outlookMeeting',
    32: 'callManager'
};

export const SUPPORTED_ADIAPAD_TILES = {
    CALL_MANAGER_INVITE_ADVISER: 'callManagerInviteAdviser',
    CALL_MANAGER_TRANSFER: 'callManagerTransfer',
    START_STOP_RECORDING: 'startStopRecording',
    MUTE_PARTICIPANT: 'muteParticipant',
    ADD_PARTICIPANT: 'addParticipant',
    REMOVE_PARTICIPANT: 'removeParticipant',
    SHOW_CAM_MIC_SETTINGS: 'showCamMicSettings',
    SEPARATOR_1: 'separator1',
    SHOW_SHOWBOARD: 'showShowboard',
    SHOW_AGENDA: 'showAgenda',
    SHOW_MINUTES: 'showMinutes',
    REQUEST_SIGNATURE: 'requestSignature',
    START_STOP_SCREEN_SHARE: 'startStopScreenShare',
    SEPARATOR_2: 'separator2'
};
