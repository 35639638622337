import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import '../../css/base/CustomGrowl.scss';

class CustomGrowl extends Component {
    constructor(props) {
        super(props);
        this.growl = React.createRef();
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.message !== this.props.message) {
            let type;
            const message = nextProps.message.content;
            switch (nextProps.message.type) {
                case 'error':
                    type = 'error';
                    break;
                case 'warn':
                    type = 'warn';
                    break;
                default:
                    type = 'success';
            }

            this.growl.current.show({
                severity: type,
                detail: message || '',
                //sticky: true,
                closable: true,
                life: 4000
            });
        }
        return false;
    }

    render() {
        return <Growl ref={this.growl} className={'custom-growl'} />;
    }
}

// growl from primereact with custom style
let messageIdx = 0;

class Growl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: []
        };

        this.onClose = this.onClose.bind(this);
        this.container = React.createRef();
    }

    show(value) {
        if (value) {
            let newMessages;

            if (Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) {
                    value[i].id = messageIdx++;
                    newMessages = [...this.state.messages, ...value];
                }
            } else {
                value.id = messageIdx++;
                newMessages = this.state.messages
                    ? [...this.state.messages, value]
                    : [value];
            }

            this.setState({
                messages: newMessages
            });

            //this.container.style.zIndex = String(this.props.baseZIndex || 0 + DomHandler.generateZIndex());
            this.container.current.style.zIndex = String(this.props.baseZIndex);
        }
    }

    clear() {
        this.setState({
            messages: []
        });
    }

    onClose(message) {
        let newMessages = this.state.messages.filter(
            msg => msg.id !== message.id
        );
        this.setState({
            messages: newMessages
        });

        if (this.props.onRemove) {
            this.props.onRemove(message);
        }
    }

    render() {
        let className = classNames(
            'p-growl p-component p-growl-' +
                (this.props.position || 'topright'),
            this.props.className
        );

        return (
            <div
                ref={this.container}
                id={this.props.id}
                className={className}
                style={this.props.style}
            >
                {this.state.messages.map(message => (
                    <GrowlMessage
                        message={message}
                        key={message.id}
                        onClick={this.props.onClick}
                        onClose={this.onClose}
                    />
                ))}
            </div>
        );
    }
}

class GrowlMessage extends Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.onClose = this.onClose.bind(this);

        this.el = React.createRef();
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    componentDidMount() {
        if (!this.props.message.sticky) {
            this.timeout = setTimeout(() => {
                this.onClose(null);
            }, this.props.message.life || 3000);
        }
    }

    onClose(event) {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        if (this.props.onClose) {
            this.props.onClose(this.props.message);
        }

        if (event) {
            event.preventDefault();
        }
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick(this.props.message);
        }
    }

    renderCloseIcon() {
        if (this.props.message.closable !== false) {
            /*return (
                <button className="p-growl-icon-close p-link" onClick={this.onClose}>
                    <span className="p-growl-icon-close-icon pi pi-times"/>
                </button>
            );*/
            return (
                <div className="p-growl-close">
                    <button onClick={this.onClose}>
                        <span>OK</span>
                    </button>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        let className = classNames('p-growl-item-container p-highlight', {
            'p-growl-message-info': this.props.message.severity === 'info',
            'p-growl-message-warn': this.props.message.severity === 'warn',
            'p-growl-message-error': this.props.message.severity === 'error',
            'p-growl-message-success': this.props.message.severity === 'success'
        });

        let closeIcon = this.renderCloseIcon();

        return (
            <div
                ref={this.el}
                className={className}
                aria-live="polite"
                onClick={this.onClick}
            >
                <div className="p-growl-item">
                    {closeIcon}
                    <div className="p-growl-message">
                        {this.props.message.summary && (
                            <span className="p-growl-title">
                                {this.props.message.summary}
                            </span>
                        )}
                        <span>{this.props.message.detail}</span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        language: state.i18n.language,
        message: state.messages.message
    };
};

export default connect(
    mapStateToProps,
    undefined
)(CustomGrowl);
