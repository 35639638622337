import React, { Component } from 'react';
import error404Image from '../../../serenity/sass/images/exception/404.svg';
import error401Image from '../../../serenity/sass/images/exception/401.svg';
import TranslatedString from '../i18n/TranslatedString';

export default class ErrorPage extends Component {
    render() {
        const errorImage =
            this.props.error === 404 ? error404Image : error401Image;

        return (
            <div className="exception-body error">
                <div className="exception-panel">
                    <div className="exception-code">
                        <img
                            src={errorImage}
                            alt={this.props.error + ' error'}
                        />
                    </div>

                    <div className="exception-detail">
                        <div className="exception-icon">
                            <i className="material-icons">close</i>
                        </div>
                        <h1>
                            <TranslatedString
                                id={
                                    this.props.error === 404
                                        ? 'pathDoesNotExist'
                                        : 'accessDenied'
                                }
                            />
                        </h1>
                    </div>
                </div>
            </div>
        );
    }
}
