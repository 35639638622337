import * as types from './actionTypes';
import { handleError, log } from '../base/util/helpers';
import { forceLogout, noServiceFound } from '../auth/actions';
import authHelper from '../base/util/authHelper';

function convertMeetingProperties(meetingData, dataFromServer) {
    if (dataFromServer) {
        meetingData.forEach(meeting => {
            // convert unix timestamp to date
            if (meeting.date) {
                meeting.date = new Date(meeting.date);
            }

            // convert additionalMetadata object to array
            if (meeting.additionalMetadata) {
                meeting.additionalMetadata = Object.entries(
                    meeting.additionalMetadata
                );
            }
        });
    } else {
        // convert date to unix timestamp
        if (meetingData.date) {
            meetingData.date = new Date(meetingData.date).getTime();
        }

        // convert additionalMetadata array to object
        if (meetingData.additionalMetadata) {
            meetingData.additionalMetadata = meetingData.additionalMetadata.reduce(
                (acc, curr) => {
                    acc[curr[0]] = curr[1];
                    return acc;
                },
                {}
            );
        }
    }
}

/*
 * action creators
 */
function requestMeetingsRequest() {
    return { type: types.REQUEST_MEETINGS };
}

function requestMeetingsSuccess(meetings, contentRange) {
    return { type: types.REQUEST_MEETINGS_SUCCESS, meetings, contentRange };
}

function requestMeetingsFailure(error) {
    return { type: types.REQUEST_MEETINGS_FAILURE, error };
}

/*
 * thunk
 */
export function requestMeetings({ fields, sort, range }) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(requestMeetingsRequest());

            const response = await api.getMeetings(
                service._id,
                fields,
                sort,
                range,
                authHelper.getToken()
            );
            if (!response.error) {
                convertMeetingProperties(response.meetings, true);
                log.debug('[requestMeetings]', response.meetings);
                dispatch(
                    requestMeetingsSuccess(
                        response.meetings,
                        response.contentRange
                    )
                );
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(requestMeetingsFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

/*
 * action creators
 */
function addMeetingRequest() {
    return { type: types.ADD_MEETING };
}

function addMeetingSuccess(meeting) {
    return { type: types.ADD_MEETING_SUCCESS, meeting };
}

function addMeetingFailure(error) {
    return { type: types.ADD_MEETING_FAILURE, error };
}

/*
 * thunk
 */
export function addMeeting({ fields, sort, range }) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(addMeetingRequest());

            const meetingToAdd = getState().meetings.meetingToAdd;

            // create a copy of the meetingToAdd object
            let meeting = { ...meetingToAdd };
            if (meeting.additionalMetadata) {
                meeting.additionalMetadata = meeting.additionalMetadata.map(
                    additionalMetadata => ({ ...additionalMetadata })
                );
            }

            convertMeetingProperties(meeting, false);
            log.debug('[addMeeting]', meeting);

            const response = await api.addMeeting(
                service._id,
                meeting,
                authHelper.getToken()
            );
            if (!response.error) {
                dispatch(addMeetingSuccess(response.meeting));
                dispatch(requestMeetings({ fields, sort, range }));
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(addMeetingFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

/*
 * action creators
 */
function updateMeetingRequest() {
    return { type: types.UPDATE_MEETING };
}

function updateMeetingSuccess(meeting) {
    return { type: types.UPDATE_MEETING_SUCCESS, meeting };
}

function updateMeetingFailure(error) {
    return { type: types.UPDATE_MEETING_FAILURE, error };
}

/*
 * thunk
 */
export function updateMeeting(meetingId, { fields, sort, range }) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(updateMeetingRequest());

            const meetingToEdit = getState().meetings.meetingToEdit;
            const updatedFields = getState().meetings.updatedFields;

            // create a copy of the meetingToEdit object
            let meeting = { ...meetingToEdit };
            if (meetingToEdit.additionalMetadata) {
                meeting.additionalMetadata = meetingToEdit.additionalMetadata.map(
                    additionalMetadata => ({ ...additionalMetadata })
                );
            }

            // remove fields that were not updated
            Object.keys(meeting).forEach(field => {
                if (!updatedFields[field]) {
                    delete meeting[field];
                }
            });

            convertMeetingProperties(meeting, false);
            log.debug('[updateMeeting]', meeting);

            const response = await api.updateMeeting(
                service._id,
                meetingId,
                meeting,
                authHelper.getToken()
            );
            if (!response.error) {
                dispatch(updateMeetingSuccess(response.meeting));
                dispatch(requestMeetings({ fields, sort, range }));
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(updateMeetingFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

/*
 * action creators
 */
function deleteMeetingRequest() {
    return { type: types.DELETE_MEETING };
}

function deleteMeetingSuccess(meetingId) {
    return { type: types.DELETE_MEETING_SUCCESS, meetingId };
}

function deleteMeetingFailure(error) {
    return { type: types.DELETE_MEETING_FAILURE, error };
}

/*
 * thunk
 */
export function deleteMeeting(meetingId, { fields, sort, range }) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(deleteMeetingRequest());

            const response = await api.deleteMeeting(
                service._id,
                meetingId,
                authHelper.getToken()
            );
            if (!response.error) {
                dispatch(deleteMeetingSuccess(meetingId));
                dispatch(requestMeetings({ fields, sort, range }));
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(deleteMeetingFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

/*
 * action creators
 */
export function startEditingMeeting(meetingData) {
    return { type: types.START_EDITING_MEETING, meetingData };
}

export function stopEditingMeeting() {
    return { type: types.STOP_EDITING_MEETING };
}

export function editMeeting(currentFields, update) {
    return { type: types.EDIT_MEETING, currentFields, update };
}

export function addMeetingValidation(validationType) {
    return { type: types.ADD_MEETING_VALIDATION, validationType };
}

function resetPage() {
    return { type: types.RESET_MEETINGS_PAGE };
}

/*
 * thunk
 */
export function resetMeetingsPage(
    loadMeetings = false,
    { fields, sort, range } = {}
) {
    return async dispatch => {
        dispatch(resetPage());
        if (loadMeetings) {
            dispatch(requestMeetings({ fields, sort, range }));
        }
    };
}
