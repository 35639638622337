import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    addMaintenanceWindow,
    addMaintenanceWindowValidation,
    deleteMaintenanceWindow,
    editMaintenanceWindow,
    requestMaintenanceWindows,
    resetMaintenanceWindowsPage,
    startEditingMaintenanceWindow,
    stopEditingMaintenanceWindow,
    updateMaintenanceWindow
} from './actions';

import classNames from 'classnames';
import '../../css/features/MaintenanceWindows.scss';

import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import EditButtons from '../base/EditButtons';
import DeleteDialog from '../base/DeleteDialog';
import FormButtons from '../base/FormButtons';
import TranslatedString from '../base/i18n/TranslatedString';
import { getTranslatedString } from '../base/i18n/translations';
import {parseDate} from "../base/util/helpers";
import {Calendar} from "primereact/calendar";

export class MaintenanceWindows extends Component {
    constructor(props) {
        super(props);

        this.rows = 25;
        this.defaultMaintenanceWindowFields = {
            description: '',
            startDateTime: '',
            endDateTime: ''
        };

        this.state = {
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
        this.handleEditForm = this.handleEditForm.bind(this);
        this.handleAddValidation = this.handleAddValidation.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    componentDidMount() {
        this.props.requestMaintenanceWindows({
            range: { startIndex: 0, endIndex: this.rows - 1 }
        });
    }

    componentDidUpdate(prevProps) {
        // reset maintenanceWindows page if the service was changed
        if (prevProps.service !== this.props.service && this.props.service) {
            this.props.resetMaintenanceWindowsPage(true, {
                range: {
                    startIndex: 0,
                    endIndex: this.rows - 1
                }
            });

            this.setState({
                showDeleteDialog: false,
                itemToDeleteId: undefined,
                itemToDeleteName: undefined
            });
        }
    }

    componentWillUnmount() {
        this.props.resetMaintenanceWindowsPage();
    }

    handlePageClick(event) {
        this.props.requestMaintenanceWindows({
            range: {
                startIndex: event.first,
                endIndex: event.first + this.rows - 1
            }
        });
    }

    handleEditClick(maintenanceWindowId) {
        if (this.props.isEditing && maintenanceWindowId === this.props.maintenanceWindowToEdit._id) {
            this.props.stopEditingMaintenanceWindow();
        } else {
            //find maintenanceWindow object with matching maintenanceWindowId to edit
            const maintenanceWindow = this.props.maintenanceWindows.find(item => {
                return item._id === maintenanceWindowId;
            });

            //build maintenanceWindow object with using given property values or default values if necessary
            let maintenanceWindowData = { _id: maintenanceWindow._id };
            Object.keys(this.defaultMaintenanceWindowFields).forEach(prop => {
                if (maintenanceWindow.hasOwnProperty(prop)) {
                    maintenanceWindowData[prop] = maintenanceWindow[prop];
                } else {
                    maintenanceWindowData[prop] = this.defaultMaintenanceWindowFields[prop];
                }
            });

            this.props.startEditingMaintenanceWindow(maintenanceWindowData);
        }
    }

    handleDeleteClick(maintenanceWindowId, maintenanceWindowName) {
        this.setState({
            showDeleteDialog: true,
            itemToDeleteId: maintenanceWindowId,
            itemToDeleteName: maintenanceWindowName
        });
    }

    handleDeleteConfirm() {
        const maintenanceWindows = this.props.maintenanceWindows;
        let currentItems = { ...this.props.contentRange.currentItems };

        // if the item to be deleted is the last one on the currently selected table page, request the items for the previous table page
        if (
            currentItems.startIndex === currentItems.endIndex &&
            maintenanceWindows[maintenanceWindows.length - 1]._id === this.state.itemToDeleteId
        ) {
            currentItems = {
                startIndex: currentItems.startIndex - this.rows,
                endIndex: currentItems.startIndex - 1
            };
            if (currentItems.startIndex < 0) {
                currentItems = undefined;
            }
        }

        this.props.deleteMaintenanceWindow(this.state.itemToDeleteId, {
            range: currentItems
        });
        this.setState({
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        });
    }

    handleDeleteCancel() {
        this.setState({
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        });
    }

    handleEditForm(currentFields, update) {
        this.props.editMaintenanceWindow(currentFields, update);
    }

    handleAddValidation(validationType) {
        const validationSet = this.props.isEditing
            ? this.props.editValidationSet
            : this.props.addValidationSet;
        if (!validationSet.hasOwnProperty(validationType)) {
            this.props.addMaintenanceWindowValidation(validationType);
        }
    }

    handleSaveClick() {
        let currentItems;
        if (this.props.contentRange) {
            currentItems = { ...this.props.contentRange.currentItems };
        }

        if (this.props.isEditing) {
            this.props.updateMaintenanceWindow(this.props.maintenanceWindowToEdit._id, {
                range: currentItems
            });
        } else {
            if (currentItems) {
                currentItems.endIndex = currentItems.startIndex + this.rows - 1;
            }
            this.props.addMaintenanceWindow({ range: currentItems });
        }
    }

    handleCancelClick() {
        this.props.stopEditingMaintenanceWindow();
    }

    render() {
        return (
            <React.Fragment>
                <div className="p-grid">
                    <div className="p-col-12 p-xl-8">
                        <MaintenanceWindowsTable
                            isLoading={this.props.isLoading}
                            editedRowId={
                                this.props.isEditing
                                    ? this.props.maintenanceWindowToEdit._id
                                    : undefined
                            }
                            maintenanceWindows={this.props.maintenanceWindows}
                            language={this.props.language}
                            rows={this.rows}
                            contentRange={this.props.contentRange}
                            onPageClick={this.handlePageClick}
                            onEditClick={this.handleEditClick}
                            onDeleteClick={this.handleDeleteClick}
                        />
                    </div>

                    <div className="p-col-12 p-xl-4">
                        <MaintenanceWindowForm
                            isLoading={this.props.isLoading}
                            isEditing={this.props.isEditing}
                            maintenanceWindowFormFields={
                                (this.props.isEditing
                                    ? this.props.maintenanceWindowToEdit
                                    : this.props.maintenanceWindowToAdd) ||
                                this.defaultMaintenanceWindowFields
                            }
                            validationSet={
                                this.props.isEditing
                                    ? this.props.editValidationSet
                                    : this.props.addValidationSet
                            }
                            language={this.props.language}
                            onEditForm={this.handleEditForm}
                            onAddValidation={this.handleAddValidation}
                            onSaveClick={this.handleSaveClick}
                            onCancelClick={this.handleCancelClick}
                        />
                    </div>
                </div>

                <DeleteDialog
                    visible={this.state.showDeleteDialog}
                    header={'maintenanceWindowDeleteHeader'}
                    dialog={'maintenanceWindowDeleteDialog'}
                    itemToDelete={this.state.itemToDeleteName}
                    onDeleteConfirm={this.handleDeleteConfirm}
                    onDeleteCancel={this.handleDeleteCancel}
                />
            </React.Fragment>
        );
    }
}

class MaintenanceWindowsTable extends Component {
    render() {
        const maintenanceWindowTableEntries = this.props.maintenanceWindows.map((item, key) => {
            const startDate = parseDate(item.startDateTime);
            const endDate = parseDate(item.endDateTime);

            const maintenanceWindow = { ...item,
            startDateTimeFormatted: `${startDate.day}.${startDate.month}.${startDate.year}, ${startDate.hours}:${startDate.minutes}`,
            endDateTimeFormatted: `${endDate.day}.${endDate.month}.${endDate.year}, ${endDate.hours}:${endDate.minutes}`};

            maintenanceWindow.editMaintenanceWindowButtons = (
                <EditButtons
                    key={key}
                    id={maintenanceWindow._id}
                    name={maintenanceWindow.description}
                    isLoading={this.props.isLoading}
                    onEditClick={this.props.onEditClick}
                    onDeleteClick={this.props.onDeleteClick}
                />
            );

            return maintenanceWindow;
        });

        return (
            <Card title={getTranslatedString(this.props.language, 'maintenanceWindows')}>
                <DataTable
                    className="table"
                    autoLayout={true}
                    responsive={true}
                    value={maintenanceWindowTableEntries}
                    rows={this.props.rows}
                    rowClassName={rowData => {
                        return {
                            'row-bg':
                                rowData &&
                                rowData._id === this.props.editedRowId
                        };
                    }}
                    paginator={true}
                    paginatorPosition={'top'}
                    alwaysShowPaginator={true}
                    lazy={true}
                    totalRecords={
                        this.props.contentRange
                            ? this.props.contentRange.totalItems
                            : undefined
                    }
                    first={
                        this.props.contentRange
                            ? this.props.contentRange.currentItems.startIndex
                            : 0
                    }
                    onPage={this.props.onPageClick}
                >
                    <Column
                        className="column id-col"
                        field="_id"
                        header={<TranslatedString id={'maintenanceWindowTableId'} />}
                    />
                    <Column
                        className="column name-col"
                        field="description"
                        header={<TranslatedString id={'maintenanceWindowTableDescription'} />}
                    />
                    <Column
                        className="column stats-group-col"
                        field="startDateTimeFormatted"
                        header={
                            <TranslatedString id={'maintenanceWindowTableStartDateTime'} />
                        }
                    />
                    <Column
                        className="column stats-group-col"
                        field="endDateTimeFormatted"
                        header={
                            <TranslatedString id={'maintenanceWindowTableEndDateTime'} />
                        }
                    />
                    <Column
                        className="column edit-buttons-col"
                        field="editMaintenanceWindowButtons"
                    />
                </DataTable>
            </Card>
        );
    }
}

class MaintenanceWindowForm extends Component {
    constructor(props) {
        super(props);

        this.validators = {
            nonEmpty: input => {
                // any non-empty input is valid
                return input ? (input+'').trim() !== '' : false;
            }
        };
    }

    /**
     * onChange event handler input field
     * @param fieldId - property name of input field
     * @returns {Function}
     */
    handleOnChange = fieldId => e => {
        this.props.onEditForm(this.props.maintenanceWindowFormFields, {
            field: fieldId,
            value: e.target.value
        });
    };

    /**
     * onBlur event handler for input field
     * @param fieldId - property name of input field
     * @returns {Function}
     */
    handleOnBlur = fieldId => () => {
        this.props.onAddValidation(fieldId);
    };

    /**
     * validate maintenanceWindow form fields
     * @param maintenanceWindowFormFields
     * @returns {{invalidInputFields: {date: boolean, title: boolean}, invalidInputs: (boolean|*)}}
     */
    validateFormFields(maintenanceWindowFormFields) {
        const invalidInputFields = {
            description: !this.validators.nonEmpty(maintenanceWindowFormFields.description),
            startDateTime: !this.validators.nonEmpty(maintenanceWindowFormFields.startDateTime),
            endDateTime: !this.validators.nonEmpty(maintenanceWindowFormFields.endDateTime)
        };
        return {
            invalidInputFields: invalidInputFields,
            invalidInputs: Object.keys(invalidInputFields).reduce(
                (acc, curr) => acc || invalidInputFields[curr],
                false
            )
        };
    }

    renderMaintenanceWindowInputFields(invalidInputFields) {
        const maintenanceWindowFormFields = this.props.maintenanceWindowFormFields;
        const validationSet = this.props.validationSet;

        return (
            <React.Fragment>
                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className={classNames('form-input', {
                                'p-error':
                                    invalidInputFields.description &&
                                    validationSet.hasOwnProperty('description')
                            })}
                            readOnly={this.props.isEditing}
                            value={maintenanceWindowFormFields.description}
                            onChange={this.handleOnChange('description')}
                            onBlur={this.handleOnBlur('description')}
                        />
                        <label>
                            <TranslatedString id={'maintenanceWindowDescription'} />
                        </label>
                    </span>
                </div>

                <div className="p-col-12">
                        <span className="md-inputfield">
                            <Calendar
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.startDateTime &&
                                        validationSet.hasOwnProperty('startDateTime')
                                })}
                                value={maintenanceWindowFormFields.startDateTime}
                                dateFormat="dd.mm.yy, "
                                showTime={true}
                                readOnlyInput
                                locale={getTranslatedString(
                                    this.props.language,
                                    'calendar'
                                )}
                                onChange={this.handleOnChange('startDateTime')}
                                onBlur={this.handleOnBlur('startDateTime')}
                            />
                            <label>
                                <TranslatedString id={'maintenanceWindowStartDateTime'} />
                            </label>
                        </span>
                </div>

                <div className="p-col-12">
                        <span className="md-inputfield">
                            <Calendar
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.endDateTime &&
                                        validationSet.hasOwnProperty('endDateTime')
                                })}
                                value={maintenanceWindowFormFields.endDateTime}
                                dateFormat="dd.mm.yy, "
                                showTime={true}
                                readOnlyInput
                                locale={getTranslatedString(
                                    this.props.language,
                                    'calendar'
                                )}
                                onChange={this.handleOnChange('endDateTime')}
                                onBlur={this.handleOnBlur('endDateTime')}
                            />
                            <label>
                                <TranslatedString id={'maintenanceWindowEndDateTime'} />
                            </label>
                        </span>
                </div>

                {/*<div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className={classNames('form-input', {
                                'p-error':
                                    invalidInputFields.startDateTime &&
                                    validationSet.hasOwnProperty('startDateTime')
                            })}
                            value={maintenanceWindowFormFields.startDateTime}
                            onChange={this.handleOnChange('startDateTime')}
                            onBlur={this.handleOnBlur('startDateTime')}
                        />
                        <label>
                            <TranslatedString id={'maintenanceWindowStartDateTime'} />
                        </label>
                    </span>
                </div>

                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className={classNames('form-input', {
                                'p-error':
                                    invalidInputFields.endDateTime &&
                                    validationSet.hasOwnProperty('endDateTime')
                            })}
                            value={maintenanceWindowFormFields.endDateTime}
                            onChange={this.handleOnChange('endDateTime')}
                            onBlur={this.handleOnBlur('endDateTime')}
                        />
                        <label>
                            <TranslatedString id={'maintenanceWindowEndDateTime'} />
                        </label>
                    </span>
                </div>*/}
            </React.Fragment>
        );
    }

    render() {
        const { invalidInputFields, invalidInputs } = this.validateFormFields(
            this.props.maintenanceWindowFormFields
        );
        const maintenanceWindowInputFields = this.renderMaintenanceWindowInputFields(
            invalidInputFields
        );

        return (
            <Card
                title={getTranslatedString(
                    this.props.language,
                    this.props.isEditing ? 'maintenanceWindowEdit' : 'maintenanceWindowAdd'
                )}
            >
                <br />
                <div className="p-grid form-group">
                    {maintenanceWindowInputFields}
                    <div className="p-col-12">
                        <FormButtons
                            invalidInputs={invalidInputs}
                            isLoading={this.props.isLoading}
                            isEditing={this.props.isEditing}
                            onSaveClick={this.props.onSaveClick}
                            onCancelClick={this.props.onCancelClick}
                        />
                    </div>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.maintenanceWindows.isLoading,
        isEditing: state.maintenanceWindows.isEditing,
        maintenanceWindowToAdd: state.maintenanceWindows.maintenanceWindowToAdd,
        addValidationSet: state.maintenanceWindows.addValidationSet,
        maintenanceWindowToEdit: state.maintenanceWindows.maintenanceWindowToEdit,
        editValidationSet: state.maintenanceWindows.editValidationSet,
        maintenanceWindows: state.maintenanceWindows.maintenanceWindows,
        contentRange: state.maintenanceWindows.contentRange,

        service: state.auth.service,
        language: state.i18n.language
    };
};

const mapDispatchToProps = {
    requestMaintenanceWindows,
    addMaintenanceWindow,
    updateMaintenanceWindow,
    deleteMaintenanceWindow,
    startEditingMaintenanceWindow,
    stopEditingMaintenanceWindow,
    editMaintenanceWindow,
    addMaintenanceWindowValidation,
    resetMaintenanceWindowsPage
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceWindows);
