export const UPDATE_USER_REQUEST_PARAMETERS = 'UPDATE_USER_REQUEST_PARAMETERS';

export const REQUEST_USERS = 'REQUEST_USERS';
export const REQUEST_USERS_SUCCESS = 'REQUEST_USERS_SUCCESS';
export const REQUEST_USERS_FAILURE = 'REQUEST_USERS_FAILURE';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const RESET_EXPORT_DATA = 'RESET_EXPORT_DATA';
export const REQUEST_EXPORT_DATA = 'REQUEST_EXPORT_DATA';
export const REQUEST_EXPORT_DATA_SUCCESS = 'REQUEST_EXPORT_DATA_SUCCESS';
export const REQUEST_EXPORT_DATA_FAILURE = 'REQUEST_EXPORT_DATA_FAILURE';

export const SYNC_USERS = 'SYNC_USERS';
export const SYNC_USERS_PROGRESS = 'SYNC_USERS_PROGRESS';
export const SYNC_USERS_SUCCESS = 'SYNC_USERS_SUCCESS';
export const SYNC_USERS_FAILURE = 'SYNC_USERS_FAILURE';

export const START_EDITING_USER = 'START_EDITING_USER';
export const STOP_EDITING_USER = 'STOP_EDITING_USER';
export const EDIT_USER = 'EDIT_USER';
export const ADD_USER_VALIDATION = 'ADD_USER_VALIDATION';

export const RESET_USER_PAGE = 'RESET_USER_PAGE';
