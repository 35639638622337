import * as types from './actionTypes';
import { handleError, log } from '../base/util/helpers';
import { forceLogout } from '../auth/actions';
import authHelper from '../base/util/authHelper';

function convertAdminUserSettings(adminUserData, dataFromServer) {
    if (dataFromServer) {
        adminUserData.forEach(adminUser => {
            // convert roles array to string
            if(Array.isArray(adminUser.roles) && adminUser.roles.length > 0){
                adminUser.roles = adminUser.roles[0];
            } else{
                adminUser.roles = '';
            }
        });
    } else {
        // convert roles string to array
        adminUserData.roles = [adminUserData.roles];

        delete adminUserData.passwordConfirmation;
        if (adminUserData.password === '') {
            delete adminUserData.password;
        }
    }
}

/*
 * action creators
 */
function requestAdminUsersRequest() {
    return { type: types.REQUEST_ADMIN_USERS };
}

function requestAdminUsersSuccess(adminUsers, contentRange) {
    return {
        type: types.REQUEST_ADMIN_USERS_SUCCESS,
        adminUsers,
        contentRange
    };
}

function requestAdminUsersFailure(error) {
    return { type: types.REQUEST_ADMIN_USERS_FAILURE, error };
}

/*
 * thunk
 */
export function requestAdminUsers({ fields, sort, range }) {
    return async (dispatch, getState, api) => {
        dispatch(requestAdminUsersRequest());
        const response = await api.getAdminUsers(
            fields,
            sort,
            range,
            authHelper.getToken()
        );
        if (!response.error) {
            convertAdminUserSettings(response.adminUsers, true);
            log.debug('[requestAdminUsers]', response.adminUsers);
            dispatch(
                requestAdminUsersSuccess(
                    response.adminUsers,
                    response.contentRange
                )
            );
        } else {
            handleError(response.error, {
                fatal: error => dispatch(forceLogout(error)),
                nonFatal: error => dispatch(requestAdminUsersFailure(error))
            });
        }
    };
}

/*
 * action creators
 */
function addAdminUserRequest() {
    return { type: types.ADD_ADMIN_USER };
}

function addAdminUserSuccess(adminUser) {
    return { type: types.ADD_ADMIN_USER_SUCCESS, adminUser };
}

function addAdminUserFailure(error) {
    return { type: types.ADD_ADMIN_USER_FAILURE, error };
}

/*
 * thunk
 */
export function addAdminUser({ fields, sort, range }) {
    return async (dispatch, getState, api) => {
        dispatch(addAdminUserRequest());

        let adminUser = { ...getState().adminUsers.adminUserToAdd };
        convertAdminUserSettings(adminUser, false);

        log.debug('[addAdminUser]', adminUser);

        const response = await api.addAdminUser(
            adminUser,
            authHelper.getToken()
        );
        if (!response.error) {
            dispatch(addAdminUserSuccess(response.adminUser));
            dispatch(requestAdminUsers({ fields, sort, range }));
        } else {
            handleError(response.error, {
                fatal: error => dispatch(forceLogout(error)),
                nonFatal: error => dispatch(addAdminUserFailure(error))
            });
        }
    };
}

/*
 * action creators
 */
function updateAdminUserRequest() {
    return { type: types.UPDATE_ADMIN_USER };
}

function updateAdminUserSuccess(adminUser) {
    return { type: types.UPDATE_ADMIN_USER_SUCCESS, adminUser };
}

function updateAdminUserFailure(error) {
    return { type: types.UPDATE_ADMIN_USER_FAILURE, error };
}

/*
 * thunk
 */
export function updateAdminUser(adminUserId, { fields, sort, range }) {
    return async (dispatch, getState, api) => {
        dispatch(updateAdminUserRequest());

        let adminUser = { ...getState().adminUsers.adminUserToEdit };
        const updatedFields = getState().adminUsers.updatedFields;

        convertAdminUserSettings(adminUser, false);

        // remove fields that were not updated
        Object.keys(adminUser).forEach(field => {
            if (!updatedFields[field]) {
                delete adminUser[field];
            }
        });
        log.debug('[updateAdminUser]', adminUser);

        const response = await api.updateAdminUser(
            adminUserId,
            adminUser,
            authHelper.getToken()
        );
        if (!response.error) {
            dispatch(updateAdminUserSuccess(response.adminUser));
            dispatch(requestAdminUsers({ fields, sort, range }));
        } else {
            handleError(response.error, {
                fatal: error => dispatch(forceLogout(error)),
                nonFatal: error => dispatch(updateAdminUserFailure(error))
            });
        }
    };
}

/*
 * action creators
 */
function deleteAdminUserRequest() {
    return { type: types.DELETE_ADMIN_USER };
}

function deleteAdminUserSuccess(adminUserId) {
    return { type: types.DELETE_ADMIN_USER_SUCCESS, adminUserId };
}

function deleteAdminUserFailure(error) {
    return { type: types.DELETE_ADMIN_USER_FAILURE, error };
}

/*
 * thunk
 */
export function deleteAdminUser(adminUserId, { fields, sort, range }) {
    return async (dispatch, getState, api) => {
        dispatch(deleteAdminUserRequest());
        const response = await api.deleteAdminUser(
            adminUserId,
            authHelper.getToken()
        );
        if (!response.error) {
            dispatch(deleteAdminUserSuccess(adminUserId));
            dispatch(requestAdminUsers({ fields, sort, range }));
        } else {
            handleError(response.error, {
                fatal: error => dispatch(forceLogout(error)),
                nonFatal: error => dispatch(deleteAdminUserFailure(error))
            });
        }
    };
}

/*
 * action creators
 */
export function startEditingAdminUser(adminUserData) {
    return { type: types.START_EDITING_ADMIN_USER, adminUserData };
}

export function stopEditingAdminUser() {
    return { type: types.STOP_EDITING_ADMIN_USER };
}

export function editAdminUser(currentFields, update) {
    return { type: types.EDIT_ADMIN_USER, currentFields, update };
}

export function addAdminUserValidation(validationType) {
    return { type: types.ADD_ADMIN_USER_VALIDATION, validationType };
}

function resetPage() {
    return { type: types.RESET_ADMIN_USER_PAGE };
}

/*
 * thunk
 */
export function resetAdminUserPage(
    loadAdminUsers = false,
    { fields, sort, range } = {}
) {
    return async dispatch => {
        dispatch(resetPage());
        if (loadAdminUsers) {
            dispatch(requestAdminUsers({ fields, sort, range }));
        }
    };
}
