import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import '../../css/base/Dialog.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { getTranslatedString } from './i18n/translations';
import TranslatedString from './i18n/TranslatedString';

class DeleteDialog extends PureComponent {
    render() {
        const deleteDialogHeader = <TranslatedString id={this.props.header} />;
        const deleteDialogFooter = (
            <div>
                <Button
                    className="button confirm"
                    label={getTranslatedString(this.props.language, 'yes')}
                    icon="pi pi-check"
                    onClick={this.props.handleDeleteConfirm}
                />
                <Button
                    className="button cancel"
                    label={getTranslatedString(this.props.language, 'no')}
                    icon="pi pi-times"
                    onClick={this.props.handleDeleteCancel}
                />
            </div>
        );

        return (
            <React.Fragment>
                <Dialog
                    className="dialog"
                    visible={this.props.visible}
                    focusOnShow={false}
                    closeOnEscape={true}
                    closable={false}
                    header={deleteDialogHeader}
                    footer={deleteDialogFooter}
                    onHide={this.props.handleDeleteCancel}
                >
                    <TranslatedString id={this.props.dialog} />

                    {this.props.itemToDelete && (
                        <div className="item-to-delete">
                            <span>{this.props.itemToDelete}</span>
                        </div>
                    )}
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        language: state.i18n.language,
        visible: ownProps.visible,
        header: ownProps.header,
        dialog: ownProps.dialog,
        itemToDelete: ownProps.itemToDelete,
        handleDeleteConfirm: ownProps.onDeleteConfirm,
        handleDeleteCancel: ownProps.onDeleteCancel
    };
};

export default connect(
    mapStateToProps,
    undefined
)(DeleteDialog);
