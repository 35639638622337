import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../../css/forms.scss';
import { InputTextarea } from 'primereact/inputtextarea';

class CustomInputTextarea extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasValue: Boolean(props.value),
            focus: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        //update hasValue flag if new values are loaded
        const hasValue = Boolean(props.value);
        if (hasValue !== state.hasValue) {
            return { hasValue: hasValue };
        }
        return null;
    }

    handleChange = e => {
        if (!this.props.readOnly) {
            this.props.onChange(e);
        }

        if (e.target.value) {
            if (!this.state.hasValue) {
                this.setState({ hasValue: true });
            }
        } else {
            if (this.state.hasValue) {
                this.setState({ hasValue: false });
            }
        }
    };

    handleFocus = () => {
        this.setState({ focus: true });
    };

    handleBlur = () => {
        this.setState({ focus: false });
    };

    render() {
        const floatingLabel = this.state.focus || this.state.hasValue;

        return (
            <div onFocus={this.handleFocus} onBlur={this.handleBlur}>
                <label
                    className={
                        floatingLabel
                            ? ' textarea-label float'
                            : ' textarea-label'
                    }
                >
                    {this.props.label}
                </label>
                <InputTextarea
                    className={this.props.className || ''}
                    autoResize={true}
                    rows={this.props.rows || 1}
                    value={this.props.value}
                    readOnly={!!this.props.readOnly}
                    onChange={this.handleChange}
                    onBlur={this.props.onBlur}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        language: state.i18n.language,
        className: ownProps.className,
        value: ownProps.value,
        onChange: ownProps.onChange,
        onBlur: ownProps.onBlur,
        label: ownProps.label,
        rows: ownProps.rows,
        readOnly: ownProps.readOnly
    };
};

export default connect(
    mapStateToProps,
    undefined
)(CustomInputTextarea);
