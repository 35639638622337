import * as types from './actionTypes';

export const initialState = {
    isLoading: false,
    isEditing: false,

    userToAdd: undefined,
    addValidationSet: {},

    userToEdit: undefined,
    updatedFields: undefined,
    editValidationSet: {},

    users: [],
    requestParameters: {
        fields: undefined,
        sort: ['username'],
        range: undefined,
        search: []
    },
    contentRange: undefined,

    exportData: undefined,
    syncProgress: undefined,

    error: undefined
};

/**
 *
 * @param state
 * @param action
 * @returns new state
 */
export default function users(state = initialState, action) {
    switch (action.type) {
        case types.UPDATE_USER_REQUEST_PARAMETERS:
            const update = { ...state.requestParameters };
            for (let requestParameter in action.requestParameters) {
                update[requestParameter] =
                    action.requestParameters[requestParameter];
            }

            return {
                ...state,
                requestParameters: update
            };

        case types.REQUEST_USERS:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.REQUEST_USERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                users: action.users || [],
                contentRange: action.contentRange
            };
        case types.REQUEST_USERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case types.ADD_USER:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.ADD_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userToAdd: undefined,
                addValidationSet: {}
            };
        case types.ADD_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case types.UPDATE_USER:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.UPDATE_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isEditing: false,
                userToEdit: undefined,
                updatedFields: undefined,
                editValidationSet: {}
            };
        case types.UPDATE_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case types.DELETE_USER:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.DELETE_USER_SUCCESS:
            let newState = {
                ...state,
                isLoading: false
            };
            if (state.isEditing && state.userToEdit._id === action.userId) {
                newState.isEditing = false;
                newState.userToEdit = undefined;
                newState.editValidationSet = {};
            }
            return newState;
        case types.DELETE_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case types.REQUEST_EXPORT_DATA:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.REQUEST_EXPORT_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                exportData: action.exportData || undefined
            };
        case types.REQUEST_EXPORT_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case types.RESET_EXPORT_DATA:
            return {
                ...state,
                exportData: undefined
            };

        case types.SYNC_USERS:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.SYNC_USERS_PROGRESS:
            return {
                ...state,
                syncProgress: action.progress
            };
        case types.SYNC_USERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                syncProgress: undefined
            };
        case types.SYNC_USERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                syncProgress: undefined,
                error: action.error
            };

        case types.START_EDITING_USER:
            return {
                ...state,
                isEditing: true,
                userToEdit: action.userData,
                updatedFields: {}
            };
        case types.STOP_EDITING_USER:
            return {
                ...state,
                isEditing: false,
                userToEdit: undefined,
                updatedFields: undefined,
                editValidationSet: {}
            };
        case types.EDIT_USER:
            if (state.isEditing) {
                return {
                    ...state,
                    userToEdit: {
                        ...action.currentFields,
                        [action.update.field]: action.update.value
                    },
                    updatedFields: {
                        ...state.updatedFields,
                        [action.update.field]: true
                    }
                };
            } else {
                return {
                    ...state,
                    userToAdd: {
                        ...action.currentFields,
                        [action.update.field]: action.update.value
                    }
                };
            }
        case types.ADD_USER_VALIDATION:
            if (state.isEditing) {
                return {
                    ...state,
                    editValidationSet: {
                        ...state.editValidationSet,
                        [action.validationType]: true
                    }
                };
            } else {
                return {
                    ...state,
                    addValidationSet: {
                        ...state.addValidationSet,
                        [action.validationType]: true
                    }
                };
            }
        case types.RESET_USER_PAGE:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
