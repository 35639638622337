import {
    ADD_USER_FAILURE,
    ADD_USER_SUCCESS,
    DELETE_USER_FAILURE,
    DELETE_USER_SUCCESS,
    REQUEST_USERS_FAILURE,
    SYNC_USERS_FAILURE,
    SYNC_USERS_SUCCESS,
    UPDATE_USER_FAILURE,
    UPDATE_USER_SUCCESS
} from '../features/users/actionTypes';
import {
    ADD_MEETING_FAILURE,
    ADD_MEETING_SUCCESS,
    DELETE_MEETING_FAILURE,
    DELETE_MEETING_SUCCESS,
    REQUEST_MEETINGS_FAILURE,
    UPDATE_MEETING_FAILURE,
    UPDATE_MEETING_SUCCESS
} from '../features/meetings/actionTypes';
import {
    ADD_API_KEY_FAILURE,
    ADD_API_KEY_SUCCESS,
    DELETE_API_KEY_FAILURE,
    DELETE_API_KEY_SUCCESS,
    REQUEST_API_KEYS_FAILURE,
    UPDATE_API_KEY_FAILURE,
    UPDATE_API_KEY_SUCCESS
} from '../features/apikeys/actionTypes';
import {
    ADD_ADMIN_USER_FAILURE,
    ADD_ADMIN_USER_SUCCESS,
    DELETE_ADMIN_USER_FAILURE,
    DELETE_ADMIN_USER_SUCCESS,
    REQUEST_ADMIN_USERS_FAILURE,
    UPDATE_ADMIN_USER_FAILURE,
    UPDATE_ADMIN_USER_SUCCESS
} from '../features/adminusers/actionTypes';
import {
    ADD_SERVICE_FAILURE,
    ADD_SERVICE_SUCCESS,
    DELETE_SERVICE_FAILURE,
    DELETE_SERVICE_SUCCESS,
    REQUEST_SERVICES_FAILURE,
    UPDATE_SERVICE_FAILURE,
    UPDATE_SERVICE_SUCCESS
} from '../features/services/actionTypes';
import {
    CHANGE_SELECTED_SERVICE_FAILURE,
    NO_SERVICE
} from '../features/auth/actionTypes';
import {
    PREPARE_DOWNLOAD_DENIED,
    REQUEST_SESSIONS_FAILURE
} from '../features/sessions/actionTypes';

import { getTranslatedString } from '../features/base/i18n/translations';
import { showMessage } from '../features/base/messages/actions';

const dispatchShowSuccessMessage = (message, { getState, dispatch }) =>
    dispatch(
        showMessage({
            content: getTranslatedString(getState().i18n.language, message),
            type: 'success'
        })
    );

const dispatchShowErrorMessage = (message, { getState, dispatch }) =>
    dispatch(
        showMessage({
            content: getTranslatedString(getState().i18n.language, message),
            type: 'error'
        })
    );

const dispatchShowWarningMessage = (message, { getState, dispatch }) =>
    dispatch(
        showMessage({
            content: getTranslatedString(getState().i18n.language, message),
            type: 'warn'
        })
    );

export const createMessageMiddleware = () => {
    return ({ getState, dispatch }) => next => action => {
        // catch connection errors first
        if (
            action.error &&
            action.error.internalError &&
            action.error.errorNo === 0
        ) {
            dispatchShowErrorMessage('connectionError', { getState, dispatch });
            return next(action);
        }

        let message;
        switch (action.type) {
            case NO_SERVICE:
                dispatchShowErrorMessage('noServiceError', {
                    getState,
                    dispatch
                });
                return next(action);

            case CHANGE_SELECTED_SERVICE_FAILURE:
                dispatchShowErrorMessage('changeServiceError', {
                    getState,
                    dispatch
                });
                return next(action);

            // user actions
            case REQUEST_USERS_FAILURE:
                dispatchShowErrorMessage('userRequestError', {
                    getState,
                    dispatch
                });
                return next(action);

            case ADD_USER_SUCCESS:
                dispatchShowSuccessMessage('userAddSuccess', {
                    getState,
                    dispatch
                });
                return next(action);

            case ADD_USER_FAILURE:
                if (action.error.errorNo === 40900) {
                    message = 'userConflict';
                } else if (action.error.errorNo === 40950) {
                    message = 'userKeycloakConflict';
                } else {
                    message = 'userAddError';
                }

                dispatchShowErrorMessage(message, { getState, dispatch });
                return next(action);

            case UPDATE_USER_SUCCESS:
                dispatchShowSuccessMessage('userUpdateSuccess', {
                    getState,
                    dispatch
                });
                return next(action);

            case UPDATE_USER_FAILURE:
                if (action.error.errorNo === 40400) {
                    message = 'userNotFound';
                } else if (action.error.errorNo === 40900) {
                    message = 'userConflict';
                } else if (action.error.errorNo === 40950) {
                    message = 'userKeycloakConflict';
                } else {
                    message = 'userUpdateError';
                }

                dispatchShowErrorMessage(message, { getState, dispatch });
                return next(action);

            case DELETE_USER_SUCCESS:
                dispatchShowSuccessMessage('userDeleteSuccess', {
                    getState,
                    dispatch
                });
                return next(action);

            case DELETE_USER_FAILURE:
                dispatchShowErrorMessage('userDeleteError', {
                    getState,
                    dispatch
                });
                return next(action);

            case SYNC_USERS_SUCCESS:
                dispatchShowSuccessMessage('userSyncSuccess', {
                    getState,
                    dispatch
                });
                return next(action);

            case SYNC_USERS_FAILURE:
                dispatchShowErrorMessage('userSyncError', {
                    getState,
                    dispatch
                });
                return next(action);

            // meeting actions
            case REQUEST_MEETINGS_FAILURE:
                dispatchShowErrorMessage('meetingRequestError', {
                    getState,
                    dispatch
                });
                return next(action);

            case ADD_MEETING_SUCCESS:
                dispatchShowSuccessMessage('meetingAddSuccess', {
                    getState,
                    dispatch
                });
                return next(action);

            case ADD_MEETING_FAILURE:
                if (action.error.errorNo === 40050) {
                    message = 'meetingAddNotSupported';
                    dispatchShowWarningMessage(message, { getState, dispatch });
                } else {
                    message = 'meetingAddError';
                    dispatchShowErrorMessage(message, { getState, dispatch });
                }
                return next(action);

            case UPDATE_MEETING_SUCCESS:
                dispatchShowSuccessMessage('meetingUpdateSuccess', {
                    getState,
                    dispatch
                });
                return next(action);

            case UPDATE_MEETING_FAILURE:
                if (
                    action.error.errorNo === 40400 ||
                    action.error.errorNo === 40450
                ) {
                    message = 'meetingNotFound';
                } else {
                    message = 'meetingUpdateError';
                }

                dispatchShowErrorMessage(message, { getState, dispatch });
                return next(action);

            case DELETE_MEETING_SUCCESS:
                dispatchShowSuccessMessage('meetingDeleteSuccess', {
                    getState,
                    dispatch
                });
                return next(action);

            case DELETE_MEETING_FAILURE:
                if (action.error.errorNo === 40050) {
                    message = 'meetingDeleteNotSupported';
                    dispatchShowWarningMessage(message, { getState, dispatch });
                } else {
                    message = 'meetingDeleteError';
                    dispatchShowErrorMessage(message, { getState, dispatch });
                }
                return next(action);

            // apiKey actions
            case REQUEST_API_KEYS_FAILURE:
                dispatchShowErrorMessage('apiKeyRequestError', {
                    getState,
                    dispatch
                });
                return next(action);

            case ADD_API_KEY_SUCCESS:
                dispatchShowSuccessMessage('apiKeyAddSuccess', {
                    getState,
                    dispatch
                });
                return next(action);

            case ADD_API_KEY_FAILURE:
                dispatchShowErrorMessage('apiKeyAddError', {
                    getState,
                    dispatch
                });
                return next(action);

            case UPDATE_API_KEY_SUCCESS:
                dispatchShowSuccessMessage('apiKeyUpdateSuccess', {
                    getState,
                    dispatch
                });
                return next(action);

            case UPDATE_API_KEY_FAILURE:
                if (action.error.errorNo === 40400) {
                    message = 'apiKeyNotFound';
                } else {
                    message = 'apiKeyUpdateError';
                }

                dispatchShowErrorMessage(message, { getState, dispatch });
                return next(action);

            case DELETE_API_KEY_SUCCESS:
                dispatchShowSuccessMessage('apiKeyDeleteSuccess', {
                    getState,
                    dispatch
                });
                return next(action);

            case DELETE_API_KEY_FAILURE:
                dispatchShowErrorMessage('apiKeyDeleteError', {
                    getState,
                    dispatch
                });
                return next(action);

            // session actions
            case REQUEST_SESSIONS_FAILURE:
                dispatchShowErrorMessage('sessionRequestError', {
                    getState,
                    dispatch
                });
                return next(action);

            case PREPARE_DOWNLOAD_DENIED:
                dispatchShowWarningMessage('sessionDownloadNotAllowed', {
                    getState,
                    dispatch
                });
                return next(action);

            // adminUser actions
            case REQUEST_ADMIN_USERS_FAILURE:
                dispatchShowErrorMessage('adminUserRequestError', {
                    getState,
                    dispatch
                });
                return next(action);

            case ADD_ADMIN_USER_SUCCESS:
                dispatchShowSuccessMessage('adminUserAddSuccess', {
                    getState,
                    dispatch
                });
                return next(action);

            case ADD_ADMIN_USER_FAILURE:
                if (action.error.errorNo === 40900) {
                    message = 'adminUserConflict';
                } else {
                    message = 'adminUserAddError';
                }

                dispatchShowErrorMessage(message, { getState, dispatch });
                return next(action);

            case UPDATE_ADMIN_USER_SUCCESS:
                dispatchShowSuccessMessage('adminUserUpdateSuccess', {
                    getState,
                    dispatch
                });
                return next(action);

            case UPDATE_ADMIN_USER_FAILURE:
                if (action.error.errorNo === 40400) {
                    message = 'adminUserNotFound';
                } else if (action.error.errorNo === 40900) {
                    message = 'adminUserConflict';
                } else {
                    message = 'adminUserUpdateError';
                }

                dispatchShowErrorMessage(message, { getState, dispatch });
                return next(action);

            case DELETE_ADMIN_USER_SUCCESS:
                dispatchShowSuccessMessage('adminUserDeleteSuccess', {
                    getState,
                    dispatch
                });
                return next(action);

            case DELETE_ADMIN_USER_FAILURE:
                dispatchShowErrorMessage('adminUserDeleteError', {
                    getState,
                    dispatch
                });
                return next(action);

            // services actions
            case REQUEST_SERVICES_FAILURE:
                dispatchShowErrorMessage('serviceRequestError', {
                    getState,
                    dispatch
                });
                return next(action);

            case ADD_SERVICE_SUCCESS:
                dispatchShowSuccessMessage('serviceAddSuccess', {
                    getState,
                    dispatch
                });
                return next(action);

            case ADD_SERVICE_FAILURE:
                if (action.error.errorNo === 40900) {
                    message = 'serviceConflict';
                } else if (action.error.errorNo.toString().endsWith('50')) {
                    if (action.error.message.startsWith('liveswitch')) {
                        message = 'serviceWebRtcError';
                    } else {
                        message = 'serviceAddError';
                    }
                } else {
                    message = 'serviceAddError';
                }

                dispatchShowErrorMessage(message, { getState, dispatch });
                return next(action);

            case UPDATE_SERVICE_SUCCESS:
                dispatchShowSuccessMessage('serviceUpdateSuccess', {
                    getState,
                    dispatch
                });
                return next(action);

            case UPDATE_SERVICE_FAILURE:
                if (action.error.errorNo === 40400) {
                    message = 'serviceNotFound';
                } else {
                    message = 'serviceUpdateError';
                }

                dispatchShowErrorMessage(message, { getState, dispatch });
                return next(action);

            case DELETE_SERVICE_SUCCESS:
                dispatchShowSuccessMessage('serviceDeleteSuccess', {
                    getState,
                    dispatch
                });
                return next(action);

            case DELETE_SERVICE_FAILURE:
                dispatchShowErrorMessage('serviceDeleteError', {
                    getState,
                    dispatch
                });
                return next(action);

            default:
                return next(action);
        }
    };
};
