export const REQUEST_ADMIN_USERS = 'REQUEST_ADMIN_USERS';
export const REQUEST_ADMIN_USERS_SUCCESS = 'REQUEST_ADMIN_USERS_SUCCESS';
export const REQUEST_ADMIN_USERS_FAILURE = 'REQUEST_ADMIN_USERS_FAILURE';

export const ADD_ADMIN_USER = 'ADD_ADMIN_USER';
export const ADD_ADMIN_USER_SUCCESS = 'ADD_ADMIN_USER_SUCCESS';
export const ADD_ADMIN_USER_FAILURE = 'ADD_ADMIN_USER_FAILURE';

export const UPDATE_ADMIN_USER = 'UPDATE_ADMIN_USER';
export const UPDATE_ADMIN_USER_SUCCESS = 'UPDATE_ADMIN_USER_SUCCESS';
export const UPDATE_ADMIN_USER_FAILURE = 'UPDATE_ADMIN_USER_FAILURE';

export const DELETE_ADMIN_USER = 'DELETE_ADMIN_USER';
export const DELETE_ADMIN_USER_SUCCESS = 'DELETE_ADMIN_USER_SUCCESS';
export const DELETE_ADMIN_USER_FAILURE = 'DELETE_ADMIN_USER_FAILURE';

export const START_EDITING_ADMIN_USER = 'START_EDITING_ADMIN_USER';
export const STOP_EDITING_ADMIN_USER = 'STOP_EDITING_ADMIN_USER';
export const EDIT_ADMIN_USER = 'EDIT_ADMIN_USER';
export const ADD_ADMIN_USER_VALIDATION = 'ADD_ADMIN_USER_VALIDATION';

export const RESET_ADMIN_USER_PAGE = 'RESET_ADMIN_USER_PAGE';
