import * as types from './actionTypes';
import {storeToSessionStorage, log} from '../util/helpers';
import {hasLanguage} from './translations';
import authHelper from '../util/authHelper';
import {isAdminUser} from "../util/accessControl";

export function changeLanguage(language) {
    return async (dispatch, getState, api) => {
        // check if this language is available
        if (hasLanguage(language) && language !== getState().i18n.language) {
            dispatch({type: types.CHANGE_LANGUAGE, language});

            const user = authHelper.getUser();
            storeToSessionStorage('user', JSON.stringify({...user, locale: language}));

            if (isAdminUser(user)) {
                const response = await api.updateAdminUser(
                    user._id,
                    {locale: language},
                    authHelper.getToken()
                );

                if (!response.error) {
                    log.debug(`updated admin user's locale: ${response.adminUser.locale}`);
                }

            } else {
                const service = getState().auth.service;
                if (service) {
                    const response = await api.updateUser(
                        service._id,
                        user._id,
                        {locale: language},
                        authHelper.getToken()
                    );

                    if (!response.error) {
                        log.debug(`updated user's locale: ${response.user.locale}`);
                    }
                }
            }
        }
    };
}
