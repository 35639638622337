export const REQUEST_MEETINGS = 'REQUEST_MEETINGS';
export const REQUEST_MEETINGS_SUCCESS = 'REQUEST_MEETINGS_SUCCESS';
export const REQUEST_MEETINGS_FAILURE = 'REQUEST_MEETINGS_FAILURE';

export const ADD_MEETING = 'ADD_MEETING';
export const ADD_MEETING_SUCCESS = 'ADD_MEETING_SUCCESS';
export const ADD_MEETING_FAILURE = 'ADD_MEETING_FAILURE';

export const UPDATE_MEETING = 'UPDATE_MEETING';
export const UPDATE_MEETING_SUCCESS = 'UPDATE_MEETING_SUCCESS';
export const UPDATE_MEETING_FAILURE = 'UPDATE_MEETING_FAILURE';

export const DELETE_MEETING = 'DELETE_MEETING';
export const DELETE_MEETING_SUCCESS = 'DELETE_MEETING_SUCCESS';
export const DELETE_MEETING_FAILURE = 'DELETE_MEETING_FAILURE';

export const START_EDITING_MEETING = 'START_EDITING_MEETING';
export const STOP_EDITING_MEETING = 'STOP_EDITING_MEETING';
export const EDIT_MEETING = 'EDIT_MEETING';
export const ADD_MEETING_VALIDATION = 'ADD_MEETING_VALIDATION';

export const RESET_MEETINGS_PAGE = 'RESET_MEETINGS_PAGE';
