import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
//import TranslatedString from '../base/i18n/TranslatedString';

class NoContentPage extends PureComponent {
    render() {
        return <div>Nothing to show :(</div>;
    }
}

export default connect(
    undefined,
    undefined
)(NoContentPage);
