const routes = {
    HOME: '/',

    // service routes
    SERVICE_LOGIN: '/:serviceId',
    SERVICE_USERS: '/:serviceId/service/users',
    SERVICE_MEETINGS: '/:serviceId/service/meetings',
    SERVICE_TOPICS: '/:serviceId/service/topics',
    SERVICE_SESSIONS: '/:serviceId/analytics/sessions',
    SERVICE_REVISION_VIEWER: '/:serviceId/analytics/revision-viewer',

    // admin routes
    ADMIN_LOGIN: '/admin',
    USERS: '/service/users',
    MEETINGS: '/service/meetings',
    TOPICS: '/service/topics',
    API_KEYS: '/service/apikeys',
    SESSIONS: '/analytics/sessions',
    REVISION_VIEWER: '/analytics/revision-viewer',
    ADMIN_USERS: '/admin/users',
    SERVICES: '/admin/services',
    LIVE_STATS: '/admin/live-stats',
    MAINTENANCE_WINDOWS: '/admin/maintenance-windows'
};
export default routes;
