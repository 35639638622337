import Routes from '../../../constants/routes';

const menuAccessRules = {
    // administration
    users: ['admin', 'restricted-admin', 'service'],
    meetings: ['admin', 'restricted-admin', 'meeting'],
    topics: ['admin', 'restricted-admin', 'service'],
    apiKeys: ['admin'],

    // analytics
    sessions: ['admin', 'restricted-admin', 'analytics'],
    revisionViewer: ['admin', 'restricted-admin', 'analytics'],

    // sysAdmin
    adminUsers: ['admin'],
    services: ['admin', 'restricted-admin'],
    liveStats: ['admin', 'restricted-admin'],
    maintenanceWindows: ['admin', 'restricted-admin']
};

/**
 * check whether a user with 'roles' has access to 'menuItem'
 * @param roles - array of roles of a user
 * @param menuItem
 * @returns {*}
 */
export const hasMenuAccess = (roles, menuItem) => {
    return (roles || []).reduce((hasAccess, role) => {
        if (menuAccessRules.hasOwnProperty(menuItem)) {
            return menuAccessRules[menuItem].includes(role) || hasAccess;
        } else {
            return false;
        }
    }, false);
};

let routeAccessRules = {
    admin: {
        HOME: 'redirect',
        SERVICE_LOGIN: 'redirect',
        ADMIN_LOGIN: 'redirect',

        SERVICE_USERS: 'redirect',
        SERVICE_MEETINGS: 'redirect',
        SERVICE_TOPICS: 'redirect',
        SERVICE_SESSIONS: 'redirect',

        USERS: ['admin', 'restricted-admin'],
        MEETINGS: ['admin', 'restricted-admin'],
        TOPICS: ['admin', 'restricted-admin'],
        API_KEYS: ['admin'],
        SESSIONS: ['admin', 'restricted-admin'],
        REVISION_VIEWER: ['admin', 'restricted-admin'],
        ADMIN_USERS: ['admin'],
        SERVICES: ['admin', 'restricted-admin'],
        LIVE_STATS: ['admin', 'restricted-admin'],
        MAINTENANCE_WINDOWS: ['admin', 'restricted-admin']
    },
    service: {
        HOME: 'redirect',
        SERVICE_LOGIN: 'redirect',
        ADMIN_LOGIN: 'redirect',

        SERVICE_USERS: ['service'],
        SERVICE_MEETINGS: ['meeting'],
        SERVICE_TOPICS: ['service'],
        SERVICE_SESSIONS: ['analytics'],
        SERVICE_REVISION_VIEWER: ['analytics'],

        USERS: 'redirect',
        MEETINGS: 'redirect',
        TOPICS: 'redirect',
        API_KEYS: ['admin'],
        SESSIONS: 'redirect',
        ADMIN_USERS: ['admin'],
        SERVICES: ['admin', 'restricted-admin'],
        LIVE_STATS: ['admin', 'restricted-admin'],
        MAINTENANCE_WINDOWS: ['admin', 'restricted-admin']
    }
};

/**
 * set access rule for a route
 * @param isAdmin - true if admin access rule should be changed
 * @param route - route to change access rule for
 * @param newRoles - new roles for access rule
 */
export const setRouteAccess = (isAdmin, route, newRoles) => {
    let accessRules = isAdmin
        ? routeAccessRules.admin
        : routeAccessRules.service;

    if (accessRules.hasOwnProperty(route)) {
        accessRules[route] = newRoles;
    }
};

/**
 * check whether a user with 'roles' has access to 'route' or if the user should be redirected
 * @param isAdmin
 * @param roles - array of roles of a user
 * @param route
 * @returns {string}
 */
export const hasRouteAccess = (isAdmin, roles, route) => {
    const accessRules = isAdmin
        ? routeAccessRules.admin
        : routeAccessRules.service;

    if (accessRules[route] === 'redirect') {
        return 'redirect';
    } else {
        if (
            (roles || []).reduce((hasAccess, role) => {
                return (
                    (accessRules[route] && accessRules[route].includes(role)) ||
                    hasAccess
                );
            }, false)
        ) {
            return 'hasAccess';
        } else {
            return 'noAccess';
        }
    }
};

/**
 * check whether a route is an admin route
 * @param path - route to check
 * @returns {boolean} - returns true if route is an admin route
 */
export const isAdminRoute = path => {
    return (
        path === Routes.ADMIN_LOGIN ||
        path === Routes.USERS ||
        path === Routes.MEETINGS ||
        path === Routes.TOPICS ||
        path === Routes.API_KEYS ||
        path === Routes.SESSIONS ||
        path === Routes.REVISION_VIEWER ||
        path === Routes.ADMIN_USERS ||
        path === Routes.SERVICES ||
        path === Routes.LIVE_STATS ||
        path === Routes.MAINTENANCE_WINDOWS
    );
};

/**
 * check whether a user is an admin
 * @param user
 * @returns {boolean}
 */
export const isAdminUser = user => {
    return !!(
        user &&
        Array.isArray(user.roles) &&
        (user.roles.includes('admin') ||
            user.roles.includes('restricted-admin'))
    );
};

/**
 * check whether a user has a certain role at least one of the roles (in case role is an array)
 * @param user
 * @param role
 * @returns {boolean|*}
 */
export const hasRole = (user, role) => {
    if (!user || !Array.isArray(user.roles)) {
        return false;
    }

    return Array.isArray(role)
        ? user.roles.some(r => role.includes(r))
        : user.roles.includes(role);
};
