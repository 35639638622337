import * as types from './actionTypes';

export const initialState = {
    isLoading: false,
    isEditing: false,
    topicToAdd: undefined,
    addValidationSet: {},
    topicToEdit: undefined,
    updatedFields: undefined,
    editValidationSet: {},
    topics: [],
    contentRange: undefined,
    error: undefined
};

/**
 *
 * @param state
 * @param action
 * @returns new state
 */
export default function topics(state = initialState, action) {
    switch (action.type) {
        case types.REQUEST_TOPICS:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.REQUEST_TOPICS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                topics: action.topics || [],
                contentRange: action.contentRange
            };
        case types.REQUEST_TOPICS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case types.ADD_TOPIC:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.ADD_TOPIC_SUCCESS:
            return {
                ...state,
                isLoading: false,
                topicToAdd: undefined,
                addValidationSet: {}
            };
        case types.ADD_TOPIC_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case types.UPDATE_TOPIC:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.UPDATE_TOPIC_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isEditing: false,
                topicToEdit: undefined,
                updatedFields: undefined,
                editValidationSet: {}
            };
        case types.UPDATE_TOPIC_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case types.DELETE_TOPIC:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case types.DELETE_TOPIC_SUCCESS:
            let newState = {
                ...state,
                isLoading: false
            };
            if (state.isEditing && state.topicToEdit._id === action.topicId) {
                newState.isEditing = false;
                newState.topicToEdit = undefined;
                newState.editValidationSet = {};
            }
            return newState;
        case types.DELETE_TOPIC_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case types.START_EDITING_TOPIC:
            return {
                ...state,
                isEditing: true,
                topicToEdit: action.topicData,
                updatedFields: {}
            };
        case types.STOP_EDITING_TOPIC:
            return {
                ...state,
                isEditing: false,
                topicToEdit: undefined,
                updatedFields: undefined,
                editValidationSet: {}
            };
        case types.EDIT_TOPIC:
            if (state.isEditing) {
                return {
                    ...state,
                    topicToEdit: {
                        ...action.currentFields,
                        [action.update.field]: action.update.value
                    },
                    updatedFields: {
                        ...state.updatedFields,
                        [action.update.field]: true
                    }
                };
            } else {
                return {
                    ...state,
                    topicToAdd: {
                        ...action.currentFields,
                        [action.update.field]: action.update.value
                    }
                };
            }
        case types.ADD_TOPIC_VALIDATION:
            if (state.isEditing) {
                return {
                    ...state,
                    editValidationSet: {
                        ...state.editValidationSet,
                        [action.validationType]: true
                    }
                };
            } else {
                return {
                    ...state,
                    addValidationSet: {
                        ...state.addValidationSet,
                        [action.validationType]: true
                    }
                };
            }
        case types.RESET_TOPICS_PAGE:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
