import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import '../../css/base/Dialog.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { getTranslatedString } from './i18n/translations';
import TranslatedString from './i18n/TranslatedString';

class DeleteDialog extends PureComponent {
    confirmLogout = _ => this.props.handleLogoutConfirm(this.props.user);

    cancelLogout = _ => this.props.handleLogoutCancel();

    render() {
        const logoutDialogHeader = <TranslatedString id={this.props.header} />;
        const logoutDialogFooter = (
            <div>
                <Button
                    className="button confirm"
                    label={getTranslatedString(this.props.language, 'yes')}
                    icon="pi pi-check"
                    onClick={this.confirmLogout}
                />
                <Button
                    className="button cancel"
                    label={getTranslatedString(this.props.language, 'no')}
                    icon="pi pi-times"
                    onClick={this.cancelLogout}
                />
            </div>
        );

        return (
            <React.Fragment>
                <Dialog
                    className="dialog"
                    visible={this.props.visible}
                    focusOnShow={false}
                    closeOnEscape={true}
                    closable={false}
                    header={logoutDialogHeader}
                    footer={logoutDialogFooter}
                    onHide={_ => {}}
                >
                    <TranslatedString id={this.props.dialog} />
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        language: state.i18n.language,
        visible: ownProps.visible,
        header: ownProps.header,
        dialog: ownProps.dialog,
        user: ownProps.user,
        handleLogoutConfirm: ownProps.onLogoutConfirm,
        handleLogoutCancel: ownProps.onLogoutCancel
    };
};

export default connect(
    mapStateToProps,
    undefined
)(DeleteDialog);
