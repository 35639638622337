import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    addApiKey,
    addApiKeyValidation,
    deleteApiKey,
    editApiKey,
    requestApiKeys,
    resetApiKeysPage,
    startEditingApiKey,
    stopEditingApiKey,
    updateApiKey
} from './actions';

import classNames from 'classnames';
import '../../css/features/ApiKeys.scss';

import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

import CustomInputTextarea from '../base/CustomInputTextarea';
import EditButtons from '../base/EditButtons';
import DeleteDialog from '../base/DeleteDialog';
import FormButtons from '../base/FormButtons';
import TranslatedString from '../base/i18n/TranslatedString';
import { getTranslatedString } from '../base/i18n/translations';
import { Checkbox } from 'primereact/checkbox';

export class ApiKeys extends Component {
    constructor(props) {
        super(props);

        this.rows = 25;
        this.defaultApiKeyFields = {
            title: '',
            ip: '',
            roles: ['meeting'],
            token: '',
            active: true
        };

        this.state = {
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
        this.handleEditForm = this.handleEditForm.bind(this);
        this.handleAddValidation = this.handleAddValidation.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    componentDidMount() {
        this.props.requestApiKeys({
            range: { startIndex: 0, endIndex: this.rows - 1 }
        });
    }

    componentDidUpdate(prevProps) {
        // reset apiKeys page if the service was changed
        if (prevProps.service !== this.props.service && this.props.service) {
            this.props.resetApiKeysPage(true, {
                range: {
                    startIndex: 0,
                    endIndex: this.rows - 1
                }
            });

            this.setState({
                showDeleteDialog: false,
                itemToDeleteId: undefined,
                itemToDeleteName: undefined
            });
        }
    }

    componentWillUnmount() {
        this.props.resetApiKeysPage();
    }

    handlePageClick(event) {
        this.props.requestApiKeys({
            range: {
                startIndex: event.first,
                endIndex: event.first + this.rows - 1
            }
        });
    }

    handleEditClick(apiKeyId) {
        if (this.props.isEditing && apiKeyId === this.props.apiKeyToEdit._id) {
            this.props.stopEditingApiKey();
        } else {
            //find apiKey object with matching apiKeyId to edit
            const apiKey = this.props.apiKeys.find(item => {
                return item._id === apiKeyId;
            });

            //build apiKey object with using given property values or default values if necessary
            let apiKeyData = { _id: apiKey._id };
            Object.keys(this.defaultApiKeyFields).forEach(prop => {
                if (apiKey.hasOwnProperty(prop)) {
                    apiKeyData[prop] = apiKey[prop];
                } else {
                    apiKeyData[prop] = this.defaultApiKeyFields[prop];
                }
            });

            this.props.startEditingApiKey(apiKeyData);
        }
    }

    handleDeleteClick(apiKeyId, apiKeyTitle) {
        this.setState({
            showDeleteDialog: true,
            itemToDeleteId: apiKeyId,
            itemToDeleteName: apiKeyTitle
        });
    }

    handleDeleteConfirm() {
        const apiKeys = this.props.apiKeys;
        let currentItems = { ...this.props.contentRange.currentItems };

        // if the item to be deleted is the last one on the currently selected table page, request the items for the previous table page
        if (
            currentItems.startIndex === currentItems.endIndex &&
            apiKeys[apiKeys.length - 1]._id === this.state.itemToDeleteId
        ) {
            currentItems = {
                startIndex: currentItems.startIndex - this.rows,
                endIndex: currentItems.startIndex - 1
            };
            if (currentItems.startIndex < 0) {
                currentItems = undefined;
            }
        }

        this.props.deleteApiKey(this.state.itemToDeleteId, {
            range: currentItems
        });
        this.setState({
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        });
    }

    handleDeleteCancel() {
        this.setState({
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        });
    }

    handleEditForm(currentFields, update) {
        this.props.editApiKey(currentFields, update);
    }

    handleAddValidation(validationType) {
        const validationSet = this.props.isEditing
            ? this.props.editValidationSet
            : this.props.addValidationSet;
        if (!validationSet.hasOwnProperty(validationType)) {
            this.props.addApiKeyValidation(validationType);
        }
    }

    handleSaveClick() {
        let currentItems;
        if (this.props.contentRange) {
            currentItems = { ...this.props.contentRange.currentItems };
        }

        if (this.props.isEditing) {
            this.props.updateApiKey(this.props.apiKeyToEdit._id, {
                range: currentItems
            });
        } else {
            if (currentItems) {
                currentItems.endIndex = currentItems.startIndex + this.rows - 1;
            }
            this.props.addApiKey({ range: currentItems });
        }
    }

    handleCancelClick() {
        this.props.stopEditingApiKey();
    }

    render() {
        return (
            <React.Fragment>
                <div className="p-grid">
                    <div className="p-col-12 p-xl-8">
                        <ApiKeysTable
                            isLoading={this.props.isLoading}
                            editedRowId={
                                this.props.isEditing
                                    ? this.props.apiKeyToEdit._id
                                    : undefined
                            }
                            apiKeys={this.props.apiKeys}
                            language={this.props.language}
                            rows={this.rows}
                            contentRange={this.props.contentRange}
                            onPageClick={this.handlePageClick}
                            onEditClick={this.handleEditClick}
                            onDeleteClick={this.handleDeleteClick}
                        />
                    </div>

                    <div className="p-col-12 p-xl-4">
                        <ApiKeyForm
                            isLoading={this.props.isLoading}
                            isEditing={this.props.isEditing}
                            apiKeyFormFields={
                                (this.props.isEditing
                                    ? this.props.apiKeyToEdit
                                    : this.props.apiKeyToAdd) ||
                                this.defaultApiKeyFields
                            }
                            validationSet={
                                this.props.isEditing
                                    ? this.props.editValidationSet
                                    : this.props.addValidationSet
                            }
                            language={this.props.language}
                            onEditForm={this.handleEditForm}
                            onAddValidation={this.handleAddValidation}
                            onSaveClick={this.handleSaveClick}
                            onCancelClick={this.handleCancelClick}
                        />
                    </div>
                </div>

                <DeleteDialog
                    visible={this.state.showDeleteDialog}
                    header={'apiKeyDeleteHeader'}
                    dialog={'apiKeyDeleteDialog'}
                    itemToDelete={this.state.itemToDeleteName}
                    onDeleteConfirm={this.handleDeleteConfirm}
                    onDeleteCancel={this.handleDeleteCancel}
                />
            </React.Fragment>
        );
    }
}

class ApiKeysTable extends Component {
    render() {
        const apiKeyTableEntries = this.props.apiKeys.map((item, key) => {
            let apiKey = { ...item };

            apiKey.roles = apiKey.roles.join(', ');

            if (apiKey.hasOwnProperty('active')) {
                apiKey.active = apiKey.active ? (
                    <span className="pi-md-check icon active" key={key} />
                ) : (
                    <span className="pi-md-block icon inactive" key={key} />
                );
            }

            apiKey.editApiKeyButtons = (
                <EditButtons
                    key={key}
                    id={apiKey._id}
                    name={apiKey.title}
                    isLoading={this.props.isLoading}
                    onEditClick={this.props.onEditClick}
                    onDeleteClick={this.props.onDeleteClick}
                />
            );

            return apiKey;
        });

        return (
            <Card title={getTranslatedString(this.props.language, 'apiKeys')}>
                <DataTable
                    className="table"
                    autoLayout={true}
                    responsive={true}
                    value={apiKeyTableEntries}
                    rows={this.props.rows}
                    rowClassName={rowData => {
                        return {
                            'row-bg':
                                rowData &&
                                rowData._id === this.props.editedRowId
                        };
                    }}
                    paginator={true}
                    paginatorPosition={'top'}
                    alwaysShowPaginator={true}
                    lazy={true}
                    totalRecords={
                        this.props.contentRange
                            ? this.props.contentRange.totalItems
                            : undefined
                    }
                    first={
                        this.props.contentRange
                            ? this.props.contentRange.currentItems.startIndex
                            : 0
                    }
                    onPage={this.props.onPageClick}
                >
                    <Column
                        className="column title-col"
                        field="title"
                        header={<TranslatedString id={'apiKeyTableTitle'} />}
                    />
                    <Column
                        className="column ip-col"
                        field="ip"
                        header={<TranslatedString id={'apiKeyTableIp'} />}
                    />
                    <Column
                        className="column roles-col"
                        field="roles"
                        header={<TranslatedString id={'apiKeyTableRoles'} />}
                    />
                    <Column
                        className="column active-col"
                        field="active"
                        header={<TranslatedString id={'apiKeyTableActive'} />}
                    />
                    <Column
                        className="column edit-buttons-col"
                        field="editApiKeyButtons"
                    />
                </DataTable>
            </Card>
        );
    }
}

class ApiKeyForm extends Component {
    constructor(props) {
        super(props);

        this.validators = {
            title: title => {
                //any non-empty title is valid
                return title !== '';
            },
            roles: roles => {
                //at least one role has to be specified
                return roles.length > 0;
            }
        };
    }

    /**
     * onChange event handler input field
     * @param fieldId - property name of input field
     * @returns {Function}
     */
    handleOnChange = fieldId => e => {
        this.props.onEditForm(this.props.apiKeyFormFields, {
            field: fieldId,
            value: e.target.value
        });
    };

    /**
     * onBlur event handler for input field
     * @param fieldId - property name of input field
     * @returns {Function}
     */
    handleOnBlur = fieldId => () => {
        this.props.onAddValidation(fieldId);
    };

    /**
     * validate apiKey form fields
     * @param apiKeyFormFields
     * @returns {{invalidInputFields: {date: boolean, title: boolean}, invalidInputs: (boolean|*)}}
     */
    validateFormFields(apiKeyFormFields) {
        const invalidInputFields = {
            title: !this.validators.title(apiKeyFormFields.title),
            roles: !this.validators.roles(apiKeyFormFields.roles)
        };
        return {
            invalidInputFields: invalidInputFields,
            invalidInputs: Object.keys(invalidInputFields).reduce(
                (acc, curr) => acc || invalidInputFields[curr],
                false
            )
        };
    }

    renderApiKeyInputFields(invalidInputFields) {
        const apiKeyFormFields = this.props.apiKeyFormFields;
        const validationSet = this.props.validationSet;

        return (
            <React.Fragment>
                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className={classNames('form-input', {
                                'p-error':
                                    invalidInputFields.title &&
                                    validationSet.hasOwnProperty('title')
                            })}
                            value={apiKeyFormFields.title}
                            onChange={this.handleOnChange('title')}
                            onBlur={this.handleOnBlur('title')}
                        />
                        <label>
                            <TranslatedString id={'apiKeyTitle'} />
                        </label>
                    </span>
                </div>

                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className={'form-input '}
                            value={apiKeyFormFields.ip}
                            onChange={this.handleOnChange('ip')}
                        />
                        <label>
                            <TranslatedString id={'apiKeyIp'} />
                        </label>
                    </span>
                </div>

                <div className="p-col-12">
                    <MultiSelect
                        className={classNames('form-input', {
                            'multiselect-error':
                                invalidInputFields.roles &&
                                validationSet.hasOwnProperty('roles')
                        })}
                        value={apiKeyFormFields.roles}
                        options={[
                            { label: 'service', value: 'service' },
                            {
                                label: 'analytics',
                                value: 'analytics'
                            },
                            {
                                label: 'meeting',
                                value: 'meeting'
                            }
                        ]}
                        onChange={this.handleOnChange('roles')}
                        onBlur={this.handleOnBlur('roles')}
                    />
                    <label className="dropdown-label-float">
                        <TranslatedString id={'apiKeyRoles'} />
                    </label>
                </div>

                {apiKeyFormFields.token && (
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <CustomInputTextarea
                                language={this.props.language}
                                className={classNames(
                                    'form-input',
                                    'input-textarea'
                                )}
                                value={apiKeyFormFields.token}
                                rows={10}
                                readOnly={true}
                                label={<TranslatedString id={'apiKeyToken'} />}
                            />
                        </span>
                    </div>
                )}

                <div className="p-col-12">
                    <Checkbox
                        value={!apiKeyFormFields.active}
                        checked={apiKeyFormFields.active}
                        onChange={this.handleOnChange('active')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'apiKeyActive'} />
                    </label>
                </div>
            </React.Fragment>
        );
    }

    render() {
        const { invalidInputFields, invalidInputs } = this.validateFormFields(
            this.props.apiKeyFormFields
        );
        const apiKeyInputFields = this.renderApiKeyInputFields(
            invalidInputFields
        );

        return (
            <Card
                title={getTranslatedString(
                    this.props.language,
                    this.props.isEditing ? 'apiKeyEdit' : 'apiKeyAdd'
                )}
            >
                <br />
                <div className="p-grid form-group">
                    {apiKeyInputFields}
                    <div className="p-col-12">
                        <FormButtons
                            invalidInputs={invalidInputs}
                            isLoading={this.props.isLoading}
                            isEditing={this.props.isEditing}
                            onSaveClick={this.props.onSaveClick}
                            onCancelClick={this.props.onCancelClick}
                        />
                    </div>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.apiKeys.isLoading,
        isEditing: state.apiKeys.isEditing,
        apiKeyToAdd: state.apiKeys.apiKeyToAdd,
        addValidationSet: state.apiKeys.addValidationSet,
        apiKeyToEdit: state.apiKeys.apiKeyToEdit,
        editValidationSet: state.apiKeys.editValidationSet,
        apiKeys: state.apiKeys.apiKeys,
        contentRange: state.apiKeys.contentRange,

        service: state.auth.service,
        language: state.i18n.language
    };
};

const mapDispatchToProps = {
    requestApiKeys,
    addApiKey,
    updateApiKey,
    deleteApiKey,
    startEditingApiKey,
    stopEditingApiKey,
    editApiKey,
    addApiKeyValidation,
    resetApiKeysPage
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApiKeys);
