import * as types from './actionTypes';
import authHelper from '../base/util/authHelper';
import { handleError, log } from '../base/util/helpers';
import { forceLogout, noServiceFound } from '../auth/actions';

function convertSessionProperties(sessionData, dataFromServer) {
    if (dataFromServer) {
        sessionData.forEach(session => {
            // convert unix timestamps to dates
            session.startTime = new Date(session.startTime);
            session.stopTime = new Date(session.stopTime);
            session.meeting.date = isNaN(session.meeting.date)
                ? null
                : new Date(session.meeting.date);
            session.meeting.durationInMinutes = isNaN(
                session.meeting.durationInMinutes
            )
                ? null
                : session.meeting.durationInMinutes;
        });
    }
}

/*
 * action creators
 */
function requestSessionsRequest() {
    return { type: types.REQUEST_SESSIONS };
}

function requestSessionsSuccess(sessions, contentRange) {
    return { type: types.REQUEST_SESSIONS_SUCCESS, sessions, contentRange };
}

function requestExportDataSuccess(exportData) {
    return { type: types.REQUEST_EXPORT_DATA_SUCCESS, exportData };
}

function requestSessionsFailure(error) {
    return { type: types.REQUEST_SESSIONS_FAILURE, error };
}

/*
 * thunk
 */
export function requestSessions(
    { fields, sort, range, filters },
    exportData = false
) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(requestSessionsRequest());
            const response = await api.getSessions(
                service._id,
                fields,
                sort,
                range,
                filters,
                authHelper.getToken()
            );
            if (!response.error) {
                if (exportData) {
                    log.debug('[requestExportData]', response.sessions);
                    dispatch(requestExportDataSuccess(response.sessions));
                } else {
                    convertSessionProperties(response.sessions, true);
                    log.debug('[requestSessions]', response.sessions);
                    dispatch(
                        requestSessionsSuccess(
                            response.sessions,
                            response.contentRange
                        )
                    );
                }
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error => dispatch(requestSessionsFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

/*
 * action creators
 */
function prepareRecordingsDownloadRequest() {
    return { type: types.PREPARE_DOWNLOAD };
}

function prepareRecordingsDownloadProgress(progress) {
    return { type: types.PREPARE_DOWNLOAD_PROGRESS, progress };
}

function prepareRecordingsDownloadSuccess(downloadLink) {
    return { type: types.PREPARE_DOWNLOAD_SUCCESS, downloadLink };
}

function prepareRecordingsDownloadFailure(error) {
    return { type: types.PREPARE_DOWNLOAD_FAILURE, error };
}

export function prepareRecordingsDownloadDenied() {
    return { type: types.PREPARE_DOWNLOAD_DENIED };
}

/*
 * thunk
 */
export function prepareRecordingsDownload(sessionId, audioOnly = false) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(prepareRecordingsDownloadRequest());

            const response = await api.prepareRecordings(
                service._id,
                sessionId,
                audioOnly,
                authHelper.getToken()
            );
            if (!response.error) {
                if (response.progress < 100) {
                    dispatch(
                        prepareRecordingsDownloadProgress(response.progress)
                    );
                } else {
                    log.debug('Download Link', response.download);

                    const downloadLink = `${service.webRtcUrl ||
                        service['webRtc.url']}${response.download}`;
                    dispatch(prepareRecordingsDownloadSuccess(downloadLink));
                }
            } else {
                handleError(response.error, {
                    fatal: error => dispatch(forceLogout(error)),
                    nonFatal: error =>
                        dispatch(prepareRecordingsDownloadFailure(error))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

export function resetRecordingsDownload() {
    return { type: types.RESET_RECORDINGS_DOWNLOAD };
}

/*
 * action creators
 */
export function resetExportData() {
    return { type: types.RESET_EXPORT_DATA };
}

export function startEditingSession(sessionData) {
    return { type: types.START_EDITING_SESSION, sessionData };
}

export function stopEditingSession() {
    return { type: types.STOP_EDITING_SESSION };
}

function resetPage() {
    return { type: types.RESET_SESSION_PAGE };
}

/*
 * thunk
 */
export function resetSessionsPage(
    loadSessions = false,
    { fields, sort, range, filters } = {}
) {
    return async dispatch => {
        dispatch(resetPage());
        if (loadSessions) {
            dispatch(requestSessions({ fields, sort, range, filters }));
        }
    };
}
