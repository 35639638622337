import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../../css/forms.scss';
import { Chips } from 'primereact/chips';

class CustomChips extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasValues: Boolean(props.value.length),
            focus: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        //update hasValues flag if new values are loaded
        const hasValues = Boolean(props.value.length);
        if (hasValues !== state.hasValues) {
            return { hasValues: hasValues };
        }
        return null;
    }

    handleChange = e => {
        this.props.onChange(e);
        if (e.target.value && e.target.value.length) {
            if (!this.state.hasValues) {
                this.setState({ hasValues: true });
            }
        } else {
            if (this.state.hasValues) {
                this.setState({ hasValues: false });
            }
        }
    };

    handleFocus = () => {
        this.setState({ focus: true });
    };

    handleBlur = () => {
        this.setState({ focus: false });
    };

    render() {
        const floatingLabel = this.state.focus || this.state.hasValues;

        return (
            <div onFocus={this.handleFocus} onBlur={this.handleBlur}>
                <label
                    className={
                        floatingLabel ? 'chips-label float' : 'chips-label'
                    }
                >
                    {this.props.label}
                </label>
                <Chips value={this.props.value} onChange={this.handleChange} />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        language: state.i18n.language,
        value: ownProps.value,
        onChange: ownProps.onChange,
        label: ownProps.label
    };
};

export default connect(
    mapStateToProps,
    undefined
)(CustomChips);
