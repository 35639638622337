import { SUPPORTED_ADIAPAD_TILES } from '../../../../constants/constants';

export const italian = {
    language: 'Lingua',

    de: 'Tedesco',
    en: 'Inglese',
    fr: 'Francese',
    it: 'Italiano',

    // ServiceInputPage
    next: 'Continua',

    // LoginPage
    applicationName: 'Unblu Meet - Manager',
    serviceInputFormHeader: 'Service',
    loginFormHeader: 'Login',
    username: 'Nome utente',
    password: 'Password',
    serviceId: 'Service-ID',
    captcha: 'Captcha',
    signIn: 'Login',

    edit: 'Modifica',
    save: 'Salva',
    confirm: 'Conferma',
    cancel: 'Annulla',
    ok: 'OK',
    yes: 'Si',
    no: 'No',
    download: 'Scarica',
    notAvailable: 'Non disponibile',

    excelExportHeader: 'Excel Export',
    excelExportLoadingMessage:
        'Un momento per favore, il suo download è in fase di preparazione.',
    excelExportDownloadMessage:
        'Completato. Ora è possibile iniziare il download.',
    excelExportErrorMessage: 'Oops! Si è verificato un errore.',

    // ADMINISTRATION
    administration: 'AMMINISTRAZIONE',

    // Service Users
    users: 'Utente',
    usersSearch: 'Cerca utenti',

    userTableId: 'ID-Utente',
    userTableAdditionalId: 'ID aggiuntivo',
    userTableName: 'Nome',
    userTableUsername: 'Nome utente',
    userTableRoles: 'Ruoli',
    userAdd: 'Aggiungere utente',
    userEdit: 'Modificare utente',
    userDeleteHeader: 'Eliminare utente',
    userDeleteDialog: 'Vuole davvero eliminare questo utente?',
    userGeneralSettings: 'IS THIS STILL USED?',
    userId: 'ID-Utente',
    userUsername: 'Nome utente *',
    userName: 'Nome *',
    userRoles: 'Ruoli *',
    userAdditionalId: 'ID aggiuntivo',
    userPassword: 'Password *',
    userConfirmPassword: 'Confermare password *',
    userNewPassword: 'Nuova password',
    userConfirmNewPassword: 'Confermare la nuova password',
    userIdTooltip:
        'Opzionale, es. indirizzo e-mail o numero univoco del collaboratore',

    // Meetings
    meetings: 'Meetings',
    meetingTableDate: 'Data',
    meetingTableTitle: 'Titolo',
    meetingTableDescription: 'Descrizione',
    meetingTableParticipants: 'Partecipanti',
    meetingTableState: 'Stato',
    meetingAdd: 'Aggiungere un nuovo meeting',
    meetingEdit: 'Modificare il meeting',
    meetingDeleteHeader: 'Eliminare il meeting?',
    meetingDeleteDialog: 'Vuole davvero eliminare questo meeting?',
    meetingGeneral: 'Impostazioni generali',
    meetingDate: 'Data *',
    meetingTitle: 'Titolo *',
    meetingDescription: 'Descrizione',
    meetingDuration: 'Durata (in minuti)',
    meetingLink: 'Link',
    meetingType: 'Tipo',
    meetingState: 'Stato',
    meetingStateOpen: 'apero',
    meetingStateOngoing: 'in corso',
    meetingStateCompleted: 'completato',
    meetingStateArchived: 'archiviato',
    meetingStateDeleted: 'eliminato',
    meetingAdditionalMetadata: 'Metadati aggiuntivi',
    meetingAdditionalMetadataLabel: 'Proprietà',
    meetingAdditionalMetadataValue: 'Valore',
    meetingParticipants: 'Partecipanti',
    meetingNewParticipant: 'Nuovo partecipante',
    meetingParticipantName: 'Nome *',
    meetingParticipantEmail: 'E-mail',
    meetingParticipantAdviserId: 'ID del consulente',
    meetingParticipantLink: 'Link',
    meetingParticipantPublic: 'Publico',

    //Topics
    topics: 'Temi',

    topicTableId: 'ID',
    topicTableName: 'Nome',
    topicTableStatsGroup: 'Gruppo statistiche',

    topicAdd: 'Creare nuovo tema',
    topicEdit: 'Modifica tema',
    topicDeleteHeader: 'Elimina tema',
    topicDeleteDialog: 'Sei sicuro di voler eliminare questo tema?',

    topicId: 'ID *',
    topicName: 'Nome *',
    topicStatsGroup: 'Gruppo statistiche',

    userTopicsAll: 'Tutti i temi',
    userTopics1: 'Temi 1',
    userTopics2: 'Temi 2',
    userTopics3: 'Temi 3',

    //Maintenance Windows
    maintenanceWindows: 'Finestra di manutenzione',

    maintenanceWindowTableId: 'ID',
    maintenanceWindowTableDescription: 'Descrizione',
    maintenanceWindowTableStartDateTime: 'Data/ora di inizio',
    maintenanceWindowTableEndDateTime: 'Data/ora di fine',

    maintenanceWindowAdd: 'Aggiungi una nuova finestra di manutenzione',
    maintenanceWindowEdit: 'Modifica finestra di manutenzione',
    maintenanceWindowDeleteHeader: 'Cancellare la finestra di manutenzione',
    maintenanceWindowDeleteDialog: 'Vuoi davvero cancellare questa finestra di manutenzione?',

    maintenanceWindowId: 'ID *',
    maintenanceWindowDescription: 'Descrizione *',
    maintenanceWindowStartDateTime: 'Data/ora di inizio *',
    maintenanceWindowEndDateTime: 'Data/ora di fine *',

    //ApiKeys
    apiKeys: 'API-Key',
    apiKeyTableTitle: 'Titolo',
    apiKeyTableRoles: 'Ruolo',
    apiKeyTableIp: 'IP',
    apiKeyTableActive: 'Attivo',
    apiKeyAdd: 'Aggiungere una nuova API-Key',
    apiKeyEdit: "Modifica l'API-Key",
    apiKeyDeleteHeader: "Elimina l'API-Key",
    apiKeyDeleteDialog: 'Vuole davvero eliminare questa API-Key?',
    apiKeyTitle: 'Titolo *',
    apiKeyRoles: 'Ruoli *',
    apiKeyToken: 'Token',
    apiKeyIp: 'IP',
    apiKeyActive: 'Attiva',

    // ANALYTICS
    analytics: 'ANALYTICS',
    revisionViewer: 'Showboard Log',

    // Sessions
    sessions: 'Sessioni',
    sessionFilter: 'Filtro',
    sessionFilterHasRecordings: 'Registrato',

    sessionExportSelectDateMessage:
        'Seleziona il periodo di esportazione desiderato:',
    sessionExportSelectUntil: 'fino',
    sessionExportSelectMaxDataMessage:
        'Si noti che è possibile esportare un massimo di 20.000 record in una volta sola.',
    sessionExportType: 'Tipo di esportazione:',

    sessionDownloadRecordings: 'Scaricare le registrazioni',
    sessionDownloadAudioRecordings: 'Scaricare le registrazioni audio',
    sessionPrepareRecordingsMessage:
        'Le registrazioni vengono preparate per il download',
    sessionDownloadMessage: 'Le registrazioni sono pronte per il download.',
    sessionDownloadOngoingMessage:
        'Le registrazioni vengono scaricate. Questa operazione potrebbe durare diverso tempo.',
    sessionBackgroundDownload: 'Scarica in background',
    sessionDownloadNoRecordingsFound:
        'Per questa Sessione non ci sono registrazioni.',
    sessionDownloadNoDownloadAvailable:
        'Per questa registrazione non è ancora disponibile alcun download.',
    sessionDownloadErrorMessage:
        'Durante la preparazione del download si è verificato un errore.',
    sessionTableDate: 'Data',
    sessionTableStartTime: 'Inizio Sessione',
    sessionTableDuration: 'Dauer',
    sessionTableAdviser: 'Consulenti',
    sessionTableParticipants: 'Partecipanti',
    sessionTableRecordings: 'Registrazioni',
    sessionInfo: 'Info sessione',
    sessionInfoMessage:
        'Seleziona una sessione per visualizzare ulteriori informazioni.',
    sessionMeetingInfo: 'Info meeting',
    sessionMeetingInfoDate: 'Data',
    sessionMeetingInfoTitle: 'Titolo',
    sessionMeetingInfoDescription: 'Descrizione',
    sessionMeetingInfoDuration: 'Durata del meeting',
    sessionParticipantInfo: 'Info partecipante',
    sessionParticipantInfoName: 'Nome',
    sessionParticipantInfoEmail: 'E-mail',
    sessionParticipantInfoIsAdviser: 'Consulente',
    sessionLog: 'Log sessione',
    sessionInfoLogUnavailableMessage:
        'Per la sessione seleziona non è disèonibile nessun Log.',

    // ADMIN
    sysAdmin: 'SYS-ADMIN',

    // Admin Users
    adminUsers: 'Admin',
    adminUser: 'Admin',
    adminUserTableId: 'ID',
    adminUserTableName: 'Nome',
    adminUserTableUsername: 'Nome utente',
    adminUserAdd: 'Aggiungere admin',
    adminUserEdit: 'Modificare admin',
    adminUserDeleteHeader: "Elimina l'admin?",
    adminUserDeleteDialog: 'Vuole davvero eliminare questo admin?',
    adminUserUsername: 'Nome utente *',
    adminUserName: 'Nome *',
    adminUserId: 'ID',

    // Services
    services: 'Services',
    servicesSearch: 'Cerca servizi',

    servicesTableId: 'ID',
    servicesTableName: 'Nome',
    servicesTableLogo: 'Logo',
    servicesTableActive: 'Attivo',
    servicesAdd: 'Aggiungere service',
    servicesEdit: 'Modificare service',
    servicesDeleteHeader: 'Eliminare service?',
    servicesDeleteDialog: 'Vuole davvero eliminare questo service?',

    servicesBasicSettings: 'Client Settings',

    servicesGeneral: 'Impostazioni generali',
    servicesId: 'ID *',
    servicesName: 'Nome *',
    servicesLogo: 'Logo',
    servicesLandingPageLogo: 'Landing-Page Logo',
    servicesAuthMethod: 'Metodo di autenticazione',
    servicesPublicServiceFields: 'Public Service Fields',
    servicesOriginRestrictions: 'Origin Restrictions',
    servicesActive: 'Attivo',
    servicesServiceEmailAddress: 'indirizzo email di contatto',

    servicesAdiaPadTiles: 'Pulsanti del pannello di controllo',
    servicesAdiaPadTilesAvailable: 'Disponibile',
    servicesAdiaPadTilesSelected: 'Selezionato',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.CALL_MANAGER_INVITE_ADVISER]: 'Aggiungere consulenti',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.CALL_MANAGER_TRANSFER]: 'Inoltrare la chiamata',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.START_STOP_RECORDING]: 'Avviare/Interrompere registrazione',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.MUTE_PARTICIPANT]: 'Silenziare partecipante',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.ADD_PARTICIPANT]: 'Aggiungere partecipanti',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.REMOVE_PARTICIPANT]: 'Rimuovere partecipanti',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.SHOW_CAM_MIC_SETTINGS]: 'Impostazioni Camera/Mic',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.SEPARATOR_1]: '--- Separatore 1 ---',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.SHOW_SHOWBOARD]: 'Aprire Showboard',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.SHOW_AGENDA]: 'Aprire agenda',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.SHOW_MINUTES]: 'Aprire protocollo',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.REQUEST_SIGNATURE]: 'Richiedere una firma',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.START_STOP_SCREEN_SHARE]: 'Avvia/arresta condivisione schermo',
    ['servicesAdiaPadTiles-' +
    SUPPORTED_ADIAPAD_TILES.SEPARATOR_2]: '--- Separatore 2 ---',

    servicesMeetingsSettings: 'Impostazioni generali',
    servicesSharedApplicationsUrl: 'Shared Applications Url',
    servicesMeetingsManagerUrl: 'Meetings Manager URL',
    servicesCommunicatorUrl: 'Communicator URL',
    servicesAdviserUrl: 'URL consulenti',
    servicesParticipantLimit: 'Limite dei partecipanti',
    servicesEnableRecording: 'Attivare registrazione',
    servicesEnableRecordingConfirmation: 'Attivare conferma di registrazione',
    servicesEnableAutoRecording: 'Attivare reistrazione automatica',
    servicesDisableCommunicatorFileTransfer:
        'Trasferimento documenti nel Communicator disattivato',
    servicesEnableCommunicatorContactForm:
        'Attiva il modulo di contatto nel Communicator',
    servicesEnableRaiseHand: 'Enable Raise Hand',
    servicesEnableIsExclusiveToggle: 'Enable Exclusive Meetings',
    servicesEnableAuthenticatedParticipants: 'Enable Authenticated Participants',
    servicesEnableSurveyInMeeting: 'Enable Surveys in Meetings',
    servicesEnableVoteInMeeting: 'Enable Votes in Meetings',
    servicesEnableWaitingRoom: 'Enable Waiting Room',
    servicesColoredChatNames: 'Colored Names in Chat',

    servicesDisableFileAttachmentsForParticipants:
        'Disable File Attachments for Participants',
    servicesDisableFileDownloadsForParticipants:
        'Disable File Downloads for Participants',
    servicesMaxAttachmentsSize: 'Maximum Attachment File Size',
    servicesMaxFileUploadSize: 'Maximum Upload File Size',
    servicesAcceptDocumentsTypesModerators:
        'Accepted Document Types (Moderators)',
    servicesAcceptDocumentsTypesAttendees:
        'Accepted Document Types (Attendees)',
    servicesEnableNepatec: 'Attiva Nepatec',
    servicesShowInsign: 'Attiva Insign',
    servicesEnableMeetingsLimit: 'Enable Meetings Limit',
    servicesMeetingsLimit: 'Meetings Limit',
    servicesReservedAdHocSlots: 'Reserved Slots for instant Meetings',
    servicesAddCurrentUserToNewMeeting: 'Add current user to new meeting',
    servicesAddAdditionalModerators: 'Add additional moderators',
    servicesKeldanoFeDomain: 'Keldano Fe Domain',
    servicesKeldanoApiDomain: 'Keldano Api Domain',
    servicesAttachAgenda: 'Attach Agenda',
    servicesAttachProtocol: 'Attach Protocol',
    servicesAttendeesSendPostProcessingMailEnabled:
        'Send Post-Processing Mail Enabled (Attendees)',
    servicesPreselectInvitationMailModerators:
        'Preselect Invitation Email (Moderators)',
    servicesPreselectInvitationMailAttendees:
        'Preselect Invitation Email (Attendees)',
    servicesPreselectPostProcessingMailModerators:
        'Preselect Post-Processing Email (Moderators)',
    servicesPreselectPostProcessingMailAttendees:
        'Preselect Post-Processing Email (Attendees)',
    servicesDisableArchive: 'Disable Archive',
    servicesCreateLimitDaysFuture: 'Maximum allowed Days to create Meeting into Future',
    servicesSummaryLink: 'Custom Link on Summary Page',
    servicesGenerateSummaryPdf: 'Generate PDF Protocol after Meeting',
    servicesRevisionPdfComments: 'Add Revision Information as PDF Comments',
    servicesRevisionViewer: 'Enable Revision Viewer',
    servicesParticipantsPdfEdit: 'Enable PDF-Edit for Participants',
    servicesTokenAlphabet: 'Short ID Alphabet',
    servicesTokenLength: 'Short ID Length',
    servicesDisableUploadInMeetingManager: 'File Upload disabled when creating meetings',
    servicesDisableAgenda: 'Disable Agenda',
    servicesDisableMinutes: 'Disable Minutes',
    servicesCompleteMeetingDialogOnLeave: 'Show Dialog to complete Meeting on leaving',
    servicesDisableSendSummary: 'Disable sending Summary-Email',

    servicesMailTemplateSettings: 'Mail Template Settings',
    servicesMailTemplateLogo: 'Mail Logo *',
    servicesMailTemplateCompany: 'Mail Company',
    servicesMailTemplateAddress: 'Mail Address',
    servicesMailTemplatePhone: 'Mail Phone',
    servicesMailTemplateWebPage: 'Mail Webpage',
    servicesMailTemplateFaxNumber: 'Mail Fax Number',

    servicesMailSettings: 'Mail Settings',
    servicesMailCredentialsUsername: 'Username',
    servicesMailCredentialsPassword: 'Password',
    servicesMailFromAddress: 'From Address',
    servicesMailFromName: 'From Name',
    servicesMailReplyToAddress: 'Reply To Address',
    servicesMailReplyToName: 'Reply To Name',
    servicesMailReturnPathAddress: 'Return Path Address',
    servicesMailReturnPathName: 'Return Path Name',
    servicesMailSmtpServer: 'SMTP Server',
    servicesMailSmtpPort: 'SMTP Port',
    servicesMailMethod: 'Method',
    servicesMailAdviserAddressAsSender: 'Use Adviser Address as Sender',
    servicesMailAllowSenderSelection: 'Allow Selection of Sender',
    servicesMailSendCopyToAdviser: 'Send BCC to Adviser',

    servicesLocalesOverwrites: 'Locales Overwrites',

    servicesWebRtcSettings: 'Impostazioni WebRTC',
    servicesWebRtcMode: 'Modo',
    servicesWebRtcUrl: 'URL *',
    servicesWebRtcSecret: 'Secret *',
    servicesWebRtcApplicationId: 'Application ID *',
    servicesWebRtcApiKey: 'Api Key *',
    servicesWebRtcMaxAudioBitrate: 'Bitrate massimo input audio (kbps)',
    servicesWebRtcMaxVideoBitrate: 'Bitrate massimo input video (kbps)',
    servicesWebRtcUseSimulcast: 'Attivare Simulcast',
    servicesWebRtcIceServerAddress: 'Turn Server',
    servicesWebRtcRelayUsername: 'Turn Username',
    servicesWebRtcRelayPassword: 'Turn Password',
    servicesWebRtcDefaultBackground: 'Immagine di sfondo del video predefinita',
    servicesWebRtcDisableLocalBgImage: 'Disattivare l\'immagine di sfondo locale,',

    servicesKeycloakSettings: 'Impostazioni Keycloak',
    servicesKeycloakUrl: 'URL',
    servicesKeycloakRealm: 'Realm',
    servicesKeycloakClientId: 'Client Id',
    servicesKeycloakKid: 'KID',
    servicesKeycloakPublicKey: 'Public Key',
    servicesKeycloakAutoSyncUsers: 'Enable User Auto-Sync',

    servicesKeldanoApiSettings: 'Impostazioni API',
    servicesKeldanoApiUrl: 'URL *',
    servicesKeldanoApiClientId: 'Client Id *',
    servicesKeldanoApiClientSecret: 'Client Secret *',
    servicesKeldanoApiUsername: 'Nome utente *',
    servicesKeldanoApiPassword: 'Password *',
    servicesKeldanoApiEndpoint: 'Keldano API Endpoint',

    servicesWebinarSettings: 'Impostazioni webinar',
    servicesWebinarMaxAdvisers: 'Numero massimo di consulenti',
    servicesWebinarMaxParticipants: 'Numero massimo di partecipanti',
    servicesWebinarMaxSpeakers: 'Numero massimo di oratori',
    servicesWebinarDisableVideoForParticipants:
        'Disattivare il video per i partecipanti',

    servicesPhoneConsultingSettings: 'Impostazioni consulenza telefonica',

    servicesPodiumSettings: 'Podium Settings',
    servicesPodiumServiceId: 'Service ID *',
    servicesPodiumStreamingServer: 'Streaming Server *',
    servicesPodiumStreamingApp: 'Streaming App *',
    servicesPodiumStreamingUrl: 'Streaming URL *',
    servicesPodiumMaxAdvisers: 'Numero massimo di consulenti',
    servicesPodiumMaxParticipants: 'Numero massimo di partecipanti',
    servicesPodiumMaxSpeakers: 'Numero massimo di oratori',

    servicesCallManagerSettings: 'Call Manager Settings',
    servicesEnableCommModeMatching: 'Enable Communication-Mode Matching',

    servicesOptions: 'Opzioni',
    servicesExperimentalMode: 'Experimental Mode',
    servicesMeetings: 'Meeting',
    servicesOneToOne: 'One-To-One',
    servicesCallManager: 'Callmanager',
    servicesVideoIdent: 'Identificazione video',
    servicesBranch: 'Branch',
    servicesKeldanoApi: 'Keldano API',
    servicesWebinar: 'Webinar',
    servicesHideAdviserList: 'Hide Adviserlist',
    servicesPhoneConsulting: 'Consulenza telefonica',
    servicesPodium: 'Podium',

    servicesLogging: 'Logging',
    servicesSessionLog: 'Log sessione',
    servicesChatLog: 'Log chat',
    servicesAdviserTracking: 'Tracking consulenti',
    //servicesUserCustomFields: 'Campi personalizzati (utente del servizio)',
    servicesAdviserUserCustomFields: 'Campi personalizzati (Utente)',
    servicesNewCustomField: 'Nuovo campo personalizzato',
    servicesCustomFieldsHeader: 'Campi personalizzati',
    servicesFieldId: 'ID',
    servicesFieldType: 'Tipo',
    servicesFieldLabel: 'Label',
    servicesFieldDescription: 'Descrizione',
    servicesFieldRequired: 'Obbligatorio',
    servicesFieldHidden: 'Nascosto',
    servicesFieldDefault: 'Valore di default',
    servicesFieldPattern: 'Modello di validazione',
    servicesFieldUsage: 'Uso',
    servicesFieldOptions: 'Opzioni dropdown',
    servicesFieldOptionsLabel: 'Label dropdown',
    servicesFieldOptionsValue: 'valore',
    invalidCustomFieldId:
        'Un ID vuoto non è valido. Non possono essere utilizzati i seguenti ID: name, id, username, password, passwordConfirmation.',

    calendar: {
        firstDayOfWeek: 1,
        dayNames: [
            'Domenica',
            'Lunedì',
            'Martedì',
            'Mercoledì',
            'Giovedì',
            'Venerdì',
            'Sabato'
        ],
        dayNamesShort: ['Do.', 'Lu.', 'Ma.', 'Me.', 'Gi.', 'Ve.', 'Sa.'],
        dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
        monthNames: [
            'Gennaio',
            'Febbraio',
            'Marzo',
            'Aprile',
            'Maggio',
            'Giugno',
            'Luglio',
            'Agosto',
            'Settembre',
            'Ottobre',
            'Novembre',
            'Dicembre'
        ],
        monthNamesShort: [
            'Geb.',
            'Feb.',
            'Mar.',
            'Apr.',
            'Mag',
            'Giu.',
            'Lug.',
            'Ago.',
            'Set.',
            'Ott.',
            'Nov.',
            'Dic.'
        ]
    },
    pathDoesNotExist: 'Questa pagina non esiste',
    invalidUsername: 'Solo lettere, numeri, "-", "_" e "." sono permessi.',
    invalidPassword: 'La password deve contenere almeno 8 caratteri.',
    invalidPasswordConfirmation: 'Le password non corrispondono.',
    logoutDialogHeader: 'Logout',
    logoutDialog:
        'Cliccanod su "Si" verrai disconnesso da tutte le applicazioni. Perderai la connessione a tutti i meeting in corso.',

    // messages
    connectionError: 'La connessione al server non riuscita.',
    noServiceError:
        'È necessario almeno un Service per eseguire questa azione.',
    changeServiceError: 'Il Service non può essere cambiato.',
    editCurrentUserErrorMessage:
        "Non è possibile modificare o eliminare un utente con il quale si è effettuato l'accesso.",
    serverError: 'oops! Si è verificato un errore.',
    accessDenied: 'Accesso negato!',
    notAuthorized: 'Non autorizzato!',
    authInvalidIp: 'IP non valido.',
    authInvalidUser: 'Utente non valido.',
    authInvalidCaptcha: 'Captcha non valido.',
    authInvalidCredentials: 'Dati di accesso non validi.',
    userRequestError: "Non è stato possibile recuperare l'utente o gli utenti.",
    userAddSuccess: "L'utente è stato aggiunto.",
    userConflict: 'Un utente con questo nome utente esiste già.',
    userKeycloakConflict:
        'Un utente con questo nome utente e / o questa email esiste già.',
    userAddError: "Non è stato possibile aggiungere l'utente.",
    userUpdateSuccess: "L'utente è stato aggiornato",
    userNotFound: 'Questo utente non esiste.',
    userUpdateError: "Non è stato possibile aggiornare l'utente",
    userDeleteSuccess: "L'utente è stato eliminato.",
    userDeleteError: "Non è stato possibile eliminre l'utente.",
    userSyncSuccess: 'Gli utenti sono stati sincronizzati.',
    userSyncError:
        'Si è verificato un errore durante la sincronizzazione degli utenti.',
    meetingRequestError:
        'Non è stato possibile recuperare il meeting o i meeting.',
    meetingAddSuccess: 'Il meeting è stato aggiunto.',
    meetingAddNotSupported:
        'Non è stato possibile aggiungere i Keldano-Meeting nel Manager.',
    meetingAddError: 'Non è stato possibile aggingere il meeting.',
    meetingUpdateSuccess: 'Il meeting è stato aggiornato.',
    meetingNotFound: 'Questom meeting non esiste.',
    meetingUpdateError: 'Non è stato possibile aggiornare il meeting.',
    meetingDeleteSuccess: 'Il meeting è stato eliminato.',
    meetingDeleteNotSupported:
        'Non è stato possibile eliminare i Keldano-Meeting nel Manager.',
    meetingDeleteError: 'Non è stato possibile eliminare il meeting.',
    apiKeyRequestError: 'Non è stato possibile recuperare la/le API Key.',
    apiKeyAddSuccess: "L'API Key è stata aggiunta",
    apiKeyAddError: "Non è stato possibile aggiungere l'API Key.",
    apiKeyUpdateSuccess: "L'API Key è stata aggiornata",
    apiKeyNotFound: 'Questa API Key non esiste.',
    apiKeyUpdateError: "Non è stato possibile aggiornare l'API Key.",
    apiKeyDeleteSuccess: "L'API Key è stata eliminata",
    apiKeyDeleteError: "Non è stato possibile eliminare l'API Key.",
    sessionRequestError:
        'Non è stato possibile recuperare la sessione o le sessioni.',
    sessionDownloadNotAllowed:
        'Lei non è autorizzato a scaricare le registrazioni.',
    adminUserRequestError:
        "Non è stato possibile recuperare l'admin o gli admin.",
    adminUserAddSuccess: "L'Admin è stato aggiunto",
    adminUserConflict: 'Un admin con questo nome utente esiste già.',
    adminUserAddError: "Non è stato possibile aggiungere l'admin.",
    adminUserUpdateSuccess: "L'admin è stato aggiornato.",
    adminUserNotFound: 'Questo admin non esiste.',
    adminUserUpdateError: "Non è stato possibile aggiornare l'admin",
    adminUserDeleteSuccess: "L'admin è stato eliminato.",
    adminUserDeleteError: "Non è stato possibile eliminre l'admin.",
    serviceRequestError: 'Non è stato possibile recuperare il/i Service.',
    serviceAddSuccess: 'Il Service è stato aggiunto.',
    serviceConflict: 'Un Service con questo ID esiste già.',
    serviceWebRtcError: 'Non è stato possibile settare le impostazioni WebRTC.',
    serviceAddError: 'Non è stato possibile aggiungere il Service.',
    serviceUpdateSuccess: 'Il Service è stato aggiornato.',
    serviceNotFound: 'Questo Service non esiste.',
    serviceUpdateError: 'Non è stato possibile aggiornare il Service.',
    serviceDeleteSuccess: 'Il Service è stato eliminato.',
    serviceDeleteError: 'Non è stato possibile eliminare il Service.'
};
