export const REQUEST_TOPICS = 'REQUEST_TOPICS';
export const REQUEST_TOPICS_SUCCESS = 'REQUEST_TOPICS_SUCCESS';
export const REQUEST_TOPICS_FAILURE = 'REQUEST_TOPICS_FAILURE';

export const ADD_TOPIC = 'ADD_TOPIC';
export const ADD_TOPIC_SUCCESS = 'ADD_TOPIC_SUCCESS';
export const ADD_TOPIC_FAILURE = 'ADD_TOPIC_FAILURE';

export const UPDATE_TOPIC = 'UPDATE_TOPIC';
export const UPDATE_TOPIC_SUCCESS = 'UPDATE_TOPIC_SUCCESS';
export const UPDATE_TOPIC_FAILURE = 'UPDATE_TOPIC_FAILURE';

export const DELETE_TOPIC = 'DELETE_TOPIC';
export const DELETE_TOPIC_SUCCESS = 'DELETE_TOPIC_SUCCESS';
export const DELETE_TOPIC_FAILURE = 'DELETE_TOPIC_FAILURE';

export const START_EDITING_TOPIC = 'START_EDITING_TOPIC';
export const STOP_EDITING_TOPIC = 'STOP_EDITING_TOPIC';
export const EDIT_TOPIC = 'EDIT_TOPIC';
export const ADD_TOPIC_VALIDATION = 'ADD_TOPIC_VALIDATION';

export const RESET_TOPICS_PAGE = 'RESET_TOPICS_PAGE';
