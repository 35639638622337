import * as types from './actionTypes';
import {
    changeSelectedService,
    forceLogout,
    getServicesList,
    updateSelectedService
} from '../auth/actions';
import { adiaLiveSettings, handleError, log } from '../base/util/helpers';
import authHelper from '../base/util/authHelper';
import { Config } from '../../config/Config';
import { SUPPORTED_ADIAPAD_TILES } from '../../constants/constants';

export function convertServiceSettings(serviceData, dataFromServer) {
    if (dataFromServer) {
        serviceData.forEach(service => {
            // convert flag to boolean settings
            if (service.hasOwnProperty('flags')) {
                adiaLiveSettings.flagsToSettings(service);
            }

            // convert originRestrictions from comma separated string to array
            if (service.hasOwnProperty('originRestrictions')) {
                if (!Array.isArray(service.originRestrictions)) {
                    service.originRestrictions =
                        service.originRestrictions === ''
                            ? []
                            : service.originRestrictions.split(',');
                }
            }

            // convert adiaPadTiles to object containing available and selected tiles
            if (Array.isArray(service.adiaPadTiles)) {
                const supportedTiles = Object.values(SUPPORTED_ADIAPAD_TILES);
                const selected = service.adiaPadTiles.filter(tile =>
                    supportedTiles.includes(tile)
                );
                const available = supportedTiles.filter(
                    tile => !selected.includes(tile)
                );
                service.adiaPadTiles = { available, selected };
            }

            // convert meetingsSettings object to single properties
            if (service.meetingsSettings) {
                Object.keys(service.meetingsSettings).forEach(
                    meetingsSetting => {
                        if (meetingsSetting === 'localesOverwrites') {
                            //TODO: localesOverwrites: apparently not implemented yet on keldano
                        } else if (meetingsSetting === 'mailTemplateSettings') {
                            Object.keys(
                                service.meetingsSettings.mailTemplateSettings
                            ).forEach(mailTemplateSetting => {
                                service[
                                    'meetings.mailTemplate.' +
                                        mailTemplateSetting
                                ] =
                                    service.meetingsSettings.mailTemplateSettings[
                                        mailTemplateSetting
                                    ];
                            });
                        } else if (meetingsSetting === 'mailSettings') {
                            Object.keys(
                                service.meetingsSettings.mailSettings
                            ).forEach(mailSetting => {
                                if (mailSetting === 'credentials') {
                                    Object.keys(
                                        service.meetingsSettings.mailSettings
                                            .credentials
                                    ).forEach(credential => {
                                        service[
                                            'meetings.mail.credentials.' +
                                                credential
                                        ] =
                                            service.meetingsSettings.mailSettings.credentials[
                                                credential
                                            ];
                                    });
                                } else {
                                    service['meetings.mail.' + mailSetting] =
                                        service.meetingsSettings.mailSettings[
                                            mailSetting
                                        ];
                                }
                            });
                        } else {
                            service['meetings.' + meetingsSetting] =
                                service.meetingsSettings[meetingsSetting];
                        }
                    }
                );
                delete service.meetingsSettings;
            }

            // convert webRtcSettings object to single properties
            if (service.webRtcSettings) {
                Object.keys(service.webRtcSettings).forEach(webRtcSetting => {
                    service['webRtc.' + webRtcSetting] =
                        service.webRtcSettings[webRtcSetting];
                });
                delete service.webRtcSettings;
            }

            // convert keycloakSettings object to single properties
            if (service.keycloakSettings) {
                Object.keys(service.keycloakSettings).forEach(
                    keycloakSetting => {
                        if (keycloakSetting === 'publicKey') {
                            let publicKey = service.keycloakSettings.publicKey.slice();
                            if (publicKey) {
                                publicKey = publicKey.replace(
                                    '-----BEGIN PUBLIC KEY-----\n',
                                    ''
                                );
                                publicKey = publicKey.replace(
                                    '\n-----END PUBLIC KEY-----',
                                    ''
                                );
                            }
                            service['keycloak.publicKey'] = publicKey;
                        } else {
                            service['keycloak.' + keycloakSetting] =
                                service.keycloakSettings[keycloakSetting];
                        }
                    }
                );
                delete service.keycloakSettings;
            }

            // convert keldanoApiSettings object to single properties
            if (service.keldanoApiSettings) {
                Object.keys(service.keldanoApiSettings).forEach(
                    keldanoApiField => {
                        service['keldanoApi.' + keldanoApiField] =
                            service.keldanoApiSettings[keldanoApiField];
                    }
                );
                delete service.keldanoApiSettings;
            }

            // convert webinarSettings object to single properties
            if (service.webinarSettings) {
                Object.keys(service.webinarSettings).forEach(webinarField => {
                    service['webinar.' + webinarField] =
                        service.webinarSettings[webinarField];
                });
                delete service.webinarSettings;
            }

            // convert phoneConsultingSettings object to single properties
            if (service.phoneConsultingSettings) {
                Object.keys(service.phoneConsultingSettings).forEach(
                    phoneConsultingField => {
                        service['phoneConsulting.' + phoneConsultingField] =
                            service.phoneConsultingSettings[
                                phoneConsultingField
                            ];
                    }
                );
                delete service.phoneConsultingSettings;
            }

            // convert podiumSettings object to single properties
            if (service.podiumSettings) {
                Object.keys(service.podiumSettings).forEach(podiumField => {
                    service['podium.' + podiumField] =
                        service.podiumSettings[podiumField];
                });
                delete service.podiumSettings;
            }

            // convert callManagerSettings object to single properties
            if (service.callManagerSettings) {
                Object.keys(service.callManagerSettings).forEach(
                    callManagerField => {
                        service['callManager.' + callManagerField] =
                            service.callManagerSettings[
                                callManagerField
                                ];
                    }
                );
                delete service.callManagerSettings;
            }

            // convert nepatecSettings object to single properties
            if (service.nepatecSettings) {
                Object.keys(service.nepatecSettings).forEach(
                    nepatecSettingsField => {
                        service['nepatecSettings.' + nepatecSettingsField] =
                            service.nepatecSettings[nepatecSettingsField];
                    }
                );
                delete service.nepatecSettings;
            }
        });
    } else {
        // convert boolean settings to flag; set oneToOne to same value as callManager
        serviceData.oneToOne = serviceData.callManager;
        adiaLiveSettings.settingsToFlags(serviceData);

        // convert originRestrictions from array to comma separated string
        if (Array.isArray(serviceData.originRestrictions)) {
            serviceData.originRestrictions = serviceData.originRestrictions.join(
                ','
            );
        }

        // convert adiaPadTiles object back to array containing the selected tiles
        if (
            serviceData.hasOwnProperty('adiaPadTiles') &&
            Array.isArray(serviceData.adiaPadTiles.selected)
        ) {
            const supportedTiles = Object.values(SUPPORTED_ADIAPAD_TILES);
            serviceData.adiaPadTiles = serviceData.adiaPadTiles.selected.filter(
                tile => supportedTiles.includes(tile)
            );
        }

        // convert meetingsSettings/webRtcSettings/keycloakSettings/keldanoApiSettings properties to object
        let meetingsSettings = {};
        let webRtcSettings = {};
        let keycloakSettings = {};
        let keldanoApiSettings = {};
        let webinarSettings = {};
        let phoneConsultingSettings = {};
        let podiumSettings = {};
        let callManagerSettings = {};
        let nepatecSettings = {};

        Object.keys(serviceData).forEach(field => {
            if (field.startsWith('meetings.')) {
                if (field.startsWith('meetings.mailTemplate.')) {
                    if (!meetingsSettings.mailTemplateSettings) {
                        meetingsSettings.mailTemplateSettings = {};
                    }

                    meetingsSettings.mailTemplateSettings[
                        field.replace('meetings.mailTemplate.', '')
                    ] = serviceData[field];
                } else if (field.startsWith('meetings.mail.')) {
                    if (!meetingsSettings.mailSettings) {
                        meetingsSettings.mailSettings = {};
                    }

                    if (field.startsWith('meetings.mail.credentials.')) {
                        if (!meetingsSettings.mailSettings.credentials) {
                            meetingsSettings.mailSettings.credentials = {};
                        }

                        meetingsSettings.mailSettings.credentials[
                            field.replace('meetings.mail.credentials.', '')
                        ] = serviceData[field];
                    } else {
                        meetingsSettings.mailSettings[
                            field.replace('meetings.mail.', '')
                        ] = serviceData[field];
                    }
                } else if (field === 'meetings.localesOverwrites') {
                    meetingsSettings.localesOverwrites = {};

                    //TODO: localesOverwrites: apparently not implemented yet on keldano
                    /*(Object.keys(serviceData[field]) || []).forEach(language => {
                        meetingsSettings.localesOverwrites[language] = serviceData[field][language].map(overwriteField => {
                            return Object.entries(overwriteField);
                        })
                    });*/
                } else {
                    meetingsSettings[field.replace('meetings.', '')] =
                        serviceData[field];
                }
                delete serviceData[field];
            } else if (field.startsWith('webRtc.')) {
                webRtcSettings[field.replace('webRtc.', '')] =
                    serviceData[field];
                delete serviceData[field];
            } else if (field.startsWith('keycloak.')) {
                if (field === 'keycloak.publicKey') {
                    const publicKey = serviceData['keycloak.publicKey']
                        .trim()
                        .slice();

                    if (publicKey) {
                        keycloakSettings.publicKey =
                            '-----BEGIN PUBLIC KEY-----\n' +
                            publicKey +
                            '\n-----END PUBLIC KEY-----';
                    } else {
                        keycloakSettings.publicKey = '';
                    }
                } else if (field === 'keycloak.autoSyncUsers') {
                    keycloakSettings.autoSyncUsers = serviceData[field];
                } else {
                    keycloakSettings[
                        field.replace('keycloak.', '')
                    ] = serviceData[field].trim();
                }
                delete serviceData[field];
            } else if (field.startsWith('keldanoApi.')) {
                keldanoApiSettings[field.replace('keldanoApi.', '')] =
                    serviceData[field];
                delete serviceData[field];
            } else if (field.startsWith('webinar.')) {
                webinarSettings[field.replace('webinar.', '')] =
                    serviceData[field];
                delete serviceData[field];
            } else if (field.startsWith('phoneConsulting.')) {
                phoneConsultingSettings[field.replace('phoneConsulting.', '')] =
                    serviceData[field];
                delete serviceData[field];
            } else if (field.startsWith('podium.')) {
                podiumSettings[field.replace('podium.', '')] =
                    serviceData[field];
                delete serviceData[field];
            } else if (field.startsWith('callManager.')) {
                callManagerSettings[field.replace('callManager.', '')] =
                    serviceData[field];
                delete serviceData[field];
            } else if (field.startsWith('nepatecSettings.')) {
                nepatecSettings[field.replace('nepatecSettings.', '')] =
                    serviceData[field];
                delete serviceData[field];
            }
        });

        serviceData.meetingsSettings = meetingsSettings;
        serviceData.webRtcSettings = webRtcSettings;
        serviceData.keycloakSettings = keycloakSettings;
        serviceData.keldanoApiSettings = keldanoApiSettings;
        serviceData.webinarSettings = webinarSettings;
        serviceData.phoneConsultingSettings = phoneConsultingSettings;
        serviceData.podiumSettings = podiumSettings;
        serviceData.callManagerSettings = callManagerSettings;
        serviceData.nepatecSettings = nepatecSettings;
    }
}

export function updateRequestParameters(requestParameters) {
    return {
        type: types.UPDATE_SERVICE_REQUEST_PARAMETERS,
        requestParameters
    };
}

/*
 * action creators
 */
function requestServicesRequest() {
    return { type: types.REQUEST_SERVICES };
}

function requestServicesSuccess(services, contentRange) {
    return { type: types.REQUEST_SERVICES_SUCCESS, services, contentRange };
}

function requestServicesFailure(error) {
    return { type: types.REQUEST_SERVICES_FAILURE, error };
}

/*
 * thunk
 */
export function requestServices() {
    return async (dispatch, getState, api) => {
        dispatch(requestServicesRequest());

        const {
            fields,
            sort,
            range,
            search
        } = getState().services.requestParameters;
        const response = await api.getServices(
            fields,
            sort,
            range,
            search,
            authHelper.getToken()
        );
        if (!response.error) {
            convertServiceSettings(response.services, true);
            log.debug('[requestServices]', response.services);
            dispatch(
                requestServicesSuccess(response.services, response.contentRange)
            );
        } else {
            handleError(response.error, {
                fatal: error => dispatch(forceLogout(error)),
                nonFatal: error => dispatch(requestServicesFailure(error))
            });
        }
    };
}

/*
 * action creators
 */
function addServiceRequest() {
    return { type: types.ADD_SERVICE };
}

function addServiceSuccess(service) {
    return { type: types.ADD_SERVICE_SUCCESS, service };
}

function addServiceFailure(error) {
    return { type: types.ADD_SERVICE_FAILURE, error };
}

/*
 * thunk
 */
export function addService() {
    return async (dispatch, getState, api) => {
        dispatch(addServiceRequest());

        const serviceToAdd = getState().services.serviceToAdd;
        let service;

        if (Config.multitenantServices) {
            service = {
                _id: serviceToAdd._id,
                name: serviceToAdd.name,
                logo: serviceToAdd.logo,
                meetingsSettings: {
                    mailTemplateSettings: {
                        logo: serviceToAdd['meetings.mailTemplate.logo'],
                        company: serviceToAdd['meetings.mailTemplate.company'],
                        address: serviceToAdd['meetings.mailTemplate.address'],
                        phone: serviceToAdd['meetings.mailTemplate.phone'],
                        webPage: serviceToAdd['meetings.mailTemplate.webPage'],
                        faxNumber:
                            serviceToAdd['meetings.mailTemplate.faxNumber']
                    }
                }
            };
        } else {
            service = { ...serviceToAdd };
            convertServiceSettings(service, false);
        }
        log.debug('[addService]', service);

        const response = await api.addService(service, authHelper.getToken());

        if (!response.error) {
            convertServiceSettings([response.service], true);

            dispatch(addServiceSuccess(response.service));
            dispatch(requestServices());
            dispatch(changeSelectedService(undefined, response.service));
            dispatch(getServicesList());
        } else {
            handleError(response.error, {
                fatal: error => dispatch(forceLogout(error)),
                nonFatal: error => dispatch(addServiceFailure(error))
            });
        }
    };
}

/*
 * action creators
 */
function updateServiceRequest() {
    return { type: types.UPDATE_SERVICE };
}

function updateServiceSuccess(service) {
    return { type: types.UPDATE_SERVICE_SUCCESS, service };
}

function updateServiceFailure(error) {
    return { type: types.UPDATE_SERVICE_FAILURE, error };
}

/*
 * thunk
 */
export function updateService(serviceId) {
    return async (dispatch, getState, api) => {
        dispatch(updateServiceRequest());

        const serviceToEdit = getState().services.serviceToEdit;

        //create a (shallow) copy of the serviceToEdit object
        let service = { ...serviceToEdit };
        const updatedFields = getState().services.updatedFields;

        convertServiceSettings(service, false);

        // remove fields that were not updated
        Object.keys(service).forEach(field => {
            switch (field) {
                case 'meetingsSettings':
                    if (
                        !Object.keys(updatedFields).find(fieldId =>
                            fieldId.includes('meetings.')
                        )
                    ) {
                        delete service.meetingsSettings;
                    }
                    break;
                case 'webRtcSettings':
                    if (
                        !Object.keys(updatedFields).find(fieldId =>
                            fieldId.includes('webRtc.')
                        )
                    ) {
                        delete service.webRtcSettings;
                    }
                    break;
                case 'keycloakSettings':
                    if (
                        !Object.keys(updatedFields).find(fieldId =>
                            fieldId.includes('keycloak.')
                        )
                    ) {
                        delete service.keycloakSettings;
                    }
                    break;
                case 'keldanoApiSettings':
                    if (
                        !Object.keys(updatedFields).find(fieldId =>
                            fieldId.includes('keldanoApi.')
                        )
                    ) {
                        delete service.keldanoApiSettings;
                    }
                    break;
                case 'webinarSettings':
                    if (
                        !Object.keys(updatedFields).find(fieldId =>
                            fieldId.includes('webinar.')
                        )
                    ) {
                        delete service.webinarSettings;
                    }
                    break;
                case 'phoneConsultingSettings':
                    if (
                        !Object.keys(updatedFields).find(fieldId =>
                            fieldId.includes('phoneConsulting.')
                        )
                    ) {
                        delete service.phoneConsultingSettings;
                    }
                    break;
                case 'podiumSettings':
                    if (
                        !Object.keys(updatedFields).find(fieldId =>
                            fieldId.includes('podium.')
                        )
                    ) {
                        delete service.podiumSettings;
                    }
                    break;
                case 'callManagerSettings':
                    if (
                        !Object.keys(updatedFields).find(fieldId =>
                            fieldId.includes('callManager.')
                        )
                    ) {
                        delete service.callManagerSettings;
                    }
                    break;
                case 'nepatecSettings':
                    if (
                        !Object.keys(updatedFields).find(fieldId =>
                            fieldId.includes('nepatecSettings.')
                        )
                    ) {
                        delete service.nepatecSettings;
                    }
                    break;
                case 'flags':
                    break;
                default:
                    if (!updatedFields[field]) {
                        delete service[field];
                    }
            }
        });

        if (Config.multitenantServices) {
            // don't send an empty meetingsSettings.mailSettings.credentials.password!
            if (
                service.meetingsSettings &&
                service.meetingsSettings.mailSettings &&
                service.meetingsSettings.mailSettings.credentials &&
                service.meetingsSettings.mailSettings.credentials.password ===
                    ''
            ) {
                delete service.meetingsSettings.mailSettings.credentials
                    .password;
            }
            // don't send an empty nepatec password
            if (
                service.nepatecSettings &&
                service.nepatecSettings.controllerPassword === ''
            ) {
                delete service.nepatecSettings.controllerPassword;
            }
        }
        log.debug('[updateService]', service);

        const response = await api.updateService(
            serviceId,
            service,
            authHelper.getToken()
        );

        if (!response.error) {
            convertServiceSettings([response.service], true);

            dispatch(updateServiceSuccess(response.service));
            dispatch(requestServices());
            dispatch(updateSelectedService(response.service));
            dispatch(getServicesList());
        } else {
            handleError(response.error, {
                fatal: error => dispatch(forceLogout(error)),
                nonFatal: error => dispatch(updateServiceFailure(error))
            });
        }
    };
}

/*
 * action creators
 */
function deleteServiceRequest() {
    return { type: types.DELETE_SERVICE };
}

function deleteServiceSuccess(serviceId) {
    return { type: types.DELETE_SERVICE_SUCCESS, serviceId };
}

function deleteServiceFailure(error) {
    return { type: types.DELETE_SERVICE_FAILURE, error };
}

/*
 * thunk
 */
export function deleteService(serviceId) {
    return async (dispatch, getState, api) => {
        dispatch(deleteServiceRequest());
        const response = await api.deleteService(
            serviceId,
            authHelper.getToken()
        );
        if (!response.error) {
            dispatch(deleteServiceSuccess(serviceId));
            dispatch(requestServices());
            await dispatch(getServicesList());

            //check if the deleted service was the active service;
            //if so, change the service to the first available service (if there is one)
            if (serviceId === getState().auth.service._id) {
                const services = getState().auth.services;
                dispatch(
                    changeSelectedService(
                        services.length > 0
                            ? services[services.length - 1]._id
                            : undefined
                    )
                );
            }
        } else {
            handleError(response.error, {
                fatal: error => dispatch(forceLogout(error)),
                nonFatal: error => dispatch(deleteServiceFailure(error))
            });
        }
    };
}

/*
 * action creators
 */
export function startEditingService(serviceData) {
    return { type: types.START_EDITING_SERVICE, serviceData };
}

export function stopEditingService() {
    return { type: types.STOP_EDITING_SERVICE };
}

export function editService(currentFields, update) {
    return { type: types.EDIT_SERVICE, currentFields, update };
}

export function addServiceValidation(validationType) {
    return { type: types.ADD_SERVICE_VALIDATION, validationType };
}

function resetPage() {
    return { type: types.RESET_SERVICES_PAGE };
}

/*
 * thunk
 */
export function resetServicesPage(loadServices = false) {
    return async dispatch => {
        dispatch(resetPage());
        if (loadServices) {
            dispatch(requestServices());
        }
    };
}
