import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';

import { requestLiveStats } from './actions';

export class LiveStats extends Component {
    componentDidMount() {
        this.props.requestLiveStats();
    }

    render() {
        return (
            <div className="p-grid dashboard">
                {this.props.error ? (
                    <Message severity="warn" text={this.props.error.message} />
                ) : (
                    <React.Fragment>
                        <div className="p-col-6 p-xl-3">
                            <div className="overview-box overview-box-4">
                                <div className="overview-box-header" />
                                <div className="overview-box-content">
                                    <div className="overview-box-icon">
                                        <i className="material-icons">
                                            view_list
                                        </i>
                                    </div>
                                    <div className="overview-box-title">
                                        {'Active Services'}
                                    </div>
                                    <div className="overview-box-count">
                                        {this.props.activeServices}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-6 p-xl-3">
                            <div className="overview-box overview-box-4">
                                <div className="overview-box-header" />
                                <div className="overview-box-content">
                                    <div className="overview-box-icon">
                                        <i className="material-icons">
                                            person_pin_circle
                                        </i>
                                    </div>
                                    <div className="overview-box-title">
                                        {'Advisers Online'}
                                    </div>
                                    <div className="overview-box-count">
                                        {this.props.connectedAdvisers}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-6 p-xl-3">
                            <div className="overview-box overview-box-4">
                                <div className="overview-box-header" />
                                <div className="overview-box-content">
                                    <div className="overview-box-icon">
                                        <i className="material-icons">event</i>
                                    </div>
                                    <div className="overview-box-title">
                                        {'Current Meetings'}
                                    </div>
                                    <div className="overview-box-count">
                                        {this.props.currentMeetings}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-6 p-xl-3">
                            <div className="overview-box overview-box-4">
                                <div className="overview-box-header" />
                                <div className="overview-box-content">
                                    <div className="overview-box-icon">
                                        <i className="material-icons">
                                            supervisor_account
                                        </i>
                                    </div>
                                    <div className="overview-box-title">
                                        {'Current Participants'}
                                    </div>
                                    <div className="overview-box-count">
                                        {this.props.currentParticipants}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                <div className="p-col-12">
                    <Button
                        label="Refresh"
                        disabled={this.props.statsLoading}
                        onClick={this.props.requestLiveStats}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    let activeServices = 0;
    let connectedAdvisers = 0;
    let currentMeetings = 0;
    let currentParticipants = 0;

    if (state.liveStats.stats) {
        state.liveStats.stats.forEach(element => {
            if (element.started) {
                activeServices++;
                connectedAdvisers += element.connectedAdvisers;
                currentMeetings += element.currentMeetings;
                currentParticipants += element.currentParticipants;
            }
        });
    }

    return {
        activeServices,
        connectedAdvisers,
        currentMeetings,
        currentParticipants,
        stats: state.liveStats.stats,
        statsLoading: state.liveStats.statsLoading,
        error: state.liveStats.errorMessage,
        language: state.i18n.language
    };
};

const mapDispatchToProps = {
    requestLiveStats
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveStats);
